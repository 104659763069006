<template>
  <div style="position: relative;">
    <div :class="{'fetching-overlay': fetching}"></div>
    <table class="table datatable-table" :class="tableClass">
      <thead>
        <tr v-if="$slots['utils-head']">
          <slot name="utils-head"></slot>
        </tr>
        <tr @drop="dragDrop" class="datatable-head-row">
          <th v-if="batchPos == 'first'" class="batch width-1">
            <label>
              <input v-model="thBatch" type="checkbox"><i></i>
            </label>
          </th>
          <th
            v-for="(column, index) in columns"
            :key="column.field"
            :class="thClass(column, index)" class="text-nowrap"
          >
            <template v-if="filters[column.field]">
              <template v-if="typeof(filters[column.field].type) == 'undefined'">
                <template v-if="sorts.indexOf(column.field) != -1">
                  <span @click="applySorting(column.field)" class="sort">{{column.title}} <i :class="sortCol == column.field ? (sortDir == 'asc' ? 'fa-sort-asc text-primary' : 'fa-sort-desc text-primary') : 'fa-sort text-muted font-xs'" class="fa"></i></span>
                </template>
                <span v-else>{{column.title}}</span>
              </template>
              <template v-else-if="filters[column.field].type == 'text'">
                <div class="filter-input">
                  <input v-model="filters[column.field].value" @paste="applyFilters()" @keyup="applyFilters()" @keyup.esc="filters[column.field].value = '';applyFilters(true)" :placeholder="column.title" :style="`width:${filters[column.field].width}; min-width:${filters[column.field].minWidth};`" type="text" class="form-control">
                  <span v-if="filters[column.field].value" @click="clearInputFilter(column, $event)" class="filter-clear-x glyphicon glyphicon-remove-circle font-sm"></span>
                  <span v-if="sorts.indexOf(column.field) != -1" @click="applySorting(column.field)" class="sort"><i :class="sortCol == column.field ? (sortDir == 'asc' ? 'fa-sort-asc text-primary' : 'fa-sort-desc text-primary') : 'fa-sort text-muted font-xs'" class="fa"></i></span>
                </div>
              </template>
              <template v-else-if="filters[column.field].type == 'select'">
                <div class="filter-input">
                  <select v-model="filters[column.field].value" @change="applyFilters(true);fixSelectWidth($event.target, column.field)" :style="`width:${filters[column.field].width}; min-width:${filters[column.field].minWidth};`" :data-filter-select="column.field" class="form-control">
                    <option :value="null">{{column.title}}</option>
                    <option
                      v-for="item in filters[column.field].options"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{item.text}}
                    </option>
                  </select>
                  <span v-if="sorts.indexOf(column.field) != -1" @click="applySorting(column.field)" class="sort"><i :class="sortCol == column.field ? (sortDir == 'asc' ? 'fa-sort-asc text-primary' : 'fa-sort-desc text-primary') : 'fa-sort text-muted font-xs'" class="fa"></i></span>
                </div>
              </template>
            </template>
            <template v-else-if="sorts.indexOf(column.field) != -1">
              <span @click="applySorting(column.field)" class="sort">{{column.title}} <i :class="sortCol == column.field ? (sortDir == 'asc' ? 'fa-sort-asc text-primary' : 'fa-sort-desc text-primary') : 'fa-sort text-muted font-xs'" class="fa"></i></span>
            </template>
            <template v-else>
              {{column.title}}
            </template>
          </th>
          <th v-if="batchPos == 'last'" class="batch width-1">
            <label>
              <input v-model="thBatch" type="checkbox"><i></i>
            </label>
          </th>
        </tr>
      </thead>
      <template v-if="data.length">
        <template
          v-for="(row, i) in data"
        >
          <tbody>
            <tr
              :key="pkval(row)"
              :class="[rowClass(row), rowDetailedClass(pkval(row))]"
              class="datatable-body-row"
              :draggable="rowsSortDraggable"
              @dragstart="rowSortDragstart(pkval(row), $event)"
              @dragend="rowSortDragend(row[pk])"
              @dragenter="rowSortDragenter(pkval(row), $event)"
              @drop="dragDrop(row, $event)"
            >
              <td v-if="batchPos == 'first' && disableBatch(row)" class="batch">
                <label>
                  <input :value="pkval(row)" @change="batchChange" :checked="batchChecked(row)" type="checkbox"><i></i>
                </label>
              </td>
              <td v-else-if="isBatch"></td>
              <td
                v-for="column in columns"
                :key="column.field"
                :class="tdClass(column, row)"
              >
                <component
                  v-if="column.component"
                  :is="column.component"
                  v-model="row[column.field]"
                  @toggleDetails="toggleDetails(pkval(row), $event)"
                  :row="row"
                  :field="column.field"
                  :column-title="column.title"
                  :editable="editable"
                  :td="td"
                  :errors="errors"/>
                <popper v-else-if="isRemoveRowButton(column, row)" title="Подтвердите удаление" placement="left">
                  <span slot="a-text">
                    <i class="fa fa-times"/>
                  </span>
                  <h5 class="font-sm sm">Удалить строку?</h5>
                  <div class="form-actions sm">
                    <button data-popper="close" type="button" class="btn btn-default btn-xs">Отменить</button>
                    <button data-popper="close" @click="$emit('removeRow', pkval(row))" type="button" class="btn btn-danger btn-xs">Удалить</button>
                  </div>
                </popper>
                <div v-else-if="column.handler" v-html="column.handler(row[column.field], row)"></div>
                <div v-else-if="column.number" @mouseenter="rowsSortDraggable = sortableRows" @mouseleave="rowsSortDraggable = false">{{i + 1}}</div>
                <template v-else>{{column.field ? row[column.field] : ''}}</template>
              </td>
              <td v-if="batchPos == 'last'" class="batch">
                <label>
                  <input :value="pkval(row)" @change="batchChange" :checked="batchChecked(row)" type="checkbox"><i></i>
                </label>
              </td>
            </tr>
          </tbody>
          <template v-if="detailedRows.indexOf(pkval(row)) > -1">
            <component @hook:mounted="detailedComponentMounted(pkval(row))" @update="$emit('updateDetails')" :key="pkval(row)" :is="details.component" :row="row" :colspan="columns.length + (batch ? 1 : 0)" :td="td" :ref="'details-'+pkval(row)" class="datatable-details-container"/>
          </template>
        </template>
        <tfoot v-if="$slots['utils-foot']">
          <tr>
            <td :colspan="columns.length + (batch ? 1 : 0)" class="datatable-utils-foot-section">
              <slot name="utils-foot"></slot>
            </td>
          </tr>
        </tfoot>
      </template>
      <tfoot v-else>
        <tr>
          <td :colspan="columns.length + (batch ? 1 : 0)" class="datatable-no-data text-muted">
            <span v-if="fetching">
              <i class="fa fa-circle-o-notch fa-spin"/> Загрузка данных...
            </span>
            <span v-else>Нет данных</span>
          </td>
        </tr>
      </tfoot>
      <tfoot>
        <tr v-if="$slots['utils-foot2']" class="datatable-utils-foot-section">
          <slot name="utils-foot2"></slot>
        </tr>
      </tfoot>
    </table>
    <table v-if="data.length && remote" class="table datatable-paginator-table">
      <tbody>
        <tr>
          <td class="datatable-showing-info">
            Showing <span class="datatable-showing-count">{{showingFrom}}</span>
            to <span class="datatable-showing-count">{{showingTo}}</span>
            of <span class="text-primary">{{filteredCount}}</span> entries
            <span v-if="showingFilteredFrom">(filtered from {{showingFilteredFrom}} total entries)</span>
          </td>
          <td class="datatable-paginator">
            <paginator @paged="paged" :current="paginator.current" :last="paginator.last"></paginator>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import Paginator from './Paginator'
  import VueScrollTo from 'vue-scrollto'
  import axios from 'axios'
  import {union, intersection} from 'lodash'

  export default {
    model: {
      prop: 'batch',
      event: 'batched'
    },
    props: {
      columns: Array,
      rows: Object,
      options: Object,
      td: null,
      src: [String, Object, Array],
      details: Object,
      batch: Array,
      id: String,
      filter: Object,
      sort: null,
      editable: Boolean,
      errors: {
        type: Object,
        'default': null
      }
    },
    data () {
      return {
        data: [],
        limits: [50, 100, 500],
        //limit: 50,
        totalCount: 0,
        filteredCount: 0,
        paginator: {current: 1, last: 0},
        remote: true,
        filterTimeoutID: null,
        filters: {},
        sorts: [],
        sortCol: null,
        sortDir: 'asc',
        detailedRows: [],
        rowsSortDraggable: false,
        fetching: false
      }
    },
    components: {Paginator},
    computed: {
      thBatch: {
        get () {
          let pageBatched = intersection(this.batch, this.pagePkValues)
          let data_count = 0
          this.data.map((val) => {
            if (this.disableBatch(val)) {
              data_count++
            }
          })
          return pageBatched.length && data_count == pageBatched.length
        },
        set (value) {
          let batched = this.batch.slice()
          let thBatched = value
            ? union(batched, this.pagePkValues)
            : batched.filter(batchedValue => {
              return this.pagePkValues.indexOf(batchedValue) == -1
            })
          batched.splice(0, batched.length, ...thBatched)
          let res = []
          batched.map((val) => {
            let element = this.data.find((el) => {
              return val == el.id
            })
            if (this.disableBatch(element)) {
              res.push(element.id)
            }
          })
          batched = res
          this.$emit('batched', batched)
        }
      },
      isBatch () {
        return this.batch
      },
      batchPos () {
        return this.batch ? this.batch.pos || 'first' : ''
      },
      pk () {
        return this.options && this.options.pk ? this.options.pk : 'id'
      },
      pkComposite () {
        return this.pk.indexOf('.') > 0 ? this.pk.split('.') : null
      },
      pagePkValues () {
        return this.data.map(value => this.pkval(value))
      },
      showingFrom () {
        let prev = this.paginator.current - 1
        return this.limit * prev + 1
      },
      showingTo () {
        let to = this.limit * this.paginator.current
        return to < this.showingFrom ? this.data.length : (to > this.filteredCount ? this.filteredCount : to)
      },
      showingFilteredFrom () {
        return this.filteredCount != this.totalCount ? this.totalCount : false
      },
      tableClass () {
        return this.options ? this.options.tableClass : ''
      },
      sortableRows () {
        return this.options && this.options.sortableRows
      },
      store () {
        //return this.$store.state.datatable[this.id]
        return null
      },
      scrollto () {
        let defaultOffset = -29
        let hasScrolltoOption = this.details && typeof this.details.scrollto != 'undefined'
        return hasScrolltoOption ? this.details.scrollto : defaultOffset
      },
      limit () {
        return this.options && typeof this.options.limit != 'undefined' ? this.options.limit : 50
      }
    },
    methods: {
      applySorting (field) {
        let dirs = ['asc', 'desc']
        if (field == this.sortCol) {
          let index = 1 - dirs.indexOf(this.sortDir)
          this.sortDir = dirs[index]
        }
        this.sortCol = field
        this.fetchData()
      },
      applyFilters (immediate) {
        let timeout = immediate ? 0 : 700
        clearTimeout(this.filterTimeoutID)
        this.filterTimeoutID = setTimeout(() => {
          clearTimeout(this.filterTimeoutID)
          this.fetchData()
          this.$emit('filter', this.filters)
        }, timeout)
      },
      clearInputFilter (column, e) {
        this.filters[column.field].value = ''
        this.applyFilters(true)
        e.target.previousSibling.focus()
      },
      fixSelectWidth (element, field) {
        let arrowWidth = 15
        let interSelect = document.createElement('select')
        interSelect.appendChild(document.createElement('option'))
        element.parentNode.insertBefore(interSelect, element.nextSibling)
        interSelect.querySelector('option').innerText = element.selectedOptions[0].innerText
        this.filters[field].width = (interSelect.clientWidth + arrowWidth) + 'px'
        interSelect.remove()
      },
      dragEnter (e, style) {
        let target = e.target
        let table = target.closest('.datatable-table')
        let bodyRow = target.closest('.datatable-body-row')
        if (bodyRow) {
          if (bodyRow.querySelector('td.batch [type="checkbox"]:checked')) {
            table.querySelectorAll('td.batch [type="checkbox"]:checked')
              .forEach(elem => {
                elem.closest('.datatable-body-row').classList.add(style)
              })
          } else {
            bodyRow.classList.add(style)
          }
        } else {
          let headRow = target.closest('.datatable-head-row')
          if (headRow) {
            table.querySelectorAll('.datatable-body-row')
              .forEach(elem => {
                elem.classList.add(style)
              })
          }
        }
      },
      dragDrop (row, e) {
        if (row instanceof MouseEvent) {
          e = row
          row = null
        }
        e.preventDefault()
        let draggedRows = []
        if (row) {
          let id = this.pkval(row)
          draggedRows = [id]
          if (this.batch && this.batch.indexOf(id) != -1) {
            draggedRows = this.batch.slice()
          }
        } else {
          draggedRows = this.pagePkValues
        }

        this.$emit('drop', draggedRows, e)
      },
      rowSortDragstart (rowId, e) {
        if (!this.sortableRows) {
          return
        }
        e.target.closest('tr').classList.add('row-sort-dragging')
        this.$emit('rowSortDragstart', rowId)
      },
      rowSortDragenter (rowId, e) {
        e.preventDefault()
        if (!this.sortableRows) {
          return
        }
        e.target.closest('tr').classList.add('row-sort-dragging')
        this.$emit('rowSortDragenter', rowId)
      },
      rowSortDragend (rowId) {
        if (!this.sortableRows) {
          return
        }
        this.$el.querySelectorAll('.row-sort-dragging')
          .forEach(elem => {
            elem.classList.remove('row-sort-dragging')
          })
        this.$emit('rowSortDragend', rowId)
      },
      batchChange (e) {
        let batched = this.batch.slice()
        let value = this.pkComposite ? e.target.value : parseInt(e.target.value)
        if (e.target.checked) {
          batched.push(value)
        } else {
          let index = batched.indexOf(value)
          batched.splice(index, 1)
        }
        this.$emit('batched', batched)
      },
      batchChecked (row) {
        return this.batch.indexOf(this.pkval(row)) > -1
      },
      tdClass (column, row) {
        return column.tdClass instanceof Function
          ? column.tdClass(row, column.field)
          : column.tdClass
      },
      thClass (column, index) {
        const col_class = column.thClass instanceof Function
          ? column.thClass(column, index)
          : column.thClass
        let res = this.columns.length > index + 1 && !column.thFullWidth ? ' width-1' : ''
        if (typeof col_class !== 'undefined') {
          res += ' ' + col_class
        }
        return res
      },
      rowClass (row) {
        if (this.rows && this.rows.trClass) {
          return this.rows.trClass instanceof Function
            ? this.rows.trClass(row)
            : this.rows.trClass
        }
      },
      disableBatch (row) {
        if (this.rows && this.rows.disableBatch) {
          return this.rows.disableBatch instanceof Function
            ? this.rows.disableBatch(row)
            : this.rows.disableBatch
        } else {
          return true
        }
      },
      rowDetailedClass (id) {
        if (this.details && this.details.trClass && this.detailedRows.indexOf(id) > -1) {
          return this.details.trClass
        }
      },
      toggleDetails (id, e) {
        let index = this.detailedRows.indexOf(id)
        if (index > -1) {
          let close = true
          if (this.details.close) {
            close = this.details.close(this.$refs['details-' + id][0], e)
          }
          if (close) {
            this.detailedRows.splice(index, 1)
          }
        } else {
          if (this.details.single) {
            this.detailedRows.splice(index, 1, id)
          } else {
            this.detailedRows.push(id)
          }
        }
      },
      getDetailsComponents () {
        return this.detailedRows.map(id => this.$refs[`details-${id}`][0])
      },
      detailedComponentMounted: function (id) {
        if (this.details.open) {
          this.details.open(this.$refs['details-' + id][0], () => {
            let index = this.detailedRows.indexOf(id)
            this.detailedRows.splice(index, 1)
          })
        }
        if (this.scrollto !== false) {
          VueScrollTo.scrollTo(this.$refs['details-' + id][0].$el, {
            container: 'body',
            offset: this.scrollto,
            duration: 0
          })
        }
      },
      fetchData (page) {
        if (typeof this.src == 'object') {
          this.data = this.src
          this.remote = false
        } else if (this.src) {
          this.fetching = true
          let query = {
            page: page || 1,
            limit: this.limit
          }
          if (this.sortCol) {
            query.sortCol = this.sortCol
            query.sortDir = this.sortDir
          }
          for (let field in this.filters) {
            if (this.filters.hasOwnProperty(field)) {
              let value = this.filters[field].value
              if (/*value !== '' && */value !== null && typeof value !== 'undefined') {
                query[field] = value
              }
            }
          }

          /*if (this.id) {
            this.$store.commit('datatable/saveQuery', {id: this.id, query: query})
          }*/

          let params = {}
          for (let name in query) {
            if (query.hasOwnProperty(name)) {
              if (query[name] !== null && typeof query[name] == 'object' && query[name] instanceof Array == false) {
                let value = query[name].handler()
                if (value !== false) {
                  params[name] = value
                }
              } else {
                params[name] = query[name]
              }
            }
          }

          axios.get(this.src, {
            params: params
          })
            .then(({data}) => {
              this.fetching = false
              this.data = data.data
              this.filteredCount = data.recordsFiltered
              this.totalCount = data.recordsTotal
              this.paginator = data.paginator
              this.$emit('updated')
              if (data.payload) {
                this.$emit('payload', data.payload)
              }
            })
            .catch(({response}) => {
              //this.$notifyError(response.status)
              this.fetching = false
            })
        }
      },
      update () {
        this.fetchData()
      },
      paged (page) {
        this.fetchData(page)
      },
      getValue (value, _default) {
        _default = typeof _default == 'undefined' ? null : _default
        return (typeof value != 'undefined' && value !== null) ? value : _default
      },
      getQueryParam (name, _default) {
        let query = this.store ? this.store.query : {}
        return this.getValue(query[name], _default)
      },
      applyFilter (name) {
        this.filters[name] = {value: this.filter[name]}
        this.fetchData()
      },
      makeColumnSortable (column) {
        if (this.sort !== false && column.sort !== false) {
          this.sorts.push(column.field)
        }
      },
      isRemoveRowButton (column, row) {
        if (column.removeRowButton) {
          if (typeof column.removeRowButton == 'function') {
            return column.removeRowButton(row)
          }
          return column.removeRowButton
        }
        return false
      },
      pkval (row) {
        const {pk, pkComposite} = this
        if (pkComposite) {
          let parts = []
          for (let part of pkComposite) {
            parts.push(row[part])
          }
          return parts.join('.')
        }

        return row[pk]
      }
    },
    watch: {
      src (newValue) {
        this.fetchData()
      },
      columns (columns) {
        this.sorts = []
        columns.forEach(column => {
          if (column.field) {
            this.makeColumnSortable(column)
          }
        })
      }
    },
    created () {
      let filters = {}
      let sortCol
      let sortDir = this.sortDir
      this.columns.forEach((column) => {
        if (column.field) {
          this.makeColumnSortable(column)
          if (['asc', 'desc'].indexOf(column.sort) != -1) {
            sortCol = column.field
            sortDir = column.sort
          }
          if (column.filter) {
            let width = null
            let type = column.filter
            let options = null
            let value = this.getQueryParam(column.field)

            if (typeof column.filter == 'object') {
              width = column.filter.width
              type = column.filter.type
              options = column.filter.options
              value = this.getValue(value, column.filter.value)
            }

            let relativeWidth = width && !Number.isInteger(width)
            let minWidth = relativeWidth ? '200px' : '0'
            width = width ? (width + (Number.isInteger(width) ? 'px' : '')) : 'auto'
            filters[column.field] = {type, options, value, width, minWidth}
          }
        }
      })

      if (!sortCol && this.sorts.length) {
        sortCol = this.sorts[0]
      }

      this.sortCol = this.getQueryParam('sortCol', sortCol)
      this.sortDir = this.getQueryParam('sortDir', sortDir)

      if (this.filter) {
        for (let name in this.filter) {
          if (this.filter.hasOwnProperty(name)) {
            let filter = this.getQueryParam(name)
            if (filter !== null) {
              if (filter instanceof Array) {
                this.filter[name].splice(0, this.filter[name].length, ...filter)
              } else if (typeof filter == 'object') {
                this.filter[name].handler(filter.value)
              } else {
                this.filter[name] = filter
              }
            }
            filters[name] = {value: this.filter[name]}
          }
        }
      }

      this.filters = filters
      this.fetchData()
    },
    mounted () {
      this.$el.querySelectorAll('[data-filter-select]')
        .forEach(element => {
          let field = element.getAttribute('data-filter-select')
          this.fixSelectWidth(element, field)
        })
    }
  }
</script>

<style scoped>
  .pagination{
    margin: 0;
    font-size: 10px;
  }
  .datatable-paginator-table {
    margin-top: -2px;
  }
  .datatable-paginator-table > tbody > tr > td {
    border-top: none;
  }
  .datatable-paginator{
    text-align: right;
    width: 650px;
  }
  .datatable-showing-info{
    font-size: 13px;
    font-weight: 700;
    font-style: italic;
    white-space: nowrap;
    color: #969696;
  }
  .datatable-showing-count{
    color: #404040;
  }
  .datatable-manage{
    width: 100%;
    margin-bottom: 10px;
  }
  .datatable-manage-limits{
    padding-left: 5px;
    width: 75px;
  }
  .datatable-no-data{
    text-align: center;
    border-top: none;
  }
  .datatable-table.table {
    margin-bottom: 0;
    border-collapse: separate;
  }
  .datatable-table.table > thead > tr > th {
    border-bottom: 1px;
    padding: 6px 10px;
    vertical-align: middle;
  }
  .datatable-table > tbody > tr > td {
    padding: 4px 10px;
    vertical-align: middle;
  }
  /* Filters */
  .filter-input {
    position: relative;
    vertical-align: top;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    zoom: 1;
  }
  .filter-clear-x {
    width: 13px;
    height: 13px;
    opacity: .4;
    z-index: 100;
    top: 1px;
    right: 20px;
    margin-top: -5px;
  }
  .filter-clear-x:hover {
    opacity: 1;
  }
  .filter-input [type="text"],
  .filter-input select {
    height: 24px;
    display: inline;
  }
  .filter-input [type="text"] {
    padding: 0 16px 0 7px;
  }
  .filter-input select {
    padding: 0 0 0 2px;
  }
  .filter-clear-x + .sort {
    margin-left: -14px !important;
  }
  .filter-input span.sort {
    margin-left: 2px;
  }
  .filter-input [type="text"],
  .filter-input select {
    margin-left: -7px;
  }
  th span.sort {
    cursor: pointer;
    user-select: none;
  }
  th span.sort i {
    width: 8px;
  }
  .datatable-utils-foot-section,
  .datatable-utils-foot-section > td {
    padding: 0;
    border: none !important;
  }

  .datatable-table .batch {
    position: relative;
  }
  .datatable-table .batch input[type="checkbox"] {
    position: absolute;
    left: -9999px;
  }
  .datatable-table .batch label {
    margin: 0;
    display: inline;
  }
  .datatable-table .batch i {
    position: relative;
    display: block;
    width: 15px;
    height: 15px;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    background: #fff;
    border-color: #bdbdbd;
    transition: border-color .3s;
  }
  .datatable-table th.batch input[type="checkbox"] + i:after {
    top: -2px;
  }
  .datatable-table .batch input[type="checkbox"] + i:after {
    color: #3276b1;
    content: '\f00c';
    top: -3px;
    left: 1px;
    width: 12px;
    height: 12px;
    font: 400 12px/19px FontAwesome;
    text-align: center;
    position: absolute;
    opacity: 0;
    transition: opacity .1s;
  }
  .datatable-table .batch input[type="checkbox"]:checked + i {
    border-color: #3276b1;
  }
  .datatable-table .batch input[type="checkbox"]:checked + i:after {
    opacity: 1;
  }
  .row-sort-dragging {
    background: #ccc;
  }
  .fetching-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.3;
    z-index: 3;
    border: 1px solid #ccc;
  }
</style>
