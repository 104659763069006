<template>
  <modal name="form" @closed="$emit('close')" :scrollable="true" height="auto" width="1000">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          {{ removing ? 'Удалить' : 'Настроить' }} контекстный фильтр: {{ form.field.label }} ({{ form.field.name }})
        </h4>
        <hr/>
        <section v-if="removing">
          <div>
            Вы хотите удалить контекстный фильтр: {{ form.field.label }} ({{ form.field.name }})?
          </div>
          <button
            class="btn btn-link font-sm no-padding"
            type="button"
            @click="removing = false"
          >
            Отмена
          </button>
        </section>
        <section v-else>
          <div class="row">
            <!-- Блочное представление -->
            <block-form/>
            <!-- Страничное представление -->
            <page-form/>
          </div>
          <bottom-form/>
          <div class="row">
            <section class="col col-12">
              <wildcards :items="wildcards"/>
            </section>
          </div>
        </section>
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            class="btn btn-link"
            @click="remove"
          >
            <span :class="{'text-danger': removing}">
              Удалить
            </span>
          </submit>
          <submit
            v-if="!removing"
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import BlockForm from './form/BlockForm'
  import PageForm from './form/PageForm'
  import BottomForm from './form/BottomForm'
  import Wildcards from '../../../../Wildcards'
  import axios from 'axios'

  export default {
    name: 'DisplayContextEditForm',
    components: {
      BlockForm,
      PageForm,
      BottomForm,
      Wildcards
    },
    data () {
      return {
        wildcards: [],
        removing: false,
        processing: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      form () {
        return JSON.parse(JSON.stringify(this.display.filterContextForm))
      },
      macros () {
        return this.display.macros
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post(`dview/display/filter/context/${this.form.id}/edit`, this.form)
          .then(({data}) => {
            const display = JSON.parse(JSON.stringify(this.display))
            const idx = display.form.filterContexts.findIndex(item => item.id == this.form.id)
            display.form.filterContexts.splice(idx, 1, data)
            this.$store.commit('display/updateFormFilterContexts', display.form.filterContexts)
            this.$store.commit('display/setFilterContextForm', data)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      remove () {
        if (!this.removing) {
          this.removing = true
          return
        }
        this.processing = true
        axios.post(`dview/display/filter/context/${this.form.id}/delete`)
          .then(() => {
            const display = JSON.parse(JSON.stringify(this.display))
            const idx = display.form.filterContexts.findIndex(item => item.id == this.form.id)
            display.form.filterContexts.splice(idx, 1)
            this.$store.commit('display/updateFormFilterContexts', display.form.filterContexts)
            this.close()
            smallBoxSuccess('Контекстный фильтр удален.')
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.wildcards = this.display.form.filterContexts
        .map(context => {
          return {
            name: context.field.name,
            label: context.field.label
          }
        })
        .concat(
          [{
            name: '[result:count]',
            label: 'Количество в результате'
          }]
        )
        .concat(
          this.macros.map(item => {
            return {
              name: item[0],
              label: item[1]
            }
          })
        )
      this.$modal.show('form')
    }
  }
</script>