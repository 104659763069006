<template>
  <div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th class="width-1">По умолчанию</th>
          <th class="width-1">Метка</th>
          <th class="width-1">Оператор</th>
          <th>Значение</th>
          <th class="width-1">Ручное заполнение</th>
          <th class="width-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in items"
          :key="item.id"
        >
          <td>
            <div class="smart-form">
              <label class="checkbox">
                <input v-model="item.default" type="checkbox">
                <i></i>
              </label>
            </div>
          </td>
          <td>
            <label class="input">
              <input
                v-model="item.label"
                placeholder="Метка"
                type="text"
              />
            </label>
          </td>
          <td>
            <label class="input">
              <select v-model="item.operator" class="form-control">
                <option :value="null" selected disabled>Выберите опретаор</option>
                <option
                  v-for="operator in operators"
                  :key="operator.symbol"
                  :value="operator.symbol"
                  :disabled="['not %like', 'not like%'].includes(operator.symbol)"
                >
                  {{operator.label}}
                </option>
              </select>
            </label>
          </td>
          <td>
            <div v-if="isBetweenOperator(item.operator)" class="between-inputs">
              <label class="input">
                <input
                  v-model="item.collect.valueMin"
                  placeholder="Min"
                  type="text"
                />
              </label>
              <label class="input">
                <input
                  v-model="item.collect.valueMax"
                  placeholder="Max"
                  type="text"
                />
              </label>
            </div>
            <template v-else-if="isListable">
              <label class="select select-multiple">
                <select
                  v-model="item.collect.values"
                  multiple
                  size="6"
                  class="custom-scroll"
                >
                  <option
                    v-for="item in fieldValues"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{item.label}}
                  </option>
                </select>
              </label>
            </template>
            <template v-else-if="!isNotNullOperator(item.operator)">
              <label class="input">
                <input
                  v-model="item.collect.value"
                  placeholder="Значение"
                  type="text"
                />
              </label>
            </template>
          </td>
          <td>
            <div class="smart-form">
              <label class="checkbox">
                <input v-model="item.manual" type="checkbox">
                <i></i>
              </label>
            </div>
          </td>
          <td>
            <button
              class="btn btn-link font-sm no-padding"
              type="button"
              @click="items.splice(idx, 1)"
            >
              <i class="fa fa-times"/>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <button
              class="btn btn-link font-sm no-padding"
              type="button"
              @click="addItem"
            >
              Добавить строку
            </button>
          </td>
          <td>
            <button
              class="btn btn-link font-sm no-padding"
              type="button"
              :disabled="fetchingItems"
              @click="createItems"
            >
              {{fetchingItems ? 'Подождите...' : 'Обновить элементы'}}
            </button>
          </td>
          <td colspan="4"></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import axios from 'axios'
  import _uniqueId from 'lodash/uniqueId'

  export default {
    name: 'ExpandMultipleItems',
    props: ['value'],
    data () {
      return {
        items: this.handleItems(this.value),
        fetchingItems: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      form () {
        return this.display.filterCriteriaForm
      },
      criteriaId () {
        return this.form.id
      },
      isListable () {
        return Boolean(this.fieldValues.length)
      },
      fieldValues () {
        return this.form.field.values
      },
      operators () {
        const operators = this.display.options.operators
        if (this.isListable) {
          return operators.filter(
            operator => ['in', 'and', 'not in', 'is not null'].includes(operator.symbol)
          )
        }
        return operators.filter(
          operator => !['in', 'and', 'not in'].includes(operator.symbol)
        )
      }
    },
    watch: {
      items: {
        deep: true,
        handler (items) {
          this.$emit('input', JSON.parse(JSON.stringify(items)))
        }
      }
    },
    methods: {
      addItem () {
        const item = {
          collect: {
            values: [],
            value: null,
            valueMin: null,
            valueMax: null
          },
          criteria_id: this.criteriaId,
          default: false,
          id: -_uniqueId(),
          label: null,
          operator: null,
          manual: 0
        }
        this.items.push(item)
      },
      isBetweenOperator (operator) {
        return ['between', 'not between'].includes(operator)
      },
      isNotNullOperator (operator) {
        return operator == 'is not null'
      },
      fixCollect (item) {
        item.collect = item.collect || {}
        const collect = item.collect
        if (!Array.isArray(collect.values)) {
          collect.values = []
        }
        if (typeof collect.value == 'undefined') {
          collect.value = null
        }
        if (typeof collect.valueMin == 'undefined') {
          collect.valueMin = null
        }
        if (typeof collect.valueMax == 'undefined') {
          collect.valueMax = null
        }
        return item
      },
      createItems () {
        const {criteriaId, form, display} = this
        const contentTypeIds = display.form.filterContentTypes.map(item => item.id)
        if (contentTypeIds.length) {
          const params = {
            contentTypeIds,
            expandLabel: form.expand_label,
            expandIgnore: form.expand_ignore
          }
          this.fetchingItems = true
          axios.post(`dview/display/filter/criteria/${criteriaId}/createExpandItems`, params)
            .then(({data}) => {
              const expands = data.result
              if (expands) {
                this.items = this.handleItems(expands)
                this.$store.commit('display/updateFilterCriteriaExpands', {
                  criteriaId,
                  expands,
                  expandLabel: form.expand_label
                })
                smallBoxSuccess('Выполнено.')
              } else {
                this.showFetchingItemsError()
              }
            })
            .finally(() => {
              this.fetchingItems = false
            })
        } else {
          this.showFetchingItemsError()
        }
      },
      handleItems (items) {
        return JSON.parse(JSON.stringify(items)).map(this.fixCollect)
      },
      showFetchingItemsError () {
        smallBoxErrors('Настройте фильтр "Тип материала".')
      }
    }
  }
</script>

<style scoped>
  thead th {
    white-space: nowrap;
  }
  label.checkbox {
    margin: 3px 33px;
  }
  input[type="text"], select {
    width: 248px;
  }
  .between-inputs label {
    display: inline;
  }
  .between-inputs input[type="text"] {
    display: inline;
    width: 123px;
  }
</style>