<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <fieldset v-else>
      <section>
        <header>Настройки содержимого блока меню</header>
      </section>
      <section>
        <label class="label">Выбор меню</label>
        <label class="select">
          <select v-model="form.menu_id" @change="handleChangeMenu">
            <option :value="null" selected disabled>Выбор меню</option>
            <option v-for="menu in menus" :value="menu.id">{{menu.label}}</option>
          </select>
          <i></i>
        </label>
      </section>
      <section>
        <section>
          <label class="label">Выбор пункта меню</label>
          <xselect2
            v-model="form.menu_item"
            tk="label"
            :label="menuItemsLoading ? 'Загрузка...' : 'Выбор пункта меню'"
            :src="menuItems"
            :disabled="menuItemsLoading"
            width="100%"
            @change="emit"
          />
        </section>
        <div class="row">
          <section class="col col-2">
            <label class="input">
              <input v-model="form.start_level" placeholder="Начальный уровень" type="text"/>
            </label>
            <div class="note">Начальный уровень.</div>
          </section>
          <section class="col col-2">
            <label class="input">
              <input v-model="form.depth" placeholder="Максимальная глубина" type="text"/>
            </label>
            <div class="note">
              Максимальная глубина
              <div>0 - без ограничений.</div>
            </div>
          </section>
          <section class="col col-8">
            <label class="select">
              <select v-model="form.follow">
                <option v-for="item in options.follow" :value="item.value">
                  {{item.text}}
                </option>
              </select>
              <i></i>
            </label>
            <div class="note">
              <p>Поведение при активном пункте меню.</p>
              <p>Укажите как начальный уровень будет следовать за активным пунктом меню.</p>
            </div>
          </section>
        </div>
        <section>
          <label class="checkbox">
            <input v-model="form.expand_all" type="checkbox"/>
            <i></i> Разворачивать все подпункты этого меню
          </label>
        </section>
        <section>
          <label class="checkbox">
            <input v-model="form.sort_by_active" type="checkbox"/>
            <i></i> Сортировать меню по активному пункту
          </label>
        </section>
      </section>
    </fieldset>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Menu',
    props: ['blockId'],
    data () {
      return {
        form: {
          block_id: this.blockId,
          menu_id: null,
          menu_item_id: null,
          start_level: 1,
          depth: 0,
          follow: 'none',
          expand_all: false,
          sort_by_active: false,
          menu_item: {
            id: null,
            label: ''
          }
        },
        menus: [],
        menuItems: [],
        options: {
          follow: [
            {
              value: 'none',
              text: 'Не следовать'
            },
            {
              value: 'parent',
              text: 'Следовать за активным пунктом меню'
            },
            {
              value: 'children',
              text: 'Следовать за потомками активного пункта меню'
            }
          ]
        },
        menuItemsLoading: false,
        loading: true
      }
    },
    watch: {
      'form.menu_item' (item) {
         this.form.menu_item_id = item.id
      },
      form: {
        deep: true,
        handler () {
          this.emit()
        }
      }
    },
    methods: {
      async getMenuItems () {
        this.menuItemsLoading = true
        this.menuItems = []
        const {data} = await axios.get(`menu/${this.form.menu_id}/getMenuItems`)
        this.menuItems = data.map(item => {
          return {
            id: item.id,
            text: `${' - '.repeat(item.lvl)}${item.label}`
          }
        })
        this.menuItemsLoading = false
      },
      handleChangeMenu () {
        this.form.menu_item_id = null
        this.getMenuItems()
      },
      emit () {
        const form = JSON.parse(JSON.stringify(this.form))
        delete form.menu_item
        this.$emit('input', form)
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getBlockTypeSettings/Menu`)
      this.menus = data.menus
      if (data.menuBlock) {
        if (!data.menuBlock.menu_item) {
          data.menuBlock.menu_item = {
            id: null,
            label: ''
          }
        }
        this.form = data.menuBlock
        await this.getMenuItems()
      }
      this.loading = false
      this.$emit('loaded')
      this.emit()
    }
  }
</script>