<template>
  <ul v-if="last>1" class="pagination">
    <li :class="{disabled:page==1}">
      <a href="#" rel="prev" v-if="page!=1" @click.prevent="page--; $emit('paged', page)">&laquo;</a>
      <span v-else>&laquo;</span>
    </li>

    <li v-for="item in segment_0" :key="item" :class="{active:item==page}">
      <a href="#" v-if="item!=page" @click.prevent="page=item; $emit('paged', item)">{{ item }}</a>
      <span v-else>{{ item }}</span>
    </li>

    <li v-if="segment_0.length==2&&page>6" class="disabled"><span>...</span></li>

    <li v-for="item in segment_1" :key="item" :class="{active:item==page}">
      <a href="#" v-if="item!=page" @click.prevent="page=item; $emit('paged', item)">{{ item }}</a>
      <span v-else>{{ item }}</span>
    </li>

    <li v-if="segment_2.length==2" class="disabled"><span>...</span></li>

    <li v-for="item in segment_2" :key="item" :class="{active:item==page}">
      <a href="#" v-if="item!=page" @click.prevent="page=item; $emit('paged', item)">{{ item }}</a>
      <span v-else>{{ item }}</span>
    </li>

    <li :class="{disabled:page==last}">
      <a href="#" rel="next" v-if="page!=last" @click.prevent="page++; $emit('paged', page)">&raquo;</a>
      <span v-else>&raquo;</span>
    </li>
  </ul>
</template>

<script>
  export default {
    props: ['current', 'last'],
    data () {
      return {
        baseQueryString: null,
        page: this.current
      }
    },
    watch: {
      current (value) {
        this.page = value
      }
    },
    computed: {
      segment_0 () {
        if (this.last <= 11) {
          return this.range(1, this.last)
        }

        let items = (this.page <= 6) ? 8 : 2
        let count = Math.min(items, this.last)
        return this.range(1, count)
      },

      segment_1 () {
        if (this.segment_0.length == 2 && this.segment_2.length == 2) {
          let start = this.page - 3
          let count = 7
          return this.range(start, count)
        }

        return []
      },

      segment_2 () {
        if (this.last <= 11) {
          return []
        }

        let start = this.last - 1
        let count = 2
        if (this.page > 6 && this.last - this.page < 6) {
          start = this.last - 8
          count = 8 + 1
        } else if (this.last <= 6) {
          return []
        }

        return this.range(start, count)
      }
    },
    methods: {
      url () {
        return '#'
      },
      range (start, count) {
        return Array.from(new Array(count), (x, i) => i + start)
      }
    }
  }
</script>