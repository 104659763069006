<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <div v-else>
      <button
        class="btn btn-link no-padding font-sm"
        @click="editContentType"
      >
        Добавить тип материала
      </button>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="width-1 text-nowrap">Типы материалов</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(contentType, idx) in contentTypes">
            <td class="text-nowrap">
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="editContentType(idx)"
              >
                {{contentType.label}}
              </button>
            </td>
            <td>
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="removeContentType(idx)"
              >
                <i class="fa fa-times"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <content-type-edit-modal
        v-if="showContentTypeEditModal"
        v-model="contentTypes[editContentTypeIdx]"
        @close="showContentTypeEditModal = false"
      />
    </div>
  </div>
</template>

<script>
  import ContentTypeEditModal from './ContentTypeEditModal'
  import axios from 'axios'

  export default {
    name: 'BlockVisibilityByContentType',
    components: { ContentTypeEditModal },
    model: {
      prop: 'items',
      event: 'input'
    },
    props: ['items', 'blockId'],
    data () {
      return {
        contentTypes: [],
        editContentTypeIdx: null,
        showContentTypeEditModal: false,
        loading: true
      }
    },
    watch: {
      contentTypes () {
        this.emit()
      }
    },
    methods: {
      editContentType (idx) {
        this.editContentTypeIdx = Number.isInteger(idx) ? idx : this.contentTypes.length
        this.showContentTypeEditModal = true
      },
      removeContentType (idx) {
        this.contentTypes.splice(idx, 1)
      },
      emit () {
        const contentTypeIds = JSON.parse(JSON.stringify(this.contentTypes.map(contentType => contentType.id)))
        this.$emit('input', contentTypeIds)
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getVisibilityContentTypes`)
      this.contentTypes = data
      this.loading = false
      this.$emit('loaded')
    }
  }
</script>