<template>
  <modal name="filesForm" @closed="$emit('close')" height="auto">
    <div class="well no-margin-bottom">
      <h4>
        <strong>Связь с файлами</strong>
        <div><small>{{title}}</small></div>
      </h4>
      <hr/>
      <span v-if="loader" class="text-muted">
        <i class="fa fa-circle-o-notch fa-spin"/> Загрузка...
      </span>
      <table class="table">
        <thead>
          <tr>
            <th class="width-1">Изображение</th>
            <th class="width-1">Видео</th>
            <th class="width-1">По умолчанию</th>
            <th>Удалить связь</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :key="item.thumb"
          >
            <td>
              <img :src="`/storage/${item.thumb}`"/>
            </td>
            <td>{{item.video ? 'Да' : 'Нет'}}</td>
            <td>
              <bs-switcher v-model="item.isDefault" @input="setDefault(item.id)"/>
            </td>
            <td>
              <a @click.prevent="removeItemId = item.id" href="#" class="font-lg">&times;</a>
            </td>
          </tr>
        </tbody>
      </table>
      <hr/>
      <button @click="close" type="button" class="btn btn-link pull-right">Закрыть</button>
      <div class="clearfix"></div>
    </div>
    <modal name="removeForm" height="auto" width="350">
      <div class="well no-margin-bottom">
        <div>
          <strong>Удалить связь с этим файлом?</strong>
        </div>
        <hr/>
        <button @click="remove" type="button" class="btn btn-primary pull-right" :disabled="removeItemProcessing">
          <i v-if="removeItemProcessing" class="fa fa-circle-o-notch fa-spin"/> Удалить связь
        </button>
        <button @click="removeItemId = null" type="button" class="btn btn-link pull-right">Отмена</button>
        <div class="clearfix"></div>
      </div>
    </modal>
  </modal>
</template>

<script>
  import BsSwitcher from '../../Bs-Switcher'
  import axios from 'axios'

  export default {
    components: {BsSwitcher},
    props: ['content-id'],
    data () {
      return {
        items: [],
        title: '',
        loader: false,
        removeItemProcessing: false,
        removeItemId: null
      }
    },
    watch: {
      removeItemId (value) {
        let action = value ? 'show' : 'hide'
        this.$modal[action]('removeForm')
      }
    },
    methods: {
      loadFiles () {
        this.loader = true
        axios.get(`gallery/contentFiles/${this.contentId}`)
          .then(({data}) => {
            this.loader = false
            this.items = data.items
            this.title = data.title
          })
      },
      setDefault (id) {
        this.items
          .filter(item => item.id != id)
          .map(item => {
            item.isDefault = 0
          })
        axios.post(`gallery/setDefaultItem/${this.contentId}/${id}`)
          .then(() => {
            smallBoxSuccess('Изображение по умолчанию установлено.')
          })
      },
      remove () {
        let {contentId, removeItemId} = this
        this.removeItemProcessing = true
        axios.post(`gallery/removeContentItem/${contentId}/${removeItemId}`)
          .then(() => {
            let index = this.items.findIndex(item => item.id == this.removeItemId)
            this.items.splice(index, 1)
            this.removeItemProcessing = false
            this.removeItemId = null
            this.$emit('removed', this.items.length)
            smallBoxSuccess('Связь с файлом удалена.')
            if (!this.items.length) {
              this.close()
            }
          })
      },
      close () {
        this.$modal.hide('filesForm')
      }
    },
    mounted () {
      this.$modal.show('filesForm')
      this.loadFiles()
    }
  }
</script>

<style scoped>
  th {
    white-space: nowrap;
  }
</style>