<template>
  <modal name="form" @closed="$emit('close')" height="auto" width="800" :scrollable="true">
    <div class="well no-margin-bottom" style="height: 100%">
      <div v-if="loading">Подготовка...</div>
      <div v-else>
        <h4>
          <strong>{{guglexContentType ? 'Редактировать' : 'Добавить'}} тип материала</strong>
        </h4>
        <hr/>
        <div class="smart-form">
          <section>
            <label class="label">Тип материала</label>
            <xselect2
              label="Выберите тип материала"
              v-model="form.contentType"
              :src="contentTypeItems"
              width="100%"
            />
          </section>
          <section>
            <label class="label">Метки</label>
            <chips v-model="form.labels" placeholder="Введите метку"/>
            <div class="note">Символ (*) звёздочка допускается в конце метки.</div>
          </section>
          <section>
            <label class="label">Минус-слова</label>
            <chips v-model="form.minus_words" placeholder="Введите минус-слова"/>
          </section>
          <section>
            <content-type-attributes v-model="attributes" :contentTypeId="form.contentType.id" @change="formChanged = true"/>
          </section>
        </div>
      </div>
      <hr/>
      <div class="pull-right">
        <button v-if="guglexContentType" @click="remove" :disabled="processing || loading" type="button" class="btn btn-link">Удалить</button>
        <submit @click="submit" :processing="processing" :disabled="loading || !formChanged" class="btn btn-link">
          Сохранить
        </submit>
        <button @click="close" type="button" class="btn btn-link">Закрыть</button>
      </div>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import ContentTypeAttributes from './ContentTypeAttributes'
  import axios from 'axios'

  export default {
    components: {ContentTypeAttributes},
    props: ['value'],
    data () {
      const guglexContentType = this.value || {content_type_id: null, label: '', labels: [], minus_words: []}
      return {
        contentTypeItems: [],
        updatedGuglexContentType: null,
        attributes: [],
        form: {
          contentType: {
            id: guglexContentType.content_type_id,
            name: guglexContentType.label
          },
          labels: guglexContentType.labels.slice(0),
          minus_words: guglexContentType.minus_words.slice(0)
        },
        formChanged: false,
        processing: false,
        loading: true
      }
    },
    computed: {
      guglexContentType () {
        const {value, updatedGuglexContentType} = this
        return value || updatedGuglexContentType
      }
    },
    watch: {
      form: {
        deep: true,
        handler () {
          this.formChanged = true
        }
      }
    },
    methods: {
      submit () {
        this.processing = true
        const {guglexContentType, attributes, form} = this
        const {contentType, labels, minus_words} = form
        const attribute_ids = attributes.map(item => item.id)
        const action = guglexContentType && guglexContentType.id ? `edit/${guglexContentType.id}` : 'create'
        const params = {
          content_type_id: contentType.id,
          labels,
          minus_words,
          attribute_ids
        }
        axios.post(`guglex/contentType/${action}`, params)
          .then(({data}) => {
            this.updatedGuglexContentType = data
            this.formChanged = false
            this.$emit('success')
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      remove () {
        if (!confirm('Удалить тип материала?')) {
          return
        }

        axios.post(`guglex/contentType/delete/${this.guglexContentType.id}`)
          .then(() => {
            this.$emit('success')
            this.close()
            smallBoxSuccess('Выполнено.')
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
      axios.get('guglex/contentType/select')
        .then(({data}) => {
          this.contentTypeItems = data
            .map(item => {
              return {
                id: item.id,
                text: item.label
              }
            })
          this.loading = false
        })
    }
  }
</script>
