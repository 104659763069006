<template>
  <div class="smart-form">
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <table v-else class="table table-bordered table-hover">
      <thead>
        <tr>
          <th class="width-1">Описание</th>
          <th class="width-1 text-nowrap">Метка по умолчанию</th>
          <th class="text-nowrap">Машинное имя</th>
          <th>Вкл.</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(defaultSystemField, idx) in defaultSystemFields"
          :key="defaultSystemField.type"
        >
          <td class="text-nowrap">{{ defaultSystemField.description }}</td>
          <td class="text-nowrap">{{ defaultSystemField.label }}</td>
          <td>
            <label class="input">
              <input
                :value="getFieldName(defaultSystemField.type)"
                placeholder="Машинное имя"
                type="text"
                :ref="`field_${idx}`"
              />
            </label>
          </td>
          <td>
            <button @click="submit(idx, defaultSystemField)" :disabled="processing" type="button" class="btn btn-default btn-sm">
              <i class="fa fa-save"></i> Применить
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'FieldSystemForm',
    data () {
      return {
        systemFields: [],
        defaultSystemFields: [],
        processing: false,
        loading: true
      }
    },
    methods: {
      getFieldName (type) {
        const field = this.systemFields.find(item => item.type == type)
        return field
          ? field.name
          : this.defaultSystemFields.find(item => item.type == type).name
      },
      submit (idx, defaultSystemField) {
        const name = this.$refs[`field_${idx}`][0].value
        const params = {
          name,
          label: defaultSystemField.label
        }
        this.processing = true
        axios.post(`field/editSystemField/${defaultSystemField.type}`, params)
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async created () {
      const {data} = await axios.get('field/getSystemFields')
      this.systemFields = data.systemFields
      this.defaultSystemFields = data.defaultSystemFields
      this.loading = false
    }
  }
</script>