<template>
  <div>
    <button @click="showContentTypeModal = true" type="button" class="btn btn-link no-padding">{{value}}</button>

    <!-- Форма редактирования типа материала -->
    <content-type-edit-modal
      v-if="showContentTypeModal"
      :value="row"
      @success="$parent.$parent.$refs.contentTypesTable.update()"
      @close="showContentTypeModal = false"
    />
  </div>
</template>

<script>
  import ContentTypeEditModal from '../ContentTypeModal'

  export default {
    components: {ContentTypeEditModal},
    props: ['row', 'value'],
    data () {
      return {
        showContentTypeModal: false
      }
    }
  }
</script>
