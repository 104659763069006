<template>
  <div>
    <dview
      :block-id="blockId"
      @input="form.displayId = $event.displayId"
      @loaded="loaded.display = true"
    />
    <div v-if="loaded.display">
      <fieldset style="padding-top: 0 !important;">
        <section>
          <header>Настройка графика сравнения цен</header>
        </section>
        <section v-if="!loaded.graph">Подготовка...</section>
        <template v-else>
          <section>
            <label class="checkbox">
              <input
                v-model="form.graph.enabled"
                type="checkbox"
              /> <i></i> Включить график
            </label>
          </section>
          <template v-if="form.graph.enabled">
            <section>
              <label class="label">Заголовок</label>
              <label class="input">
                <vp-editor
                  v-model="form.graph.title"
                  style="background: #fff;"
                />
              </label>
            </section>
            <section style="width: 350px;">
              <label class="label">Поле по оси X</label>
              <label class="select">
                <xselect2
                  v-model="fieldX"
                  label="Выбор поля для оси X"
                  tk="text"
                  :src="fields"
                  width="100%"
                  @change="form.graph.axisX.fieldId = $event.id"
                />
              </label>
            </section>
            <section style="width: 350px;">
              <label class="label">Поле по оси Y</label>
              <label class="select">
                <xselect2
                  v-model="fieldY"
                  label="Выбор поля для оси Y"
                  tk="text"
                  :src="fields"
                  width="100%"
                  @change="form.graph.axisY.fieldId = $event.id"
                />
              </label>
            </section>
            <section style="width: 350px;">
              <label class="label">Метка на точке графика</label>
              <label class="textarea textarea-resizable">
                <textarea
                  v-model="form.graph.tooltip"
                  class="custom-scroll"
                  rows="3"
                  placeholder="Метка на точке графика"
                ></textarea>
              </label>
            </section>
          </template>
        </template>
      </fieldset>
    </div>
  </div>
</template>

<script>
  import Dview from './Dview'
  import axios from 'axios'

  export default {
    name: 'DviewProductAnalogs',
    components: { Dview },
    props: ['blockId'],
    data () {
      return {
        loaded: {
          display: false,
          graph: false
        },
        fields: [],
        fieldX: {
          id: null,
          text: null
        },
        fieldY: {
          id: null,
          text: null
        },
        form: {
          displayId: null,
          graph: {
            enabled: false,
            title: null,
            tooltip: null,
            axisX: {
              fieldId: null
            },
            axisY: {
              fieldId: null
            }
          }
        }
      }
    },
    watch: {
      loaded: {
        deep: true,
        handler () {
          const {loaded} = this
          if (loaded.display && loaded.graph) {
            this.$emit('loaded')
          }
        }
      },
      form: {
        deep: true,
        handler () {
          this.$emit('input', JSON.parse(JSON.stringify(this.form)))
        }
      }
    },
    created () {
      axios.get(`block/${this.blockId}/getBlockTypeSettings/DviewProductAnalogs`)
        .then(({data}) => {
          const {enabled, title, tooltip, axisX, axisY} = data.params || {}
          this.form.graph = {
            enabled,
            title,
            tooltip,
            axisX: {
              fieldId: axisX?.fieldId
            },
            axisY: {
              fieldId: axisY?.fieldId
            }
          }
          this.fields = data.fields.map(field => {
            return {
              id: field.id,
              text: `${field.label} (${field.name})`
            }
          })
          if (axisX?.fieldId) {
            this.fieldX = this.fields.find(field => field.id == axisX.fieldId)
          }
          if (axisY?.fieldId) {
            this.fieldY = this.fields.find(field => field.id == axisY.fieldId)
          }
          this.loaded.graph = true
        })
    }
  }
</script>