var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"name":"form","scrollable":true,"height":"auto","width":"700"},on:{"closed":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"well no-margin-bottom"},[_c('h4',[_c('strong',[_vm._v("Загрузить файлы")])]),_c('hr'),_c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.arrayFiles),function(item,i){return _c('tr',{key:item.file.name},[_c('td',{staticClass:"width-1"},[_vm._v(_vm._s(Number(i) + 1)+".")]),_c('td',{staticClass:"width-1 text-nowrap"},[_c('div',{staticStyle:{"padding":"5px 0"}},[_c('strong',[_vm._v(_vm._s(item.file.name))])]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.alt),expression:"item.alt"}],attrs:{"type":"text","title":"Альтернативный текст","placeholder":"Альтернативный текст"},domProps:{"value":(item.alt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "alt", $event.target.value)}}})]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.videoUrl),expression:"item.videoUrl"}],attrs:{"type":"text","title":"ID YouTube видео","placeholder":"ID YouTube видео"},domProps:{"value":(item.videoUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "videoUrl", $event.target.value)}}})])]),_c('td',{staticClass:"width-1 text-nowrap"},[_c('file-to-base64',{attrs:{"file":item.file},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ext = ref.ext;
var src = ref.src;
var isImage = ref.isImage;
var base64String = ref.base64String;
var height = ref.height;
var width = ref.width;
return [(isImage)?_c('div',[_c('table',[_c('tbody',[_c('tr',[_c('td',[(width && height)?_c('span',{staticClass:"font-xs"},[_vm._v(_vm._s(width)+"×"+_vm._s(height))]):_vm._e(),_c('br'),_c('span',{staticClass:"font-xs"},[_vm._v(_vm._s(_vm.fileSizeText(item.file.size)))])]),_c('td',{staticStyle:{"width":"50px"}},[_c('img',{attrs:{"src":src,"height":"80"}})])])])])]):_vm._e()]}}],null,true)})],1),_c('td',[_c('div',{staticClass:"delete-item",attrs:{"title":"Исключить из загрузки"},on:{"click":function($event){return _vm.arrayFiles.splice(i, 1)}}})])])}),0),_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-nowrap",attrs:{"colspan":"4"}},[_vm._v(" Общий размер: "),_c('strong',{class:{'text-danger': _vm.uploadMaxFilesizeExceeded}},[_vm._v(_vm._s(_vm.fileSizeText(_vm.totalSize)))]),(_vm.uploadMaxFilesizeExceeded)?_c('span',[_vm._v("(Макс.: "+_vm._s(_vm.fileSizeText(_vm.uploadMaxFilesize))+")")]):_vm._e()])]),_c('tr',[_c('td',{staticClass:"text-nowrap",attrs:{"colspan":"4"}},[_vm._v(" Кол-во файлов: "),_c('strong',{class:{'text-danger': _vm.maxFileUploadsExceeded}},[_vm._v(_vm._s(_vm.arrayFiles.length))]),(_vm.maxFileUploadsExceeded)?_c('span',[_vm._v("(Макс.: "+_vm._s(_vm.maxFileUploads)+")")]):_vm._e()])])])]),_c('hr'),_c('button',{staticClass:"btn btn-primary pull-right",attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.submit}},[(_vm.state)?_c('i',{staticClass:"fa fa-circle-o-notch fa-spin"}):_vm._e(),_vm._v(" Загрузить файлы ")]),_c('button',{staticClass:"btn btn-link pull-right",attrs:{"type":"button"},on:{"click":_vm.close}},[_vm._v("Отмена")]),_c('div',{staticClass:"clearfix"})])])}
var staticRenderFns = []

export { render, staticRenderFns }