<template>
  <modal name="form" @closed="$emit('close')" :scrollable="true" height="auto" width="900">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          Критерии сортировки
        </h4>
        <hr/>
        <div class="row">
          <div class="col col-sm-10">
            <xselect2
              v-model="field"
              label="Выбор ССК поля"
              tk="label"
              ref="fields"
              :src="fieldsSelect"
              width="100%"
            />
          </div>
          <div class="col col-sm-2">
            <button
              :disabled="!field.id"
              class="btn btn-primary pull-right"
              type="button"
              @click="addItem"
            >
              Добавить
            </button>
          </div>
        </div>
        <datatable
          :columns="columns"
          :options="{sortableRows: true}"
          :sort="false"
          :src="items"
          @rowSortDragstart="handleRowSortDragstart"
          @rowSortDragenter="handleRowSortDragenter"
          @removeRow="handleRemoveRow"
        />
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import TdLabel from './td/Label'
  import TdDirection from './td/Direction'
  import TdExpand from './td/Expand'
  import _uniqueId from 'lodash/uniqueId'
  import axios from 'axios'

  export default {
    name: 'DisplayOrderEditForm',
    data () {
      const items = JSON.parse(JSON.stringify(this.$store.state.display.form.filterCriteriaOrders))
        .sort((a, b) => {
          if (a.order < b.order) return -1
          if (a.order > b.order) return 1
          return 0
        })
        .map((item, idx) => {
          item.order = idx
          return item
        })
      return {
        items,
        field: {
          id: null,
          label: null
        },
        rowSortMovedId: null,
        processing: false
      }
    },
    computed: {
      columns () {
        return [
          {
            title: '#',
            number: true
          },
          {
            title: 'Метка',
            field: 'label',
            component: TdLabel,
            tdClass: 'text-nowrap'
          },
          {
            title: 'Направление по умолчанию',
            field: 'direction',
            component: TdDirection,
            tdClass: 'text-nowrap'
          },
          {
            title: 'Раскрыть для пользователя',
            field: 'isExpand',
            component: TdExpand,
            tdClass: 'text-nowrap'
          },
          {
            title: '',
            removeRowButton: true
          }
        ];
      },
      display () {
        return this.$store.state.display
      },
      displayId () {
        return this.display.form.id
      },
      fields () {
        return this.display.fields
      },
      fieldsSelect () {
        return this.fields
          .map(field => {
            return {
              id: field.id,
              text: `${field.label} (${field.name})`
            }
          })
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post(`dview/display/${this.displayId}/order/edit`, {items: this.items})
          .then(({data}) => {
            this.$store.commit('display/updateFormDisplayOrders', data)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      addItem () {
        const field = this.fields.find(field => field.id == this.field.id)
        const order = Math.max.apply(null, this.items.map(item => item.order)) + 1
        this.items.push({
          id: -_uniqueId(),
          direction: 0,
          display_id: this.displayId,
          expand_order: 0,
          field,
          field_id: this.field.id,
          isExpand: 0,
          label: null,
          order
        })
        this.field.id = null
        this.field.label = null
      },
      handleRemoveRow (id) {
        const idx = this.items.findIndex(item => item.id == id)
        this.items.splice(idx, 1)
      },
      handleRowSortDragstart (movedId) {
        this.rowSortMovedId = movedId
      },
      handleRowSortDragenter (enterId) {
        let movedItem = this.items.find(item => item.id == this.rowSortMovedId)
        let movedOrder = movedItem.order
        let enterItem = this.items.find(item => item.id == enterId)
        let enterOrder = enterItem.order
        movedItem.order = enterOrder
        enterItem.order = movedOrder
        this.items
          .sort((a, b) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>