<template>
  <div class="well well-sm">
    <fieldset>
      <div v-if="loading">Загрузка...</div>
      <div v-else>
        <div class="smart-form">
          <div class="row">
            <section class="col col-4">
              <section>
                <header>Реквизиты</header>
              </section>
              <section>
                <label class="label">Название</label>
                <label class="input">
                  <input
                    v-model="form.name"
                    type="text"
                    placeholder="Название"
                  />
                </label>
              </section>
              <section>
                <label class="label">ИНН</label>
                <label class="input">
                  <input
                    v-model="form.inn"
                    type="text"
                    placeholder="ИНН"
                  />
                </label>
              </section>
              <section>
                <label class="label">КПП</label>
                <label class="input">
                  <input
                    v-model="form.kpp"
                    type="text"
                    placeholder="КПП"
                  />
                </label>
              </section>
              <section>
                <label class="label">ОГРН</label>
                <label class="input">
                  <input
                    v-model="form.ogrn"
                    type="text"
                    placeholder="ОГРН"
                  />
                </label>
              </section>
            </section>
            <section class="col col-4">
              <section>
                <header>Контакты</header>
              </section>
              <section>
                <label class="label">Телефон</label>
                <label class="input">
                  <input
                    v-model="form.phone"
                    type="text"
                    placeholder="Телефон"
                  />
                </label>
              </section>
              <section>
                <label class="label">Телефон для регионов</label>
                <label class="input">
                  <input
                    v-model="form.phone_regions"
                    type="text"
                    placeholder="Телефон для регионов"
                  />
                </label>
              </section>
              <section>
                <label class="label">E-mail</label>
                <label class="input">
                  <input
                    v-model="form.email"
                    type="text"
                    placeholder="E-mail"
                  />
                </label>
              </section>
              <section>
                <label class="label">Адрес</label>
                <label class="textarea textarea-resizable">
                <textarea
                  v-model="form.address"
                  rows="2"
                  class="custom-scroll"
                  placeholder="Адрес"
                />
                </label>
              </section>
              <section>
                <label class="label">Адрес склада</label>
                <label class="textarea textarea-resizable">
                <textarea
                  v-model="form.stock_address"
                  rows="2"
                  class="custom-scroll"
                  placeholder="Адрес склада"
                />
                </label>
              </section>
            </section>
            <section class="col col-4">
              <section>
                <header>Реквизиты платежа</header>
              </section>
              <section>
                <label class="label">Банк</label>
                <label class="input">
                  <input
                    v-model="form.bank_name"
                    type="text"
                    placeholder="Банк"
                  />
                </label>
              </section>
              <section>
                <label class="label">Расчетный счет</label>
                <label class="input">
                  <input
                    v-model="form.checking_account"
                    type="text"
                    placeholder="Расчетный счет"
                  />
                </label>
              </section>
              <section>
                <label class="label">Корреспондентский счет</label>
                <label class="input">
                  <input
                    v-model="form.correspondent_account"
                    type="text"
                    placeholder="Корреспондентский счет"
                  />
                </label>
              </section>
              <section>
                <label class="label">БИК</label>
                <label class="input">
                  <input
                    v-model="form.bik"
                    type="text"
                    placeholder="БИК"
                  />
                </label>
              </section>
            </section>
          </div>
        </div>
        <hr/>
        <submit
          @click="submit"
          :processing="processing"
          class="btn btn-primary"
          icon="fa fa-save"
        >
          Сохранить
        </submit>
      </div>
    </fieldset>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'General',
    data () {
      return {
        form: {
          name: null,
          inn: null,
          kpp: null,
          ogrn: null,
          phone: null,
          phone_regions: null,
          email: null,
          address: null,
          stock_address: null,
          bank_name: null,
          checking_account: null,
          correspondent_account: null,
          bik: null
        },
        loading: true,
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post('siteconf/general/save', this.form)
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    mounted () {
      axios.get('siteconf/general/get')
        .then(({data}) => {
          this.form = data
          this.loading = false
        })
    }
  }
</script>