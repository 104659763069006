<template>
  <modal name="form" @closed="$emit('close')" height="auto">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          <strong>{{ isCreate ? 'Добавить' : 'Редактировать' }} фильтр</strong>
        </h4>
        <hr/>
        <div class="smart-form">
          <section>
            <label class="label">Метка</label>
            <label class="input">
              <input v-model="form.label" placeholder="Метка" type="text"/>
            </label>
          </section>
          <section>
            <label class="label">URL маска</label>
            <label class="input">
              <input v-model="form.path" placeholder="URL маска" type="text"/>
            </label>
            <div class="note">URL маска работает по принципу настройки видимости по URL страницы.</div>
          </section>
          <section>
            <label class="label">Отображение</label>
            <label class="select">
              <xselect2
                v-model="form.display"
                label="Выбор отображения"
                tk="label"
                :src="displays"
                width="100%"
              />
            </label>
          </section>
        </div>
        <hr/>
        <div class="pull-right">
          <button @click="apply" class="btn btn-link">
            Применить
          </button>
          <button @click="close" type="button" class="btn btn-link">Закрыть</button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'DviewFilterEditModal',
    props: ['value', 'displays'],
    data () {
      const value = this.value || {
        id: null,
        display_id: null,
        path: null,
        label: null,
        order: 0
      }
      return {
        form: {
          id: value.id,
          display: {
            id: value.display_id,
            label: this.getDisplayText(value.display_id)
          },
          path: value.path,
          label: value.label,
          order: value.order
        }
      }
    },
    computed: {
      isCreate () {
        return !this.value
      }
    },
    methods: {
      apply () {
        const {form} = this
        if (!form.display.id) {
          smallBoxErrors('Отображение обязательное поле.')
          return
        }
        const path = typeof form.path == 'string' ? form.path.trim() : ''
        const label = typeof form.label == 'string' ? form.label.trim() : ''
        this.$emit('input', {
          id: form.id,
          display_id: form.display.id,
          order: form.order,
          path: path,
          label: label
        })
        this.close()
      },
      getDisplayText (displayId) {
        const display = this.displays.find(display => display.id == displayId)
        return display ? display.text : ''
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>