<template>
  <div>
    <section>
      <label class="label">Переопределить заголовок отображения</label>
      <label class="input">
        <label class="input">
          <input
            v-model="title"
            placeholder="Переопределить заголовок отображения"
          />
        </label>
      </label>
      <div class="note">
        Переопредилить заголовок отображения и прочие заголовки контекстных фильтров.
        <br>Вы можете использовать машинные имена полей контекстных фильтров в качестве подстановочных шаблонов.
      </div>
    </section>
    <div class="row">
      <section class="col col-6">
        <label class="label">Подстановочный шаблон метки</label>
        <label class="input">
          <label class="input">
            <input
              v-model="wildcardTitle"
              placeholder="Подстановочный шаблон метки"
            />
          </label>
        </label>
        <div class="note">Подстановочный шаблон метки для вывода в заголовок отображения. Используйте [value] для подстановки значение фильтра.</div>
      </section>
      <section class="col col-6">
        <label class="label">Подстановочный шаблон метки исключаемого значения</label>
        <label class="input">
          <label class="input">
            <input
              v-model="exWildcardTitle"
              placeholder="Подстановочный шаблон метки исключаемого значения"
            />
          </label>
        </label>
        <div class="note">Подстановочный шаблон метки исключаемого значения для вывода в заголовок отображения.</div>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BottomForm',
    computed: {
      display () {
        return this.$store.state.display
      },
      form () {
        return JSON.parse(JSON.stringify(this.display.filterContextForm))
      },
      title: {
        get () {
          return this.form.title
        },
        set (value) {
          const {form} = this
          form.title = value
          this.updateForm(form)
        }
      },
      wildcardTitle: {
        get () {
          return this.form.wildcard_title
        },
        set (value) {
          const {form} = this
          form.wildcard_title = value
          this.updateForm(form)
        }
      },
      exWildcardTitle: {
        get () {
          return this.form.ex_wildcard_title
        },
        set (value) {
          const {form} = this
          form.ex_wildcard_title = value
          this.updateForm(form)
        }
      }
    },
    methods: {
      updateForm (form) {
        this.$store.commit('display/setFilterContextForm', form)
      }
    }
  }
</script>