<template>
  <component
    :is="activeTab"
    @close="switchActiveTab"
  />
</template>

<script>
  import File from './ContentImportFile'
  import Preview from './ContentImportPreview'
  import axios from 'axios'

  export default {
    name: 'ContentImport',
    components: {
      File,
      Preview
    },
    data () {
      return {
        activeTab: null
      }
    },
    methods: {
      switchActiveTab () {
        this.activeTab = this.activeTab == 'Preview' ? 'File' : 'Preview'
      }
    },
    async mounted () {
      const {data: {conf}} = await axios.get('content/import/status')
      this.activeTab = conf ? 'Preview' : 'File'
    }
  }
</script>