<template>
  <div>
    <div class="row">
      <div class="col col-12">
        <section>
          <label class="label">Переопределить заголовок отображения</label>
          <label class="input">
            <input
              v-model="displayTitle"
              placeholder="Заголовок отображения"
            />
          </label>
          <div class="note">Если оставить пустым, будет взят основной заголовок отображения.</div>
        </section>
      </div>
      <div class="col col-4">
        <section>
          <label class="label">Текст ссылки</label>
          <label class="input">
            <input
              v-model="text"
              placeholder="Текст ссылки"
            />
          </label>
          <div class="note">Текст, который будет отображаться в ссылке на представление.</div>
        </section>
        <section>
          <label class="label">Текст в Title</label>
          <label class="input">
            <input
              v-model="title"
              placeholder="Текст в Title"
            />
          </label>
          <div class="note">Текс в атрибуте "title".</div>
        </section>
        <section>
          <label class="label">Класс ссылки</label>
          <label class="input">
            <input
              v-model="linkClass"
              placeholder="Класс ссылки"
            />
          </label>
          <div class="note">CSS классы. Перечисление через пробел.</div>
        </section>
      </div>
      <div class="col col-4">
        <section>
          <label class="label">Rel атрибут</label>
          <label class="input">
            <input
              v-model="rel"
              placeholder="Rel атрибут"
            />
          </label>
          <div class="note">Значение атрибута "rel".</div>
        </section>
        <section>
          <label class="label">Target атрибут</label>
          <label class="input">
            <input
              v-model="target"
              placeholder="Target атрибут"
            />
          </label>
          <div class="note">Значение атрибута "target".</div>
        </section>
        <section>
          <label class="label">Номер в подстановочном шаблоне [display_link_N]</label>
          <label class="input">
            <input
              v-model="order"
              placeholder="Номер в подстановочном шаблоне [display_link_N]"
            />
          </label>
          <div class="note">Введите номер для подстановочного шаблона вывода ссылки.</div>
        </section>
      </div>
      <div class="col col-4">
        <section>
          <label class="label">Единственное число</label>
          <label class="input">
            <input
              v-model="wordSingular"
              placeholder="Единственное число"
            />
          </label>
          <div class="note">Слово в единственном числе для <strong>[result:count]</strong>.</div>
        </section>
        <section>
          <label class="label">Множественное число</label>
          <label class="input">
            <input
              v-model="wordPlural"
              placeholder="Множественное число"
            />
          </label>
          <div class="note">Слово во множественном числе для <strong>[result:count]</strong>.</div>
        </section>
        <section>
          <label class="label">Родительный падеж</label>
          <label class="input">
            <input
              v-model="wordGenitive"
              placeholder="Родительный падеж"
            />
          </label>
          <div class="note">Слово в родительном падеже для <strong>[result:count]</strong>.</div>
        </section>
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <section>
          <label class="checkbox">
            <input
              v-model="more"
              :true-value="1"
              :false-value="0"
              type="checkbox"
            />
            <i></i> Показать ссылку только если есть больше содержимого.
          </label>
        </section>
        <section>
          <label class="checkbox">
            <input
              v-model="viaExpands"
              :true-value="1"
              :false-value="0"
              type="checkbox"
            />
            <i></i> Показать ссылку только если есть хотя бы один активный раскрытый фильтр.
          </label>
        </section>
        <section>
          <label class="checkbox">
            <input
              v-model="ignoreExpands"
              :true-value="1"
              :false-value="0"
              type="checkbox"
            />
            <i></i> Игнорировать работу раскрытых фильтров для <strong>[result:count]</strong>.
          </label>
        </section>
        <section>
          <label class="checkbox">
            <input
              v-model="excludeCid"
              :true-value="1"
              :false-value="0"
              type="checkbox"
            />
            <i></i> Не включать материал с идентификатором в GET запросе на странице результатов.
          </label>
        </section>
      </div>
    </div>
    <section>
      <wildcards
        :items="wildcards"
      />
    </section>
  </div>
</template>

<script>
  import Wildcards from '../../../../Wildcards'

  export default {
    name: 'Form',
    components: { Wildcards },
    props: ['value'],
    data () {
      return {
        wildcards: []
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      macros () {
        return this.display.macros
      },
      form () {
        return JSON.parse(JSON.stringify(this.value))
      },
      displayTitle: {
        get () {
          return this.form.display_title
        },
        set (value) {
          const {form} = this
          form.display_title = value
          this.updateForm(form)
        }
      },
      text: {
        get () {
          return this.form.text
        },
        set (value) {
          const {form} = this
          form.text = value
          this.updateForm(form)
        }
      },
      title: {
        get () {
          return this.form.title
        },
        set (value) {
          const {form} = this
          form.title = value
          this.updateForm(form)
        }
      },
      linkClass: {
        get () {
          return this.form.class
        },
        set (value) {
          const {form} = this
          form.class = value
          this.updateForm(form)
        }
      },
      rel: {
        get () {
          return this.form.rel
        },
        set (value) {
          const {form} = this
          form.rel = value
          this.updateForm(form)
        }
      },
      target: {
        get () {
          return this.form.target
        },
        set (value) {
          const {form} = this
          form.target = value
          this.updateForm(form)
        }
      },
      order: {
        get () {
          return this.form.order
        },
        set (value) {
          const {form} = this
          form.order = value
          this.updateForm(form)
        }
      },
      wordSingular: {
        get () {
          return this.form.word_singular
        },
        set (value) {
          const {form} = this
          form.word_singular = value
          this.updateForm(form)
        }
      },
      wordPlural: {
        get () {
          return this.form.word_plural
        },
        set (value) {
          const {form} = this
          form.word_plural = value
          this.updateForm(form)
        }
      },
      wordGenitive: {
        get () {
          return this.form.word_genitive
        },
        set (value) {
          const {form} = this
          form.word_genitive = value
          this.updateForm(form)
        }
      },
      more: {
        get () {
          return this.form.more
        },
        set (value) {
          const {form} = this
          form.more = value
          this.updateForm(form)
        }
      },
      viaExpands: {
        get () {
          return this.form.via_expands
        },
        set (value) {
          const {form} = this
          form.via_expands = value
          this.updateForm(form)
        }
      },
      ignoreExpands: {
        get () {
          return this.form.ignore_expands
        },
        set (value) {
          const {form} = this
          form.ignore_expands = value
          this.updateForm(form)
        }
      },
      excludeCid: {
        get () {
          return this.form.exclude_cid
        },
        set (value) {
          const {form} = this
          form.exclude_cid = value
          this.updateForm(form)
        }
      }
    },
    methods: {
      updateForm (form) {
        this.$emit('input', form)
      }
    },
    mounted () {
      this.wildcards = this.display.form.filterContexts
        .map(context => {
          return {
            name: context.field.name,
            label: context.field.label
          }
        })
        .concat(
          [{
            name: '[result:count]',
            label: 'Количество в результате'
          }]
        )
        .concat(
          this.macros.map(item => {
            return {
              name: item[0],
              label: item[1]
            }
          })
        )
    }
  }
</script>