<template>
  <modal name="form" @closed="$emit('close')" height="auto" :scrollable="true">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          <strong>Выбор регионов</strong>
        </h4>
        <hr/>
        <div v-for="region in template.regions">
          <label class="checkbox">
            <input v-model="regions" :value="region.name" type="checkbox"/> <i></i>
            <span
              v-tooltip="{
                content: getPageTypes(region.name).map(item => `<div>${item}</div>`).join(''),
                placement: 'right',
                offset: 5,
                classes: ['default', 'template-region-pagetypes']
              }"
            >
              {{region.title}}
            </span>
          </label>
        </div>
        <hr/>
        <div class="pull-right">
          <button @click="apply" class="btn btn-link">
            Применить
          </button>
          <button @click="close" type="button" class="btn btn-link">Закрыть</button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'BlockRegionsForm',
    props: ['value'],
    data () {
      return {
        regions: []
      }
    },
    computed: {
      template () {
        return this.$store.state.common.template
      }
    },
    methods: {
      getPageTypes (region) {
        const pageTypes = []
        const pageTypesRegions = Object.entries(this.template.pageTypesRegions)
        for (const [pageTypeName, regions] of pageTypesRegions) {
          if (regions.includes(region)) {
            const pageType = this.template.pageTypes.find(pageType => pageType.name == pageTypeName)
            pageTypes.push(pageType.title)
          }
        }
        return pageTypes.length == pageTypesRegions.length ? ['Все страницы'] : pageTypes
      },
      apply () {
        this.$emit('input', JSON.parse(JSON.stringify(this.regions)))
        this.close()
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.regions = JSON.parse(JSON.stringify(this.value))
      this.$modal.show('form')
    }
  }
</script>

<style>
  .tooltip.template-region-pagetypes .tooltip-inner {
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
    color: #555;
  }
</style>