<template>
  <div>
    <div v-if="loading" class="well well-sm text-muted">Подготовка...</div>
    <div v-else class="smart-form well no-padding">
      <fieldset>
        <section>
          <header><span class="text-info">{{form.label}}</span> - Мета теги</header>
        </section>
        <section>
          <label class="label">Заголовок страницы</label>
          <label class="textarea">
            <textarea
              v-model="form.pageTitle"
              rows="3"
              class="custom-scroll"
              placeholder="Заголовок страницы"
            ></textarea>
          </label>
          <div class="note">Значение этого поля предназначается для тега &lt;title&gt;.</div>
        </section>
        <section>
          <label class="label">Мета тег "Keywords"</label>
          <label class="textarea">
            <textarea
              v-model="form.metakeywords"
              rows="3"
              class="custom-scroll"
              placeholder="Мета тег &quot;Keywords&quot;"
            ></textarea>
          </label>
        </section>
        <section>
          <label class="label">Мета тег "Description"</label>
          <label class="textarea">
            <textarea
              v-model="form.metadescription"
              rows="3"
              class="custom-scroll"
              placeholder="Мета тег &quot;Description&quot;"
            ></textarea>
          </label>
        </section>
        <div class="row">
          <section class="col col-12">
            <section>
              <wildcards :items="wildcards"/>
            </section>
          </section>
        </div>
      </fieldset>
      <footer>
        <submit @click="submit" :processing="processing" class="btn btn-primary" icon="fa fa-save">
          Применить
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import Wildcards from '../Wildcards'
  import axios from 'axios'

  export default {
    name: 'ContentTypeMeta',
    components: { Wildcards },
    props: ['contentTypeId'],
    data () {
      return {
        form: {
          label: null,
          pageTitle: null,
          metakeywords: null,
          metadescription: null
        },
        wildcards: [],
        loading: true,
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post(`contentType/${this.contentTypeId}/meta/edit`, this.form)
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async created () {
      const {data} = await axios.get(`contentType/${this.contentTypeId}/meta/get`)
      this.form = data.contentType
      this.wildcards = data.wildcards
      this.loading = false
    }
  }
</script>