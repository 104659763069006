<template>
  <modal name="form" @closed="$emit('close')" height="auto" :scrollable="true" width="1000">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          Группировка результата по вкладкам
        </h4>
        <hr/>
        <div class="row">
          <section class="col col-6">
            <section>
              <label class="checkbox">
                <input
                  v-model="form.tabs_on"
                  type="checkbox"
                />
                <i></i> Включить группировку результата по вкладкам
              </label>
            </section>
            <section>
              <label class="label">Источник вкладок</label>
              <div class="inline-group">
                <label class="radio">
                  <input
                    v-model="form.tabs_mode"
                    type="radio"
                    value="criteria"
                    name="displayTabsMode"
                  />
                  <i></i> Раскрытые фильтры
                </label>
                <label class="radio">
                  <input
                    v-model="form.tabs_mode"
                    type="radio"
                    value="tags"
                    name="displayTabsMode"
                  />
                  <i></i> Теги в контексте материала
                </label>
              </div>
            </section>
            <section>
              <label class="checkbox">
                <input
                  v-model="form.atab_on"
                  type="checkbox"
                />
                <i></i> Включить вкладку "Все результаты"
              </label>
            </section>
            <div v-if="form.atab_on">
              <section>
                <label class="checkbox">
                  <input
                    v-model="form.atab_last"
                    type="checkbox"
                  />
                  <i></i> Разместить вкладку "Все результаты" в конце
                </label>
              </section>
              <section>
                <label class="label">Метка вкладки "Все результаты"</label>
                <label class="input">
                  <input
                    v-model="form.atab_label"
                    type="text"
                    placeholder="Метка вкладки &quot;Все результаты&quot;"
                  />
                </label>
              </section>
              <section>
                <label class="label">Заголовок для каждой группы в блоке "Все результаты"</label>
                <label class="input">
                  <input
                    v-model="form.atab_group_label"
                    type="text"
                    placeholder="Заголовок для каждой группы в блоке &quot;Все результаты&quot;"
                  />
                </label>
                <div class="note">Используйте подстановочный шаблон <strong>[label]</strong> для вывода метки.</div>
              </section>
            </div>
          </section>
          <section class="col col-6">
            <display-tab-fields
              v-model="form.tab_fields"
            />
          </section>
        </div>
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import DisplayTabFields from './DisplayTabFields'
  import axios from 'axios'

  export default {
    name: 'DisplayTabsEditForm',
    components: { DisplayTabFields },
    data () {
      const displayTabs = this.$store.state.display.form.displayTabs
      return {
        form: {
          tabs_on: displayTabs.tabs_on,
          tabs_mode: displayTabs.tabs_mode,
          atab_on: displayTabs.atab_on,
          atab_last: displayTabs.atab_last,
          atab_label: displayTabs.atab_label,
          atab_group_label: displayTabs.atab_group_label,
          tab_fields: displayTabs.tab_fields
        },
        processing: false
      }
    },
    computed: {
      displayId () {
        return this.$store.state.display.form.id
      }
    },
    methods: {
      submit () {
        const {form} = this
        this.processing = true
        axios.post(`dview/display/${this.displayId}/tab/edit`, form)
          .then(({data}) => {
            smallBoxSuccess('Выполнено.')
            form.tab_fields = data
            this.$store.commit('display/updateFormDisplayTabs', form)
            this.close()
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>