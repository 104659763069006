<template>
  <form
    v-if="can('interrelation.export.w')"
    action="/interrelation/export" method="post"
    class="smart-form well no-padding"
  >
    <fieldset>
      <h2>Экспорт взаимосвязей</h2>
      <hr style="margin: 10px 0;"/>
      <div class="row">
        <section class="col col-6">
          <section>
            <header>Выбор типов материалов.</header>
            <div class="note">Отметьте типы, материалы которых требуется экспортировать в файл.</div>
          </section>
          <section>
            <label class="checkbox checkbox-all checkbox-contenttypes">
              <input
                v-model="selectAllContentTypes"
                type="checkbox"
                @change="hangleSelectAllContentTypes"
              />
              <i></i> <strong>Отметить все</strong>
            </label>
            <label class="input content-type-filter">
              <input
                v-model="contentTypeLabel"
                placeholder="Отфильтровать типы материалов для отображения."
                type="text"
              />
            </label>
            <div class="note">Отфильтровать типы материалов для отображения.</div>
            <hr/>
            <label
              v-for="contentType in contentTypes"
              :key="contentType.id"
              class="checkbox"
            >
              <input
                v-model="form.contentTypeIds"
                :value="contentType.id"
                name="contentTypeIds[]"
                type="checkbox"/>
              <i></i> {{contentType.label}}
            </label>
          </section>
          <section>
            <header>Применить фильтрацию материалов по полю "Title"</header>
          </section>
          <section>
            <label class="input">
              <input
                name="contentTitle"
                placeholder="Применить фильтрацию материалов по полю &quot;Title&quot;"
                type="text"
              />
              <div class="note">Применить фильтрацию материалов по полю "Title"</div>
            </label>
          </section>
        </section>
        <section class="col col-6">
          <section>
            <header>Включить взаимосвязи в файл экспорта.</header>
            <div class="note">Отметьте взаимосвязи, которые требуется включить в файл.</div>
          </section>
          <section>
            <label class="checkbox checkbox-all">
              <input
                v-model="selectAllInterrelations"
                type="checkbox"
                @change="handleSelectAllInterrelations"
              />
              <i></i> <strong>Отметить все</strong>
            </label>
            <hr>
            <label
              v-for="interrelation in interrelations"
              :key="interrelation.id"
              class="checkbox"
            >
              <input
                v-model="form.interrelationIds"
                :value="interrelation.id"
                name="interrelationIds[]"
                type="checkbox"
              />
              <i></i>
              {{interrelation.label}}
              <span class="text-muted">({{interrelation.name}})</span>
            </label>
          </section>
        </section>
      </div>
      <section>
        <header>Настройки файла экспорта</header>
      </section>
      <div class="row">
        <div class="col col3">
          <section>
            <label class="label">Разделитель столбцов в csv</label>
            <label class="select">
              <select name="csv_delimiter">
                <option value=",">Запятая</option>
                <option value=";" selected>Точка с запятой</option>
              </select>
              <i></i>
            </label>
          </section>
          <section>
            <label class="checkbox">
              <input name="to_windows_1251" value="1" type="checkbox" checked>
              <i></i> Скачать файл в кодировке Windows-1251
            </label>
          </section>
        </div>
      </div>
      <footer>
        <button type="submit" class="btn btn-primary">
          <i class="fa fa-download"></i> Экспорт
        </button>
      </footer>
      <div v-html="config.csrf_field"></div>
    </fieldset>
  </form>
</template>

<script>
  import _escapeRegExp from "lodash/escapeRegExp";

  export default {
    name: 'InterrelationExport',
    data () {
      return {
        selectAllContentTypes: false,
        selectAllInterrelations: false,
        form: {
          contentTypeIds: [],
          interrelationIds: []
        }
      }
    },
    computed: {
      contentTypes () {
        const split = this.contentTypeLabel.split(' ').filter(string => string.trim())
        const regexp = new RegExp(split.map(string => `(?=.*${_escapeRegExp(string)})`).join(''), 'i')
        return this.config.contentTypes.filter(contentType => {
          return regexp.test(contentType.label)
        })
      },
      contentTypeIds () {
        return this.contentTypes.map(contentType => contentType.id)
      },
      interrelations () {
        return this.config.interrelations
      },
      config () {
        return this.$root.config
      },
      contentTypeLabel: {
        get () {
          return this.$store.state.cookies.contentExportContentTypeLabel || ''
        },
        set (value) {
          this.$store.commit('cookies/contentExportSetContentTypeLabel', value)
        }
      },
      can () {
        return window.has_access
      }
    },
    watch: {
      'form.interrelationIds': {
        deep: true,
        handler () {
          this.selectAllInterrelations = this.form.interrelationIds.length == this.interrelations.length
        }
      },
      'form.contentTypeIds': {
        deep: true,
        handler () {
          this.selectAllContentTypes = this.form.contentTypeIds.length &&
            this.form.contentTypeIds.length == this.contentTypeIds.length
        }
      },
      contentTypeLabel () {
        this.form.contentTypeIds = this.form.contentTypeIds.filter(id => {
          return this.contentTypeIds.includes(id)
        })
      }
    },
    methods: {
      hangleSelectAllContentTypes () {
        this.form.contentTypeIds = []
        if (this.selectAllContentTypes) {
          this.form.contentTypeIds = this.contentTypeIds
        }
      },
      handleSelectAllInterrelations () {
        this.form.interrelationIds = []
        if (this.selectAllInterrelations) {
          this.form.interrelationIds = this.interrelations.map(item => item.id)
        }
      }
    }
  }
</script>