<template>
  <div>
    <div class="smart-form well no-padding">
      <fieldset style="padding: 7px 25px !important;">
        <div v-if="loading" class="text-muted">Подготовка...</div>
        <display-manage-btns
          v-else
          :display-id="displayId"
          :dview-id="dviewId"
        />
      </fieldset>
      <display-form
        v-if="displayId && !loading"
        :display-id="displayId"
      />
      <datatable
        v-else
        :columns="columns"
        :options="{tableClass: 'table-bordered'}"
        :src="`dview/${dviewId}/display/list`"
        @openDisplay="openDisplay"
        @payload="handlePayload"
      />
    </div>
  </div>
</template>

<script>
  import DisplayManageBtns from './DisplayManageBtns'
  import DisplayForm from './DisplayForm'
  import TdLabel from './td/Label'
  import axios from 'axios'

  export default {
    name: 'Display',
    props: ['dviewId', 'displayId'],
    components: {
      DisplayManageBtns,
      DisplayForm
    },
    data () {
      return {
        dview: {},
        loading: true
      }
    },
    computed: {
      columns () {
        return [
          {
            title: 'Наименование',
            field: 'label',
            filter: {type: 'text', width: '98%'},
            component: TdLabel,
            tdClass: 'text-nowrap'
          }
        ]
      }
    },
    methods: {
      openDisplay (id) {
        this.$emit('openDisplay', id)
      },
      handlePayload (data) {
        this.dview = data
        this.loading = false
      }
    },
    async created () {
      if (!this.displayId) {
        return
      }
      const {data} = await axios.get(`dview/${this.dviewId}/get`)
      this.dview = data.dview
      this.loading = false
    }
  }
</script>