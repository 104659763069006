<template>
  <div class="smart-form well">
    <section style="position: relative;">
      <h2>Настройка загрузки файла импорта</h2>
      <div style="position: absolute; right:0; top:0;">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn btn-default btn-sm content-import-generate-header"
            @click="showFileHeaderModal = true"
          >
            <i class="fa fa-columns"></i> Сгенерировать шапку
          </button>
        </div>
      </div>
    </section>
    <div v-if="loading">Подготовка...</div>
    <div v-else>
      <section>
        <section>
          <header>Режим импорта</header>
        </section>
        <div class="inline-group">
          <label class="radio">
            <input
              v-model="form.mode"
              value="insert"
              type="radio"
            />
            <i></i> Вставка новых материалов
          </label>
          <label class="radio">
            <input
              v-model="form.mode"
              value="update"
              type="radio"
            />
            <i></i> Обновление существующих материалов
          </label>
        </div>
      </section>
      <section>
        <section>
          <header>Тип материала</header>
        </section>
        <label class="select">
          <xselect2
            v-model="contentType"
            label="Выбор типа материала"
            tk="text"
            :src="contentTypes"
            width="100%"
            @change="form.content_type_id = $event.id"
          />
        </label>
      </section>
      <section>
        <section>
          <header>Файл импорта материалов</header>
        </section>
        <label class="input input-file">
          <input
            type="file"
            accept=".csv"
            @change="onChangeFile"
          >
        </label>
      </section>
      <footer>
        <submit
          :processing="processing"
          :disabled="!isReadyToUpload"
          icon="fa fa-upload"
          class="btn btn-primary"
          @click="upload"
        >
          Загрузить
        </submit>
      </footer>
    </div>
    <download-file-header-modal
      v-if="showFileHeaderModal"
      :content-types="contentTypes"
      @close="showFileHeaderModal = false"
    />
  </div>
</template>

<script>
  import DownloadFileHeaderModal from './ContentImportDownloadFileHeaderModal'
  import axios from 'axios'

  export default {
    name: 'ContentImportFile',
    components: { DownloadFileHeaderModal },
    data () {
      return {
        form: {
          mode: 'insert',
          content_type_id: null,
          file: null
        },
        contentType: {
          id: null,
          text: null
        },
        contentTypes: [],
        status: null,
        statusIntervalId: null,
        showFileHeaderModal: false,
        processing: false,
        loading: true
      }
    },
    computed: {
      isReadyToUpload () {
        return this.form.content_type_id && this.form.file
      },
      isUploading () {
        return Boolean(this.status?.isUploading)
      },
      isImporting () {
        return Boolean(this.status?.isImporting)
      }
    },
    watch: {
      isUploading (isUploading) {
        if (isUploading) {
          this.close()
        }
      },
      isImporting (isImporting) {
        if (isImporting) {
          this.close()
        }
      }
    },
    methods: {
      onChangeFile (e) {
        this.form.file = e.target.files[0]
      },
      upload () {
        const {form} = this
        const formData = new FormData()
        formData.append('file', form.file)
        formData.append('content_type_id', form.content_type_id)
        formData.append('mode', form.mode)
        this.processing = true
        axios
          .post('content/import/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.stopStatus()
        this.$emit('close')
      },
      watchStatus () {
        this.statusIntervalId = setInterval(async () => {
          const {data} = await axios.get('content/import/status')
          this.status = data
        }, 1000)
      },
      stopStatus () {
        clearInterval(this.statusIntervalId)
      }
    },
    async mounted () {
      this.watchStatus()
      let {data: {contentTypes}} = await axios.get('content/import/getContentTypes')
      this.contentTypes = contentTypes.map(contentType => {
        return {
          id: contentType.id,
          text: `${contentType.label} (${contentType.name})`
        }
      })
      this.loading = false
    },
    beforeDestroy () {
      this.stopStatus()
    }
  }
</script>