<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <template v-else>
      <fieldset>
        <section>
          <header>Транспортная компания</header>
        </section>
        <section>
          <label class="radio">
            <input
              v-model="form.carrier"
              name="carrier"
              value="dellin"
              type="radio"
              @change="emit"
            />
            <i></i> Деловые линии
          </label>
        </section>
        <section>
          <label class="radio">
            <input
              v-model="form.carrier"
              name="carrier"
              value="pec"
              type="radio"
              @change="emit"
            />
            <i></i> ПЭК
          </label>
        </section>
        <section>
          <header>Тип доставки</header>
        </section>
        <section>
          <label class="radio">
            <input
              v-model="form.type"
              name="type"
              value="domain"
              type="radio"
              @change="emit"
            />
            <i></i> Терминальная доставка на основе текущего домена
          </label>
        </section>
        <section>
          <label class="radio">
            <input
              v-model="form.type"
              name="type"
              value="geo"
              type="radio"
              @change="emit"
            />
            <i></i> Адресная доставка на основе географического положения
          </label>
        </section>
      </fieldset>
    </template>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Delivery',
    props: ['blockId'],
    data () {
      return {
        form: {
          carrier: null,
          type: null
        },
        loading: true
      }
    },
    methods: {
      emit () {
        this.$emit('input', JSON.parse(JSON.stringify(this.form)))
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getBlockTypeSettings/Delivery`)
      this.form.carrier = data.carrier
      this.form.type = data.type
      this.loading = false
      this.$emit('loaded')
      this.emit()
    }
  }
</script>