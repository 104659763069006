<template>
  <button @click="$parent.$emit('setEditTab', row.id)" type="button" class="btn btn-link no-padding font-sm">
    {{value}}
  </button>
</template>

<script>
  export default {
    name: 'Label',
    props: ['row', 'value']
  }
</script>