<template>
  <div class="select2-container select2-container-multi select2" style="width:100%">
    <ul class="select2-choices">
      <li v-for="(item, idx) in items" class="select2-search-choice">
        <div>{{item}}</div>
        <a @click.prevent="removeItem(idx)" href="#" class="select2-search-choice-close" tabindex="-1"></a>
      </li>
      <li class="select2-search-field">
        <label class="select2-offscreen"></label>
        <input
          @keyup.enter="addItem($event.target.value)"
          ref="input"
          type="text"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          class="select2-input"
          :placeholder="placeholder"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: ['value', 'placeholder'],
    data () {
      return {
        items: this.value ? this.value.slice(0) : []
      }
    },
    methods: {
      addItem (value) {
        value = value.trim()
        if (value) {
          const existsIdx = this.items.findIndex(item => item == value)
          if (existsIdx > -1) {
            // todo: подсветить элемент с индексом existsIdx
          } else {
            this.items.push(value)
            this.$emit('input', this.items)
          }
        }
        this.$refs.input.value = ''
      },
      removeItem (idx) {
        this.items.splice(idx, 1)
        this.$refs.input.focus()
        this.$emit('input', this.items)
      }
    },
    mounted () {
      this.$refs.input.focus()
    }
  }
</script>
