<template>
  <modal name="form" @closed="$emit('close')" height="auto">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          <strong>Редактировать URL</strong>
        </h4>
        <hr/>
        <section>
          <label class="label">URL</label>
          <label class="input">
            <input
              v-model="url"
              placeholder="Введите URL"
              type="text"
            />
          </label>
          <div class="note">
            <p>
              Вы можете применить символ
              <strong>*</strong> для шаблона.
            </p>
            <p>
              Пример №1:
              <strong>zatvory</strong> - блок выводится только на странице
              <strong>zatvory</strong>
            </p>
            <p>
              Пример №2:
              <strong>zatvory*</strong> - блок будет выводиться на страницах, url которых начинается на
              <strong>zatvory</strong>
            </p>
            <p>
              Пример №3: <strong>*zatvory</strong> - блок будет выводиться на страницах, url которых оканчивается на
              <strong>zatvory</strong>
            </p>
            <p>
              Пример №4: <strong>*zatvory*</strong> - блок будет выводиться на страницах, в url которых встречается
              <strong>zatvory</strong>
            </p>
          </div>
        </section>
        <hr/>
        <div class="pull-right">
          <button
            :disabled="!trimmedUrl"
            class="btn btn-link"
            @click="apply"
          >
            Применить
          </button>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'UrlEditModal',
    props: ['value'],
    data () {
      return {
        url: this.value
      }
    },
    computed: {
      trimmedUrl () {
        return typeof this.url == 'string' ? this.url.trim() : ''
      }
    },
    methods: {
      apply () {
        if (this.trimmedUrl) {
          this.$emit('input', this.trimmedUrl)
        }
        this.close()
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>