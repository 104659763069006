<template>
  <div>
    Взаимосвязь с материалами
    <button
      class="btn btn-link no-padding font-sm"
      type="button"
      @click="showEditForm = true"
    >
      Настроить
    </button>
    <div v-if="interrelation" style="margin-top: 2px;">
      {{interrelation.label}}
      <div class="note">
        Поиск взаимосвязи
        "{{ interrelation.label }} ({{ interrelation.name }})"
        в контексте материала на странице.
      </div>
    </div>
    <div v-else class="text-muted">
      Взаимосвязь не установлена.
    </div>
    <display-interrelation-edit-form
      v-if="showEditForm"
      @close="showEditForm = false"
    />
  </div>
</template>

<script>
  import DisplayInterrelationEditForm from './DisplayInterrelationEditForm'

  export default {
    name: 'DisplayInterrelation',
    components: { DisplayInterrelationEditForm },
    data () {
      return {
        showEditForm: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      interrelation () {
        return this.display.interrelations.find(item => item.id == this.display.form.interrelationId)
      }
    }
  }
</script>