<template>
  <span class="xfield">
    <div v-if="state" class="xfield-editable">
      <div class="xfield-input">
        <select v-model="currentValue" :style="'width:'+width+'px;'" class="form-control">
        <option value="" selected disabled>{{label}}</option>
        <option v-for="item in options" :value="item[pk]">{{item[tk]}}</option>
      </select>
      </div>
      <button @click="apply" type="button" class="btn btn-primary btn-sm"><i class="glyphicon glyphicon-ok"></i></button>
      <button @click="toggle" type="button" class="btn btn-default btn-sm"><i class="glyphicon glyphicon-remove"></i></button>
    </div>
    <div v-else-if="disabled && editable" class="xfield-disabled text-muted">{{label}}</div>
    <div v-else class="xfield-static" :class="{'empty-value':isEmpty, 'compact': (isCompact && isEditable), 'unsaved': unsaved}">
      <span v-if="!(!isEditable && isEmpty)" @click="toggle(true)">{{stateLabel}} <sup v-if="isRequired && isEmpty" class="fa fa-asterisk required"></sup></span>
      <span v-else class="text-muted"><i>не задано</i></span>
      <a v-if="!isCompact && isEditable" @click.prevent="toggle" href="#" class="toggle font-xs">изменить</a>
    </div>
    <div v-if="error" v-html="error" class="text-danger font-xs"></div>
  </span>
</template>

<script>
  export default {
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: [String, Number],
      options: Array,
      errors: [String, Array],
      required: String,
      width: String,
      label: {
        type: String,
        default: ''
      },
      pk: {
        type: String,
        'default': 'id' // primary key
      },
      tk: {
        type: String,
        'default': 'name' // text key
      },
      disabled: {
        type: Boolean,
        'default': false
      },
      editable: {
        type: Boolean,
        'default': true
      },
      compact: {
        type: String,
        'default': void (0)
      }
    },
    data () {
      return {
        field: null,
        state: false,
        unsaved: false,
        initValue: null,
        currentValue: null
      }
    },
    methods: {
      apply () {
        this.$emit('change', this.currentValue)
        this.unsaved = this.currentValue != this.initValue
        this.state = false
      },
      toggle (compactOnly) {
        if (!this.isEditable) {
          return
        }
        if (compactOnly === true && !this.isCompact) {
          return
        }
        this.state = !this.state
      },
      on () {
        this.$nextTick(() => {
          if (this.field === null) {
            this.initValue = this.currentValue = this.value
          }
          this.field = $(this.$el).find('select')
          this.field.focus()
        })
        setTimeout(() => {
          document.documentElement.addEventListener('click', this.onDocumentAction, false)
          document.documentElement.addEventListener('keyup', this.onDocumentAction, false)
        }, 100)
      },
      off () {
        document.documentElement.removeEventListener('click', this.onDocumentAction, false)
        document.documentElement.removeEventListener('keyup', this.onDocumentAction, false)
      },
      onDocumentAction (e) {
        let $target = $(e.target)
        let $xfield = $target.closest('.xfield')
        if (e.type == 'click') {
          if ($xfield.length) {
            if ($target.hasClass('toggle')) {
              this.state = false
            }
          } else {
            this.state = false
          }
        } else if (e.type == 'keyup') {
          if (e.which == 27) {
            this.state = false
          }
        }
      }
    },
    watch: {
      state (value) {
        if (value) {
          this.on()
        } else {
          this.off()
        }
      }
    },
    computed: {
      error () {
        return (this.errors instanceof Array) ? this.errors.join('<br>') : this.errors
      },
      isRequired () {
        return typeof this.required !== 'undefined'
      },
      isCompact () {
        return typeof this.compact !== 'undefined'
      },
      isEditable () {
        return typeof this.editable === 'undefined' || this.editable
      },
      isEmpty () {
        return this.value == null || this.value === ''
      },
      stateLabel () {
        let option = this.options.find(item => {
          return item[this.pk] == this.value
        })
        return option ? option[this.tk] : this.label
      }
    },
    created () {
      this.$parent.$on('success', () => {
        this.initValue = this.value
        this.unsaved = false
      })
    }
  }
</script>