<template>
  <div>
    <section>
      <header>Настройки видимости</header>
    </section>
    <section v-if="!loading || form.visibility == 'all'">
      <div class="inline-group">
        <label v-for="item in visibilityTypes" class="radio">
          <input v-model="form.visibility" type="radio" name="visibility" :value="item.type"/>
          <i></i> {{ item.title }}
        </label>
      </div>
    </section>
    <component
      v-model="visibilityItems"
      :block-id="block.id"
      :is="visibilityComponent"
      style="margin-bottom: 15px;"
      @loaded="handleLoaded"
    />
    <section v-if="!loading && form.visibility != 'all'">
      <div class="inline-group visibility-mode-section">
        <label class="radio">
          <input v-model="form.mode" type="radio" name="mode" value="include"/>
          <i></i> Выводить блок только на перечисленных объектах
        </label>
        <label class="radio">
          <input v-model="form.mode" type="radio" name="mode" value="exclude"/>
          <i></i> Выводить на всех объектах, кроме перечисленных
        </label>
      </div>
    </section>
  </div>
</template>

<script>
  import BlockVisibilityByContent from './by_content/BlockVisibilityByContent'
  import BlockVisibilityByContentType from './by_content_type/BlockVisibilityByContentType'
  import BlockVisibilityByPageUrl from './by_url/BlockVisibilityByPageUrl'
  import BlockVisibilityByDomain from './by_domain/BlockVisibilityByDomain'
  import BlockVisibilityByGeo from './by_geo/BlockVisibilityByGeo'

  export default {
    name: 'BlockVisibilitySettings',
    components: {
      BlockVisibilityByContent,
      BlockVisibilityByContentType,
      BlockVisibilityByPageUrl,
      BlockVisibilityByDomain,
      BlockVisibilityByGeo
    },
    model: {
      prop: 'blockVisibilityItems',
      event: 'input'
    },
    props: ['block', 'blockVisibilityItems'],
    data () {
      return {
        form: {
          visibility: 'all',
          mode: 'include'
        },
        visibilityItems: {},
        loading: true
      }
    },
    computed: {
      visibilityTypes () {
        return [
          {
            type: 'content_id',
            title: 'По идентификатору материала',
            component: 'BlockVisibilityByContent'
          },
          {
            type: 'content_type',
            title: 'По типу материала',
            component: 'BlockVisibilityByContentType'
          },
          {
            type: 'page_url',
            title: 'По URL страницы',
            component: 'BlockVisibilityByPageUrl'
          },
          {
            type: 'domain',
            title: 'По домену',
            component: 'BlockVisibilityByDomain'
          },
          {
            type: 'geo',
            title: 'По географическому положению',
            component: 'BlockVisibilityByGeo'
          },
          {
            type: 'all',
            title: 'Отображать всегда',
            component: null
          }
        ]
      },
      visibilityComponent () {
        return this.visibilityTypes
          .find(item => item.type == this.form.visibility)
          .component
      }
    },
    watch: {
      form: {
        deep: true,
        handler () {
          this.$emit('change', this.form)
        }
      },
      visibilityItems: {
        deep: true,
        handler () {
          this.$emit('input', JSON.parse(JSON.stringify(this.visibilityItems)))
        }
      }
    },
    methods: {
      handleLoaded () {
        this.loading = false
        this.$emit('loaded')
      }
    },
    created () {
      this.form.visibility = this.block.visibility
      this.form.mode = this.block.mode
      this.visibilityItems = JSON.parse(JSON.stringify(this.blockVisibilityItems))
    }
  }
</script>