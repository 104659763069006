<template>
  <modal name="form" @closed="$emit('close')" height="auto" :scrollable="true">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          {{ isCreate ? 'Создать' : 'Редактировать' }} отображение
        </h4>
        <hr/>
        <section>
          <label class="label">Метка</label>
          <label class="input">
            <input v-model="form.label" placeholder="Метка" type="text"/>
          </label>
        </section>
        <section>
          <label class="label">Машинное имя</label>
          <label class="input">
            <input v-model="form.name" placeholder="Машинное имя" type="text"/>
          </label>
        </section>
        <section>
          <label class="label">Заголовок отображения</label>
          <label class="input">
            <input v-model="form.title" placeholder="Заголовок отображения" type="text"/>
          </label>
        </section>
        <section>
          <label class="label">URL адрес отображения</label>
          <label class="input">
            <input v-model="form.url" placeholder="URL адрес отображения" type="text"/>
          </label>
        </section>
        <hr/>
        <div class="pull-right">
          <submit @click="submit" :processing="processing" class="btn btn-link">
            Применить
          </submit>
          <button @click="close" type="button" class="btn btn-link">Закрыть</button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DisplayEditForm',
    props: ['isCreate', 'dviewId'],
    data () {
      const form = this.$store.state.display.form
      const displayForm = this.isCreate ? {viewId: this.dviewId} : form
      return {
        form: {
          id: displayForm.id,
          name: displayForm.name,
          label: displayForm.label,
          title: displayForm.title,
          url: displayForm.url,
          view_id: displayForm.viewId
        },
        processing: false
      }
    },
    methods: {
      submit () {
        const {form, isCreate} = this
        const action = isCreate ? 'create' : `${form.id}/edit`
        this.processing = true
        axios.post(`dview/display/${action}`, form)
          .then(({data}) => {
            smallBoxSuccess('Выполнено.')
            if (isCreate) {
              window.location.hash = `dview?tab=Display&id=${data.view_id}&displayId=${data.id}`
            } else {
              this.$store.commit('display/updateFormDisplay', form)
              this.close()
            }
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>