<template>
  <div>
    <div v-if="loading.this" class="well well-sm text-muted">Подготовка...</div>
    <div v-else class="smart-form well no-padding">
      <fieldset>
        <section>
          <header>Основные настройки</header>
        </section>
        <section>
          <label class="label">Машинное имя</label>
          <label class="input">
            <input v-model="form.name" placeholder="Машинное имя" type="text"/>
          </label>
          <div class="note">Уникальное имя блока. Используется для обращения к блоку из других модулей системы.</div>
        </section>
        <section>
          <label class="label">Описание блока</label>
          <label class="input">
            <input v-model="form.description" placeholder="Описание блока" type="text"/>
          </label>
          <div class="note">Описание блока показывается на странице блоков.</div>
        </section>
        <section>
          <label class="label">Заголовок блока</label>
          <label class="input">
            <input v-model="form.title" placeholder="Заголовок блока" type="text"/>
          </label>
          <div class="note">Заголовок блока, как он будет показан пользователю.</div>
        </section>
        <template v-if="!isCreate">
          <section class="block-type">
            <label class="label">Тип содержимого блока</label>
            <label class="select">
              <select v-model="form.type">
                <option v-for="item in options.blockTypes" :value="item.type">{{item.title}}</option>
              </select>
              <i></i>
            </label>
            <component
              v-model="blockTypeSettings"
              :is="form.type"
              :block-id="blockId"
              style="background: #EFEFEF"
              @loaded="loading.typeSettings = false"
            />
          </section>
          <section>
            <label class="checkbox">
              <input v-model="form.enabled" type="checkbox"/>
              <i></i> Включить блок
            </label>
          </section>
          <section>
            <label class="checkbox">
              <input v-model="form.auth_only" type="checkbox"/>
              <i></i> Только для авторизованных пользователей
            </label>
          </section>
          <section>
            <header>
              Настройки региона
              <button
                class="btn btn-link no-padding"
                style="font-size: 72%;"
                @click="showBlockRegionsModal = true"
              >
                Выбрать регион
              </button>
            </header>
            <div class="note">Укажите в каком регионе должен отображаться этот блок.</div>
          </section>
          <block-regions-list :items="form.blockRegions">
            <template slot="removeButton" slot-scope="{region}">
              <button
                v-if="!region.available"
                class="btn btn-link font-xs no-padding"
                type="button"
                @click="removeRegion(region.name)"
              >
                <i class="fa fa-times text-danger"/>
              </button>
            </template>
          </block-regions-list>
          <block-visibility-settings
            v-model="blockVisibilityItems"
            :block="form"
            @change="handleVisibilityChanged"
            @loaded="loading.visibilityItems = false"
          />
        </template>
      </fieldset>
      <footer>
        <submit
          :processing="processing"
          :disabled="!isLoaded"
          class="btn btn-primary"
          icon="fa fa-save"
          @click="submit"
        >
          Сохранить
        </submit>
      </footer>
      <block-regions-modal
        v-if="showBlockRegionsModal"
        v-model="form.blockRegions"
        @close="showBlockRegionsModal = false"
      />
    </div>
  </div>
</template>

<script>
  import Breadcrumbs from './block_types/Breadcrumbs'
  import Delivery from './block_types/Delivery'
  import DviewFilterGroup from './block_types/dview_filter_group/DviewFilterGroup'
  import Dview from './block_types/Dview'
  import DviewProductDiameters from './block_types/DviewProductDiameters'
  import DviewProductModifications from './block_types/DviewProductModifications'
  import DviewProductAbout from './block_types/DviewProductAbout'
  import DviewProductFiles from './block_types/DviewProductFiles'
  import DviewProductAnalogs from './block_types/DviewProductAnalogs'
  import DviewAssemblyKit from './block_types/DviewAssemblyKit'
  import DviewHomeBanners from './block_types/DviewHomeBanners'
  import DviewHomeTab from './block_types/DviewHomeTab'
  import DviewQuickMenu from './block_types/DviewQuickMenu'
  import ProductGallery from './block_types/ProductGallery'
  import MetizCalc from './block_types/MetizCalc'
  import Megamenu from './block_types/Megamenu'
  import Menu from './block_types/Menu'
  import QuickMenu from './block_types/QuickMenu'
  import FooterMenu from './block_types/FooterMenu'
  import FooterText from './block_types/FooterText'
  import Html from './block_types/Html'
  import BlockRegionsModal from './BlockRegionsModal'
  import BlockRegionsList from './BlockRegionsList'
  import BlockVisibilitySettings from './visibility_settings/BlockVisibilitySettings'
  import axios from 'axios'

  export default {
    name: 'BlockEditForm',
    components: {
      Breadcrumbs,
      Delivery,
      DviewFilterGroup,
      Dview,
      DviewProductDiameters,
      DviewProductModifications,
      DviewProductAbout,
      DviewProductFiles,
      DviewProductAnalogs,
      DviewAssemblyKit,
      DviewHomeBanners,
      DviewHomeTab,
      DviewQuickMenu,
      ProductGallery,
      MetizCalc,
      Megamenu,
      Menu,
      QuickMenu,
      FooterMenu,
      FooterText,
      Html,
      BlockRegionsModal,
      BlockRegionsList,
      BlockVisibilitySettings
    },
    props: ['blockId'],
    data () {
      return {
        form: {
          name: null,
          description: null,
          title: null,
          type: 'Html',
          enabled: false,
          auth_only: false,
          blockRegions: [],
          visibility: 'all',
          mode: 'include'
        },
        blockTypeSettings: {},
        blockVisibilityItems: [],
        options: {
          blockTypes: []
        },
        showBlockRegionsModal: false,
        processing: false,
        loading: {
          this: true,
          typeSettings: true,
          visibilityItems: true
        }
      }
    },
    computed: {
      isCreate () {
        return !this.blockId
      },
      isLoaded () {
        return this.isCreate || !Object.entries(this.loading).some(([type, flag]) => flag)
      }
    },
    watch: {
      'form.type' () {
        this.loading.typeSettings = true
      },
      'form.visibility': {
        immediate: true,
        handler (visibility) {
          this.loading.visibilityItems = visibility != 'all'
        }
      }
    },
    methods: {
      submit () {
        this.processing = true
        const url = `block/${this.isCreate ? 'create' : `${this.blockId}/edit`}`
        const params = {
          form: this.form,
          typeSettings: this.blockTypeSettings,
          visibilityItems: this.blockVisibilityItems
        }
        axios.post(url, params)
          .then(({data}) => {
            if (this.isCreate) {
              this.$emit('created', data.id)
            }
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      handleVisibilityChanged (form) {
        this.form.visibility = form.visibility
        this.form.mode = form.mode
      },
      removeRegion (name) {
        const idx = this.form.blockRegions.findIndex(item => {
          return item == name
        })
        this.form.blockRegions.splice(idx, 1)
      }
    },
    async created () {
      const {data} = await axios.get('block/getOptions')
      this.options.blockTypes = data.blockTypes
      if (!this.isCreate) {
        const {data} = await axios.get(`block/${this.blockId}/get`)
        this.form = data.block
      }
      this.loading.this = false
    }
  }
</script>