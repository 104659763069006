<template>
  <div class="row">
    <div class="col-sm-6">
      <div class="btn-group">
        <template v-if="displayId">
          <button
            class="btn btn-default display-list"
            type="button"
            @click="backToList"
          >
            <i class="fa fa-chevron-left"></i> Вернуться к списку
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="showDisplayCloneForm = true"
          >
            <i class="fa fa-copy"></i> Клонировать
          </button>
          <button
            class="btn btn-default"
            type="button"
            @click="showDisplayDeleteFrom = true"
          >
            <i class="fa fa-trash-o"></i> Удалить
          </button>
        </template>
        <button
          class="btn btn-default"
          @click="showDisplayEditForm = true"
        >
          <i class="fa fa-plus"></i> Создать
        </button>
      </div>
    </div>
    <div
      v-if="displayId"
      class="col-sm-6"
    >
      <div v-if="loading">Загрузка...</div>
      <label
        v-else
        class="select"
      >
        <xselect2
          v-model="display"
          label="Выбор отображения"
          tk="label"
          :src="displays"
          width="100%"
          @change="changeDisplay($event.id)"
        />
      </label>
    </div>
    <display-edit-form
      v-if="showDisplayEditForm"
      :is-create="true"
      :dview-id="dviewId"
      @close="showDisplayEditForm = false"
    />
    <display-delete-form
      v-if="showDisplayDeleteFrom"
      @close="showDisplayDeleteFrom = false"
    />
    <display-clone-form
      v-if="showDisplayCloneForm"
      @close="showDisplayCloneForm = false"
    />
  </div>
</template>

<script>
  import DisplayEditForm from './DisplayEditForm'
  import DisplayDeleteForm from './DisplayDeleteForm'
  import DisplayCloneForm from './DisplayCloneForm'
  import axios from 'axios'

  export default {
    name: 'DisplayManageBtns',
    components: {
      DisplayEditForm,
      DisplayDeleteForm,
      DisplayCloneForm
    },
    props: ['dviewId', 'displayId'],
    data () {
      return {
        display: {
          id: null,
          label: null
        },
        displays: [],
        showDisplayEditForm: false,
        showDisplayDeleteFrom: false,
        showDisplayCloneForm: false,
        loading: true
      }
    },
    watch: {
      displayId: {
        immediate: true,
        handler () {
          axios.get(`dview/${this.dviewId}/getDisplays`)
            .then(({data}) => {
              this.displays = data.map(display => {
                if (this.displayId == display.id) {
                  this.display.id = display.id
                  this.display.label = display.label
                }
                return {
                  id: display.id,
                  text: display.label
                }
              })
              this.loading = false
            })
        }
      }
    },
    methods: {
      backToList () {
        window.location.hash = `dview?tab=Display&id=${this.dviewId}`
      },
      changeDisplay (id) {
        window.location.hash = `dview?tab=Display&id=${this.dviewId}&displayId=${id}`
      }
    }
  }
</script>

<style scoped>
  .btn {
    padding: 6px 12px;
  }
</style>