<template>
  <div style="position: relative">
    <dview-edit-form
      v-if="activeTab == 'DviewCreate'"
      @created="setEditTab"
    />
    <div v-else-if="activeTab">
      <div class="tabs-body">
        <ul class="nav nav-tabs">
          <li
            v-for="tab in tabs"
            :key="tab.name"
            :class="{active: tab.name == activeTab}"
          >
            <a @click.prevent="setActiveTab(tab)" href="#">{{tab.title}}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active">
            <component
              :is="activeTab"
              :dview-id="dviewId"
              :display-id="displayId"
              @deleted="handleDeleted"
              @openDisplay="setDisplayFormTab"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <button
        type="button"
        class="btn btn-link no-padding font-xs dview-create-btn"
        @click="setCreateTab"
      >
        Создать представление
      </button>
      <datatable
        :columns="columns"
        :options="{tableClass: 'table-bordered'}"
        src="dview/list"
        @setDisplayTab="setDisplayTab"
      />
    </div>
  </div>
</template>

<script>
  import DviewEditForm from './DviewEditForm'
  import DviewDeleteForm from './DviewDeleteForm'
  import Display from './display/Display'
  import TdLabel from './td/Label'
  import TdDescription from './td/Description'

  export default {
    name: 'Dviews',
    components: {
      DviewEditForm,
      DviewDeleteForm,
      Display
    },
    data () {
      return {
        activeTab: _hash_struct.tab,
        dviewId: _hash_struct.query.id,
        displayId: _hash_struct.query.displayId,
        tabs: [
          {title: 'Настроить представление', name: 'DviewEditForm'},
          {title: 'Отображения', name: 'Display'},
          {title: 'Клонировать представление', name: 'DviewCopy'},
          {title: 'Удалить представление', name: 'DviewDeleteForm'}
        ]
      }
    },
    computed: {
      columns () {
        return [
          {
            title: 'Наименование',
            field: 'label',
            filter: {type: 'text', width: '100%'},
            component: TdLabel,
            tdClass: 'text-nowrap'
          },
          {
            title: 'Описание',
            field: 'description',
            filter: {type: 'text', width: '100%'},
            component: TdDescription,
            tdClass: 'text-nowrap'
          }
        ]
      }
    },
    methods: {
      setCreateTab () {
        this.setActiveTab({name: 'DviewCreate'})
      },
      setEditTab (id) {
        this.dviewId = id
        this.setActiveTab({name: 'DviewEditForm'})
      },
      setDisplayTab (id) {
        this.dviewId = id
        this.setActiveTab({name: 'Display'})
      },
      setDisplayFormTab (displayId) {
        this.displayId = displayId
        this.setActiveTab({name: 'Display'})
      },
      setActiveTab (tab) {
        this.activeTab = tab.name
        this.setTab(tab.name)
      },
      setTab (tab) {
        CHECK_URL_ON_HASH_CHANGE = false;
        const query = []
        if (tab) {
          query.push(`tab=${tab}`)
        }
        if (this.dviewId) {
          query.push(`id=${this.dviewId}`)
        }
        if (this.displayId) {
          query.push(`displayId=${this.displayId}`)
        }
        window.location.hash = 'dview' + (query.length ? `?${query.join('&')}` : '')
      },
      handleDeleted () {
        this.dviewId = null
        this.activeTab = null
        this.setTab()
      }
    }
  }
</script>

<style scoped>
  .dview-create-btn {
    position: absolute;
    top: -22px;
    left: 47px;
  }
</style>