<template>
  <section>
    <section>
      <label class="label">Значение по умолчанию</label>
      <label class="input">
        <input v-model="form.defaultValue" @input="emit" placeholder="Значение по умолчанию" type="text"/>
      </label>
      <div class="note">Применяется для полей с отсутствующими значениями.</div>
    </section>
    <section>
      <label class="checkbox">
        <input v-model="form.availableValuesFlag" @change="emit" type="checkbox">
        <i></i> Задать список доступных значений
      </label>
    </section>
    <template v-if="form.availableValuesFlag">
      <div class="row">
        <section class="col col-5">
          <label class="label">Разделитель для вывода значений</label>
          <label class="select">
            <select v-model="form.listDelimiter" @change="emit">
              <option
                v-for="item in options.listDelimiters"
                :key="item.value"
                :value="item.value"
              >
                {{item.text}}
              </option>
            </select>
            <i></i>
          </label>
        </section>
        <section class="col col-5"></section>
      </div>
      <div class="list-values">
        <div class="row list-values-labels">
          <section class="col col-5">
            <label class="label">Значение</label>
          </section>
          <section class="col col-5">
            <label class="label">Метка</label>
          </section>
        </div>
        <div
          v-for="(item, idx) in form.availableValues"
          :key="item.value"
          class="row"
        >
          <section class="col col-5">
            <label class="input">
              <i class="icon-prepend">#{{idx + 1}}</i>
              <input v-model="item.value" @input="emit" type="text" placeholder="Значение">
            </label>
          </section>
          <section class="col col-5">
            <label class="input">
              <i
                class="icon-append fa fa-times"
                @click="removeValue(idx)"
              ></i>
              <input v-model="item.label" @input="emit" type="text" placeholder="Метка">
              <i
                v-if="form.availableValues.length == idx + 1"
                class="fa fa-lg fa-fw fa-plus-circle txt-color-green"
                @click="pushValue"
              ></i>
            </label>
          </section>
        </div>
      </div>
    </template>
    <div v-if="showWarning" class="row list-values-analize">
      <section class="col col-10">
        <div class="alert alert-warning">
          <p><i class="fa-fw fa fa-warning"></i><strong>Список доступных значений используется!</strong></p>
          <p>Удаление списка приведёт к нарушению консистенции данных.</p>
          <ul>
            <li>Материалы: <span>{{usedInfo.contents}}</span></li>
            <li>Раскрытые фильтры: <span>{{usedInfo.expandFilters}}</span></li>
          </ul>
        </div>
      </section>
    </div>
    <label class="label">Тип отображения поля на форме</label>
    <section>
      <div class="inline-group">
        <label
          v-for="(label, type) in options.inputTypes"
          :key="type"
          class="radio"
        >
          <input
            v-model="form.inputType"
            :value="type"
            name="fieldInputType"
            type="radio"
            @change="emit"
          />
          <i></i> {{ label }}
        </label>
      </div>
    </section>
  </section>
</template>

<script>
  export default {
    name: 'FieldSettings',
    model: {
      prop: 'field',
      event: 'input'
    },
    props: ['field', 'options', 'usedInfo'],
    data () {
      return {
        form: {}
      }
    },
    computed: {
      hasAvailableValues () {
        return this.field.availableValues.length > 0
      },
      showWarning () {
        return (this.usedInfo.contents || this.usedInfo.expandFilters) &&
          !this.form.availableValuesFlag &&
          this.field.availableValues
            .filter(item => item.value != null)
            .length > 0
      }
    },
    methods: {
      removeValue (idx) {
        this.form.availableValues.splice(idx, 1)
        this.emit()
        if (!this.form.availableValues.length) {
          this.form.availableValues.push({value: null, label: null})
        }
      },
      pushValue () {
        this.form.availableValues.push({value: null, label: null})
        this.emit()
      },
      emit () {
        this.$emit('input', JSON.parse(JSON.stringify(this.form)))
      }
    },
    created () {
      const form = JSON.parse(JSON.stringify(this.field))
      form.availableValuesFlag = this.hasAvailableValues
      this.form = form
      this.emit()
      if (!this.hasAvailableValues) {
        this.form.availableValues = [{value: null, label: null}]
      }
    }
  }
</script>

<style scoped>
  .list-values .fa-plus-circle {
    position: absolute;
    top: 0;
    right: -33px;
    cursor: pointer;
    padding: 10px 5px;
  }
  .list-values .fa-times {
    cursor: pointer;
  }
  .list-values .fa-times:hover {
    color: red;
  }
  .list-values .icon-prepend {
    font-size: 90%;
  }
  .list-values section:first-child {
    padding-right: 3px;
  }
  .list-values section:last-child {
    padding-left: 3px;
  }
  .list-values-labels section {
    margin-bottom: 0
  }
  .list-values-analize ul {
    margin-left: 20px
  }
</style>