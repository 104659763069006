import Cookie from 'js-cookie'

// admin cookies
const contentExportContentTypeLabel = 'admin_content_export_contenttype_label'

export default {
  namespaced: true,
  state: {
    contentExportContentTypeLabel: Cookie.get(contentExportContentTypeLabel)
  },
  mutations: {
    contentExportSetContentTypeLabel (state, label) {
      state.contentExportContentTypeLabel = label
      Cookie.set(contentExportContentTypeLabel, state.contentExportContentTypeLabel)
    }
  }
}