<template>
  <div class="btn-group">
    <button
      :class="btnClass"
      @click="showItems = !showItems"
      @blur="handleBlur"
    >
      <i v-if="icon" :class="icon"></i>
      {{ title }}
    </button>
    <ul
      v-if="showItems"
      class="dropdown-menu"
      :class="align == 'right' ? 'dropdown-menu-right' : 'dropdown-menu-left'"
      @mouseenter="closeOnBlur = false"
      @mouseleave="closeOnBlur = true"
    >
      <li v-for="(title, eventName) in items">
        <a @click.prevent="emit(eventName)" href="#">{{title}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'ButtonMenu',
    props: ['title', 'icon', 'btnClass', 'items', 'align'],
    data () {
      return {
        showItems: false,
        closeOnBlur: true
      }
    },
    methods: {
      handleBlur () {
        if (this.closeOnBlur) {
          this.showItems = false
        }
      },
      emit (eventName) {
        this.showItems = false
        this.closeOnBlur = true
        this.$emit(eventName)
      }
    }
  }
</script>

<style scoped>
  .dropdown-menu {
    display: block;
  }
  .dropdown-menu.dropdown-menu-right {
    left: auto;
    right: 0;
    text-align: right;
  }
  .dropdown-menu.dropdown-menu-left {
    right: auto;
    left: 0;
    text-align: left;
  }
</style>