<template>
  <div>
    <div class="smart-form">
      <label class="checkbox">
        <input
          v-model="isExpand"
          :true-value="1"
          :false-value="0"
          type="checkbox"
          @change="$emit('input', isExpand)"
        />
        <i></i> Раскрыть
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Expand',
    props: ['value', 'row'],
    data () {
      return {
        isExpand: this.value
      }
    }
  }
</script>