<template>
  <div>
    Критерии фильтрации
    <button-menu
      title="Управление"
      btn-class="btn btn-link font-sm no-padding"
      style="margin-bottom: 5px;"
      :items="buttonMenu"
      @addFilter="showFilterAddForm = true"
      @reorderFilters="showReorderFiltersForm = true"
      @reorderExpandFilters="showReorderExpandFiltersForm = true"
    />
    <div class="criteria-box">
      <ol>
        <li v-for="(criteriaItem, idx) in criteriaItems" :key="criteriaItem.id">
          <button
            class="btn btn-link font-sm no-padding"
            type="button"
            @click="editIdx = idx"
          >
            {{criteriaItem.field.label}} ({{criteriaItem.field.name}})
          </button>
          <div class="font-xs">
            <div v-if="criteriaItem.isExpand">
              <span class="text-warning">
                : Фильтр раскрыт для пользователя
                <span v-if="criteriaItem.isRefining" class="text-danger">
                  : уточняющий
                </span>
              </span>
              <span
                v-if="criteriaItem.isAutoValue"
                class="text-success"
              >
                : автоподстановка значения
              </span>
            </div>
            <div v-else-if="criteriaItem.operator" class="text-success">
              <span>
                : {{getCriteriaOperatorLabel(criteriaItem.operator)}}
              </span>
              <span v-if="!isNotNullOperator(criteriaItem.operator)">
                [ {{getCriteriaValues(criteriaItem).join(', ')}} ]
              </span>
            </div>
            <div v-else>: [ <strong class="text-danger">Не задано</strong> ]</div>
          </div>
        </li>
      </ol>
    </div>
    <display-criteria-create-form
      v-if="showFilterAddForm"
      @close="showFilterAddForm = false"
    />
    <display-criteria-edit-form
      v-if="isEditable"
      @close="editIdx = null"
    />
    <expand-order-form
      v-if="showReorderExpandFiltersForm"
      @close="showReorderExpandFiltersForm = false"
    />
  </div>
</template>

<script>
  import DisplayCriteriaCreateForm from './DisplayCriteriaCreateForm'
  import DisplayCriteriaEditForm from './form/DisplayCriteriaEditForm'
  import ExpandOrderForm from './form/expand_order/ExpandOrderForm'

  export default {
    name: 'DisplayCriteria',
    components: {
      DisplayCriteriaCreateForm,
      DisplayCriteriaEditForm,
      ExpandOrderForm
    },
    data () {
      return {
        buttonMenu: {
          addFilter: 'Добавить поле',
          //reorderFilters: 'Перестроить фильтры',
          reorderExpandFilters: 'Порядок раскрытых фильтров'
        },
        showFilterAddForm: false,
        showReorderFiltersForm: false,
        showReorderExpandFiltersForm: false,
        editIdx: null
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      criteriaItems () {
        const filterCriteria = this.display.form.filterCriteria
        const criteria = filterCriteria
          .filter(criteria => !criteria.isExpand)
          .sort((a, b) => {
            if (a.order == b.order) return 0
            return a.order < b.order ? -1 : 1;
          })
        const expandedCriteria = filterCriteria
          .filter(criteria => criteria.isExpand)
          .sort((a, b) => {
            if (a.expand_order == b.expand_order) return 0
            return a.expand_order < b.expand_order ? -1 : 1;
          })
        return criteria.concat(expandedCriteria)
      },
      operators () {
        return this.display.options.operators
      },
      isEditable () {
        return this.editIdx != null
      }
    },
    watch: {
      editIdx (idx) {
        let form = null
        if (idx != null) {
          form = JSON.parse(JSON.stringify(this.criteriaItems[idx]))
        }
        this.$store.commit('display/setFilterCriteriaForm', form)
      }
    },
    methods: {
      getCriteriaOperatorLabel (symbol) {
        const operator = this.operators.find(item => item.symbol == symbol)
        return operator.label
      },
      getCriteriaValues (criteriaItem) {
        const collect = this.fixCollect(criteriaItem.collect)
        switch (true) {
          case this.isBetweenOperator(criteriaItem.operator):
            return [collect.valueMin, collect.valueMax]
          case this.isArrayableOperator(criteriaItem.operator):
            return collect.values
          default: return [collect.value]
        }
      },
      isBetweenOperator (operator) {
        return ['between', 'not between'].includes(operator)
      },
      isArrayableOperator (operator) {
        return ['in', 'and', 'not in'].includes(operator)
      },
      isNotNullOperator (operator) {
        return operator == 'is not null'
      },
      fixCollect (collect) {
        collect = collect || {}
        if (!Array.isArray(collect.values)) {
          collect.values = []
        }
        if (typeof collect.value == 'undefined') {
          collect.value = null
        }
        if (typeof collect.valueMin == 'undefined') {
          collect.valueMin = null
        }
        if (typeof collect.valueMax == 'undefined') {
          collect.valueMax = null
        }
        return collect
      }
    }
  }
</script>

<style scoped>
  .criteria-box ol{
    padding-left: 25px
  }
  .criteria-box ol button {
    margin-top: -3px
  }
  .criteria-box li {
    margin-bottom: 4px;
  }
</style>