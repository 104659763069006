<template>
  <modal ref="test" name="form" @closed="$emit('close')" height="auto">
    <div class="no-margin-bottom">
      <div class="well">
        <h4>
          Добавить поле для контекстного фильтра
        </h4>
        <hr/>
        <div>
          <label class="label">ССК поле</label>
          <xselect2
            v-model="field"
            label="Выбор ССК поля"
            tk="label"
            :src="fields"
            width="100%"
          />
        </div>
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            :disabled="!field.id"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DisplayContextCreateForm',
    data () {
      return {
        field: {
          id: null,
          label: null
        },
        processing: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      filterContexts () {
        return this.display.form.filterContexts
      },
      fields () {
        return this.display.fields
          .filter(field => {
            return !this.filterContexts
              .find(context => context.field_id == field.id)
          })
          .map(field => {
            return {
              id: field.id,
              text: `${field.label} (${field.name})`
            }
          })
      }
    },
    methods: {
      submit () {
        this.processing = true
        const params = {fieldId: this.field.id}
        axios.post(`dview/display/${this.display.form.id}/filter/context/create`, params)
          .then(({data}) => {
            const display = JSON.parse(JSON.stringify(this.display))
            display.form.filterContexts.unshift(data)
            this.$store.commit('display/updateFormFilterContexts', display.form.filterContexts)
            this.close();
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>