<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <div v-else>
      <button
        class="btn btn-link no-padding font-sm"
        @click="editDomain"
      >
        Добавить домен
      </button>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="width-1">Домены</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(domain, idx) in domains">
            <td class="text-nowrap">
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="editDomain(idx)"
              >
                {{domain.city}}
              </button>
            </td>
            <td>
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="removeDomain(idx)"
              >
                <i class="fa fa-times"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <domain-edit-modal
        v-if="showDomainEditModal"
        v-model="domains[editDomainIdx]"
        @close="showDomainEditModal = false"
      />
    </div>
  </div>
</template>

<script>
  import DomainEditModal from './DomainEditModal'
  import axios from 'axios'

  export default {
    name: 'BlockVisibilityByDomain',
    components: { DomainEditModal },
    model: {
      prop: 'items',
      event: 'input'
    },
    props: ['items', 'blockId'],
    data () {
      return {
        domains: [],
        editDomainIdx: null,
        showDomainEditModal: false,
        loading: true
      }
    },
    watch: {
      domains () {
        this.emit()
      }
    },
    methods: {
      editDomain (idx) {
        this.editDomainIdx = Number.isInteger(idx) ? idx : this.domains.length
        this.showDomainEditModal = true
      },
      removeDomain (idx) {
        this.domains.splice(idx, 1)
      },
      emit () {
        const domainsIds = JSON.parse(JSON.stringify(this.domains.map(domain => domain.id)))
        this.$emit('input', domainsIds)
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getVisibilityDomains`)
      this.domains = data
      this.loading = false
      this.$emit('loaded')
    }
  }
</script>