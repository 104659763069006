<template>
  <div>
    <label class="label">
      Значение для диапазона (Min, Max)
    </label>
    <div class="row" style="margin-bottom: 10px;">
      <div class="col col-2">
        <label class="input">
          <input
            v-model="rangeMin"
            placeholder="Min"
            type="text"
          />
        </label>
      </div>
      <div class="col col-2">
        <label class="input">
          <input
            v-model="rangeMax"
            placeholder="Max"
            type="text"
          />
        </label>
      </div>
    </div>
    <label class="label">
      Значение по умолчанию для диапазона (Min, Max)
    </label>
    <div class="row">
      <div class="col col-2">
        <label class="input">
          <input
            v-model="rangeMinDefault"
            placeholder="Min"
            type="text"
          />
        </label>
      </div>
      <div class="col col-2">
        <label class="input">
          <input
            v-model="rangeMaxDefault"
            placeholder="Max"
            type="text"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ExpandRangeItem',
    computed: {
      form () {
        return JSON.parse(JSON.stringify(this.$store.state.display.filterCriteriaForm))
      },
      rangeMin: {
        get () {
          return this.form.range_min
        },
        set (value) {
          const {form} = this
          form.range_min = value
          this.updateForm(form)
        }
      },
      rangeMax: {
        get () {
          return this.form.range_max
        },
        set (value) {
          const {form} = this
          form.range_max = value
          this.updateForm(form)
        }
      },
      rangeMinDefault: {
        get () {
          return this.form.range_min_default
        },
        set (value) {
          const {form} = this
          form.range_min_default = value
          this.updateForm(form)
        }
      },
      rangeMaxDefault: {
        get () {
          return this.form.range_max_default
        },
        set (value) {
          const {form} = this
          form.range_max_default = value
          this.updateForm(form)
        }
      }
    },
    methods: {
      updateForm (form) {
        this.$store.commit('display/setFilterCriteriaForm', form)
      }
    }
  }
</script>