<template>
  <modal name="form" @closed="$emit('close')" @opened="openedHandler" height="auto" width="500">
    <div class="well no-margin-bottom">
      <h4><strong>Новый каталог</strong></h4>
      <hr/>
      <div class="font-md current-name">Имя нового каталога.</div>
      <div :class="{'has-error': errors.path}">
        <input v-model="newName" @keyup.enter="submit" type="text" class="form-control input-lg" placeholder="Имя нового каталога"/>
        <div
          v-for="error in errors.path"
          :key="error"
          class="help-block"
        >
          {{error}}
        </div>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="open" type="checkbox"/> Перейти в новый каталог
        </label>
      </div>
      <hr/>
      <button @click="submit" type="button" class="btn btn-primary pull-right" :disabled="state || !newName">
        <i v-if="state" class="fa fa-circle-o-notch fa-spin"/> Создать каталог
      </button>
      <button @click="close" type="button" class="btn btn-link pull-right">Отмена</button>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    props: ['path'],
    data () {
      return {
        newName: '',
        errors: {},
        state: false,
        open: true
      }
    },
    methods: {
      submit () {
        let {path, newName, open} = this
        let newPath = `${path}/${newName}`
        this.state = true
        axios.post('gallery/makedir', {path: newPath})
          .then(() => {
            this.errors = []
            smallBoxSuccess('Каталог создан.')
            this.$emit('success', {open, newPath})
            this.close()
          })
          .catch(({response}) => {
            this.errors = response.data
            this.state = false
          })
      },
      close () {
        this.$modal.hide('form')
      },
      openedHandler () {
        this.$el.querySelector('input[type=text]').focus()
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>

<style scoped>
  .current-name {
    margin-bottom: 10px;
  }
  .current-name span {
    background: #eee;
  }
</style>