<template>
  <modal name="form" @closed="$emit('close')" height="auto" :scrollable="true">
    <div class="well" style="margin-bottom: 0">
      <h4>
        {{row.label}}
        <p class="modal-sub-title">{{row.name}}</p>
      </h4>
      <hr/>
      <section class="smart-form">
        <label class="label">Частота обновления материалов по умолчанию</label>
        <label class="select">
          <select v-model="form.changefreq">
            <option
              v-for="value in changefreqOptions"
              :value="value"
              :key="value"
            >
              {{value}}
            </option>
          </select>
          <i></i>
        </label>
      </section>
      <section class="smart-form">
        <label class="label">Приоритетность URL по умолчанию</label>
        <label class="select">
          <select v-model="form.priority">
            <option
              v-for="value in priorityOptions"
              :value="value"
              :key="value"
            >
              {{value}}
            </option>
          </select>
          <i></i>
        </label>
      </section>
      <hr/>
      <div class="pull-right">
        <submit
          :processing="processing"
          class="btn btn-link"
          @click="submit"
        >
          Применить
        </submit>
        <button
          type="button"
          class="btn btn-link"
          @click="close"
        >
          Закрыть
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'EditFormModal',
    props: ['row', 'changefreqOptions', 'priorityOptions'],
    data () {
      return {
        form: {
          changefreq: this.row.changefreq,
          priority: this.row.priority
        },
        processing: false
      }
    },
    methods: {
      submit () {
        const {form, row} = this
        this.processing = true
        axios.post(`siteconf/sitemap/contentTypes/${row.id}/save`, form)
          .then(() => {
            row.changefreq = form.changefreq
            row.priority = form.priority
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>