<template>
  <div>
    <div style="margin-bottom: 15px;">
      <datatable
        :columns="columns"
        :sort="false"
        :src="items"
        @edit="showEditForm"
        @removeRow="handleRemoveRow"
      />
    </div>
    <button
      class="btn btn-link no-padding font-sm"
      type="button"
      style="margin-left: 10px;"
      @click="showCreateForm"
    >
      Создать фильтр
    </button>
    <filter-form
      v-if="showForm"
      v-model="items[editIdx]"
      :display-link-id="displayLinkId"
      @close="showForm = false"
    />
  </div>
</template>

<script>
  import FilterForm from './FilterForm'
  import TdField from './td/Field'

  export default {
    name: 'Filters',
    components: { FilterForm },
    props: ['value'],
    data () {
      const items = JSON.parse(JSON.stringify(this.value.filters))
      return {
        items,
        editIdx: null,
        showForm: false
      }
    },
    computed: {
      columns () {
        return [
          {
            title: 'Поле',
            field: 'field_id',
            component: TdField
          },
          {
            title: 'Оператор',
            field: 'operator'
          },
          {
            title: 'Значение из материала',
            field: 'value_by_cid',
            handler: value => value ? 'Да' : 'Нет'
          },
          {
            title: 'Значение по умолчанию',
            field: 'value',
            handler: value => value || '<span class="text-muted">не задано</span>'
          },
          {
            title: '',
            removeRowButton: true
          }
        ]
      },
      displayLinkId () {
        return this.value.id
      }
    },
    watch: {
      items (items) {
        const form = JSON.parse(JSON.stringify(this.value))
        form.filters = items
        this.$emit('input', form)
      }
    },
    methods: {
      showCreateForm () {
        this.editIdx = this.items.length
        this.showForm = true
      },
      showEditForm (id) {
        this.editIdx = this.items.findIndex(item => item.id == id)
        this.showForm = true
      },
      handleRemoveRow (id) {
        const idx = this.items.findIndex(item => item.id == id)
        this.items.splice(idx, 1)
      }
    }
  }
</script>