<template>
  <div>
    Стили отображения
    <button
      v-if="styles.length < 3"
      class="btn btn-link no-padding font-sm"
      type="button"
      @click="showCreateForm"
    >
      Создать
    </button>
    <div v-if="styles.length">
      <div v-for="(style, idx) in styles" :key="style.id">
        <button
          class="btn btn-link no-padding font-sm"
          type="button"
          @click="showEditForm(idx)"
        >
          {{getStyleLabel(style.style)}}
        </button>
      </div>
    </div>
    <div v-else class="text-muted">
      Стили не заданы
    </div>
    <display-style-edit-form
      v-if="showForm"
      :value="form"
      @close="showForm = false"
    />
  </div>
</template>

<script>
  import DisplayStyleEditForm from './DisplayStyleEditForm'

  export default {
    name: 'DisplayStyles',
    components: { DisplayStyleEditForm },
    data () {
      return {
        form: null,
        showForm: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      styles () {
        return this.display.form.displayStyles
      }
    },
    watch: {
      showForm (flag) {
        if (!flag) {
          this.form = null
        }
      }
    },
    methods: {
      getStyleLabel (style) {
        switch (style) {
          case 'flip': return 'Таблица (Flip)'
          case 'grid': return 'Сетка'
          case 'list': return 'Список'
          default: return 'Неизвестный стиль отображения'
        }
      },
      showCreateForm () {
        this.showForm = true
      },
      showEditForm (idx) {
        this.form = JSON.parse(JSON.stringify(this.styles[idx]))
        this.showForm = true
      }
    }
  }
</script>