<template>
  <modal name="form" @closed="$emit('close')" height="auto" width="350">
    <div class="well no-margin-bottom">
      <div>
        <strong>Удалить эти файлы ({{files.length}})?</strong>
      </div>
      <hr/>
      <button @click="submit" type="button" class="btn btn-primary pull-right" :disabled="state">
        <i v-if="state" class="fa fa-circle-o-notch fa-spin"/> Удалить файлы
      </button>
      <button @click="close" type="button" class="btn btn-link pull-right">Отмена</button>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    props: ['files'],
    data () {
      return {
        state: false
      }
    },
    methods: {
      submit () {
        let params = {
          paths: this.files.map(file => file.path)
        }
        this.state = true
        axios.post('gallery/remove', params)
          .then(() => {
            smallBoxSuccess('Файлы удалены.')
            this.$emit('success')
            this.close()
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>
