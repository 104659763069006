<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <div v-else style="position: relative">
      <block-edit-form
        v-if="activeTab == 'BlockCreate'"
        @created="setEditTab"
      />
      <div v-else-if="activeTab">
        <div class="tabs-body">
          <ul class="nav nav-tabs">
            <li v-for="tab in tabs" :class="{active: tab.name == activeTab}">
              <a @click.prevent="setActiveTab(tab)" href="#">{{tab.title}}</a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active">
              <component
                :is="activeTab"
                :block-id="blockId"
                @deleted="handleDeleted"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <button
          type="button"
          class="btn btn-link no-padding font-xs block-create-btn"
          @click="setCreateTab"
        >
          Создать блок
        </button>
        <datatable
          :columns="columns"
          :options="{tableClass: 'table-bordered'}"
          src="block/list"
          @setEditTab="setEditTab"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import BlockEditForm from './BlockEditForm'
  import BlockDeleteForm from './BlockDeleteForm'
  import TdLabel from './td/Label'
  import TdRegions from './td/Regions'
  import axios from 'axios'

  export default {
    name: 'Blocks',
    components: {
      BlockEditForm,
      BlockDeleteForm
    },
    data () {
      return {
        activeTab: _hash_struct.tab,
        blockId: _hash_struct.query.id,
        tabs: [
          {title: 'Настроить блок', name: 'BlockEditForm'},
          {title: 'Удалить блок', name: 'BlockDeleteForm'}
        ],
        usedRegions: [],
        loading: true
      }
    },
    computed: {
      columns () {
        return [
          {
            title: 'Наименование',
            field: 'description',
            filter: {type: 'text', width: '100%'},
            component: TdLabel,
            tdClass: 'text-nowrap'
          },
          {
            title: 'Регион вывода',
            field: 'regions',
            component: TdRegions,
            filter: this.regionsFilter,
            tdClass: 'text-nowrap'
          }
        ]
      },
      templateRegions () {
        return this.$store.state.common.template.regions
      },
      regionsFilter () {
        const options = this.templateRegions
          .filter(region => this.usedRegions.includes(region.name))
          .map(region => {
            return {
              value: region.name,
              text: region.title
            }
          })

        return {
          type: 'select',
          options
        }
      }
    },
    methods: {
      setCreateTab () {
        this.setActiveTab({name: 'BlockCreate'})
      },
      setEditTab (id) {
        this.blockId = id
        this.setActiveTab({name: 'BlockEditForm'})
      },
      setActiveTab (tab) {
        this.activeTab = tab.name
        this.setTab(tab.name)
      },
      setTab (tab) {
        CHECK_URL_ON_HASH_CHANGE = false;
        const query = []
        if (tab) {
          query.push(`tab=${tab}`)
        }
        if (this.blockId) {
          query.push(`id=${this.blockId}`)
        }
        window.location.hash = 'block' + (query.length ? `?${query.join('&')}` : '')
      },
      handleDeleted () {
        this.blockId = null
        this.activeTab = null
        this.setTab()
      }
    },
    async created () {
      await this.$store.dispatch('common/initTemplate')
      const {data} = await axios.get('block/getUsedRegions')
      this.usedRegions = data
      this.loading = false
    }
  }
</script>

<style scoped>
  .block-create-btn {
    position: absolute;
    top: -22px;
    left: 47px;
  }
</style>