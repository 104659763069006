import { render, staticRenderFns } from "./BlockDeleteForm.vue?vue&type=template&id=c27d3c34&scoped=true&"
import script from "./BlockDeleteForm.vue?vue&type=script&lang=js&"
export * from "./BlockDeleteForm.vue?vue&type=script&lang=js&"
import style0 from "./BlockDeleteForm.vue?vue&type=style&index=0&id=c27d3c34&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c27d3c34",
  null
  
)

export default component.exports