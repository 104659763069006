<template>
  <div class="well well-sm">
    <div v-if="loading">Подготовка...</div>
    <div v-else>
      <div class="smart-form">
        <div class="row">
          <div class="col col-6">
            <section>
              <label class="label">Цена - CCK поле</label>
              <xselect2
                label="Выберите CCK поле цены"
                v-model="form.priceField"
                tk="label"
                :src="fields"
                width="100%"
              />
            </section>
            <section>
              <label>
                <input v-model="form.skipNoPrice" type="checkbox"/> Не отображать позиции без цен
              </label>
            </section>
            <section>
              <label class="label">Обозначение количества</label>
              <chips v-model="form.quantitySigns" placeholder="Введите обозначение количества"/>
            </section>
            <section>
              <label class="label">Суффиксы для вставки пробела перед ними</label>
              <chips v-model="form.suffixes" placeholder="Введите суффиксы"/>
            </section>
            <section>
              <label class="label">Количество позиций в выдаче</label>
              <label class="input">
                <input v-model="form.limit" type="text" class="form-control" placeholder="Количество позиций в выдаче"/>
              </label>
            </section>
          </div>
        </div>
      </div>
      <hr/>
      <submit @click="submit" :processing="processing" class="btn btn-primary" icon="fa fa-save">
        Сохранить
      </submit>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data () {
      return {
        fields: [],
        form: {
          quantitySigns: [],
          suffixes: [],
          limit: 1,
          skipNoPrice: 1,
          priceField: {
            id: null,
            label: null
          }
        },
        processing: false,
        loading: true
      }
    },
    methods: {
      submit () {
        this.processing = true
        const params = {
          priceField: this.form.priceField.id,
          skipNoPrice: this.form.skipNoPrice,
          quantitySigns: this.form.quantitySigns,
          suffixes: this.form.suffixes,
          limit: this.form.limit
        }
        axios.post('guglex/settings/saveSettings', params)
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    mounted () {
      axios.get('guglex/settings/getSettings')
        .then(({data}) => {
          const field = data.fields.find(field => field.id == data.priceField)
          this.form.priceField.id = field ? field.id : null
          this.form.priceField.label = field ? field.label : null
          this.form.quantitySigns = data.quantitySigns
          this.form.suffixes = data.suffixes
          this.form.limit = data.limit
          this.form.skipNoPrice = data.skipNoPrice
          this.fields = data.fields
            .map(field => {
              return {
                id: field.id,
                text: field.label
              }
            })
          this.loading = false
        })
    }
  }
</script>
