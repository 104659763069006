<template>
  <div>
    <div v-if="loading" class="well well-sm text-muted">Подготовка...</div>
    <div v-else class="smart-form well no-padding">
      <fieldset>
        <section>
          <header>Основные настройки</header>
        </section>
        <section>
          <label class="label">Имя представления</label>
          <label class="input">
            <input v-model="form.label" placeholder="Имя представления" type="text"/>
          </label>
          <div class="note">Имя представления</div>
        </section>
        <section>
          <label class="label">Машинное имя</label>
          <label class="input">
            <input v-model="form.name" placeholder="Машинное имя" type="text"/>
          </label>
          <div class="note">Машинное имя</div>
        </section>
        <section>
          <label class="label">Описание</label>
          <label class="textarea">
            <label class="textarea">
                <textarea
                  v-model="form.description"
                  rows="5"
                  placeholder="Описание"
                ></textarea>
            </label>
          </label>
          <div class="note">Описание</div>
        </section>
      </fieldset>
      <footer>
        <submit
          :processing="processing"
          class="btn btn-primary"
          icon="fa fa-save"
          @click="submit"
        >
          Сохранить
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DviewEditForm',
    props: ['dviewId'],
    data () {
      return {
        form: {
          name: null,
          label: null,
          description: null
        },
        processing: false,
        loading: true
      }
    },
    computed: {
      isCreate () {
        return !this.dviewId
      }
    },
    methods: {
      submit () {
        this.processing = true
        const url = `dview/${this.isCreate ? 'create' : `${this.dviewId}/edit`}`
        const params = {
          form: this.form
        }
        axios.post(url, params)
          .then(({data}) => {
            if (this.isCreate) {
              this.$emit('created', data.id)
            }
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async created () {
      if (!this.isCreate) {
        const {data} = await axios.get(`dview/${this.dviewId}/get`)
        this.form = data.dview
      }
      this.loading = false
    }
  }
</script>