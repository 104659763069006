<template>
  <modal name="form" @closed="$emit('close')" :scrollable="true" height="auto" width="1200">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          {{removing ? 'Удалить' : (isCreate ? 'Создать' : 'Редактировать')}} ссылку отображения
        </h4>
        <hr/>
        <section v-if="removing">
          <div>
            Вы хотите удалить ссылку отображения "{{form.text}}"?
          </div>
          <button
            class="btn btn-link font-sm no-padding"
            type="button"
            @click="removing = false"
          >
            Отмена
          </button>
        </section>
        <section v-else>
          <div class="tabs-body">
            <ul class="nav nav-tabs">
              <li
                v-for="tab in tabs"
                :key="tab.name"
                :class="{active: tab.name == activeTab}"
              >
                <a @click.prevent="activeTab = tab.name" href="#">{{tab.title}}</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active">
                <div :style="activeTab == 'EditForm' ? 'padding: 10px;' : ''">
                  <component
                    :is="activeTab"
                    v-model="form"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr/>
        <div class="pull-right">
          <submit
            v-if="!isCreate"
            :processing="processing"
            class="btn btn-link"
            @click="remove"
          >
            <span :class="{'text-danger': removing}">
              Удалить
            </span>
          </submit>
          <submit
            v-if="!removing"
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import EditForm from './form/Form'
  import EditFilter from './form/filters/Filters'
  import _uniqueId from 'lodash/uniqueId'
  import axios from 'axios'

  export default {
    name: 'DisplayLinkEditForm',
    props: ['value'],
    components: {
      EditForm,
      EditFilter
    },
    data () {
      const display = this.$store.state.display
      let form = this.value ? JSON.parse(JSON.stringify(this.value)) : null
      const isCreate = !form
      if (isCreate) {
        form = {
          id: -_uniqueId(),
          class: null,
          display_id: display.form.id,
          display_title: null,
          exclude_cid: 0,
          filters: [],
          ignore_expands: 0,
          more: 0,
          order: display.form.displayLinks.length + 1,
          rel: null,
          target: null,
          text: null,
          via_expands: 0,
          word_genitive: null,
          word_plural: null,
          word_singular: null
        }
      }
      return {
        form,
        isCreate,
        tabs: [
          {
            name: 'EditForm',
            title: 'Основные настройки'
          },
          {
            name: 'EditFilter',
            title: 'Фильтры'
          }
        ],
        activeTab: 'EditForm',
        removing: false,
        processing: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      }
    },
    methods: {
      submit () {
        this.processing = true
        const url = `dview/display/link/${this.isCreate ? 'create' : `${this.form.id}/edit`}`
        axios.post(url, this.form)
          .then(({data}) => {
            const displayLinks = JSON.parse(JSON.stringify(this.display.form.displayLinks))
            if (this.isCreate) {
              displayLinks.push(data)
            } else {
              const idx = displayLinks.findIndex(item => item.id == this.form.id)
              displayLinks.splice(idx, 1, data)
            }
            this.updateDisplayLinks(displayLinks)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      remove () {
        if (!this.removing) {
          this.removing = true
          return
        }
        /*const postRemoving = () => {
          const displayLinks = JSON.parse(JSON.stringify(this.display.form.displayLinks))
          const idx = displayLinks.findIndex(item => item.id == this.form.id)
          displayLinks.splice(idx, 1)
          this.updateDisplayLinks(displayLinks)
          this.close()
          this.$toasted.info('Ссылка отображения удалена.', {
            duration: 1500,
            position: 'bottom-right'
          })
        }
        if (this.isCreate) {
          postRemoving()
        }*/
        this.processing = true
        axios.post(`dview/display/link/${this.form.id}/delete`)
          .then(() => {
            const displayLinks = JSON.parse(JSON.stringify(this.display.form.displayLinks))
            const idx = displayLinks.findIndex(item => item.id == this.form.id)
            displayLinks.splice(idx, 1)
            this.updateDisplayLinks(displayLinks)
            this.close()
            smallBoxSuccess('Ссылка отображения удалена.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      updateDisplayLinks (displayLinks) {
        this.$store.commit('display/updateFormDisplayLinks', displayLinks)
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>