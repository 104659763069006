<template>
  <modal
    name="download-file-header-form"
    @closed="$emit('close')"
    height="auto"
    width="600"
    :scrollable="true"
  >
    <div class="well no-margin-bottom" style="height: 100%">
      <h4 style="margin-bottom: 20px;">Сгенерировать шапку файла импорта</h4>
      <section>
        <section>
          <header>Тип материала</header>
        </section>
        <label class="select">
          <xselect2
            v-model="contentType"
            label="Выбор типа материала"
            tk="text"
            :src="contentTypes"
            width="100%"
            @change="form.content_type_id = $event.id"
          />
        </label>
      </section>
      <section>
        <section>
          <header>Разделитель значений колонок</header>
        </section>
        <div class="inline-group">
          <label class="radio">
            <input
              v-model="form.delimiter"
              value=","
              type="radio"
            />
            <i></i> Запятая
          </label>
          <label class="radio">
            <input
              v-model="form.delimiter"
              value=";"
              type="radio"
            />
            <i></i> Точка с запятой
          </label>
        </div>
      </section>
      <div class="pull-right">
        <form
          :action="`/content/import/getFileHeader?content_type_id=${form.content_type_id}&delimiter=${form.delimiter}`"
          method="post"
          style="display: inline-block"
        >
          <button
            :disabled="!form.content_type_id"
            type="submit"
            class="btn btn-link"
            @click="close"
          >
            Скачать шапку
          </button>
          <div v-html="$root.config.csrf_field"></div>
        </form>
        <button
          @click="close"
          type="button"
          class="btn btn-link"
        >
          Закрыть
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'ContentImportDownloadFileHeaderModal',
    props: ['contentTypes'],
    data () {
      return {
        form: {
          content_type_id: null,
          delimiter: ','
        },
        contentType: {
          id: null,
          text: null
        }
      }
    },
    methods: {
      close () {
        this.$modal.hide('download-file-header-form')
      }
    },
    mounted () {
      this.$modal.show('download-file-header-form')
    }
  }
</script>
