<template>
  <div>
    <button
      type="button"
      class="btn btn-link no-padding font-sm"
      @click="$parent.$emit('edit', row)"
    >
      {{ row.label }}
    </button>
    <div class="text-muted font-xs">
      {{ row.name }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Label',
    props: ['row', 'value']
  }
</script>