import axios from 'axios'

export default {
  namespaced: true,
  state: {
    options: null,
    macros: null,
    fields: null,
    contentTypes: null,
    interrelations: null,
    form: {},
    filterCriteriaForm: {},
    filterContextForm: {}
  },
  actions: {
    async initOptions ({commit}) {
      const {data} = await axios.get('dview/display/getOptions')
      commit('setOptions', data)
    },
    async initDisplay ({commit}, displayId) {
      const {data} = await axios.get(`dview/display/${displayId}/get`)
      commit('setForm', data)
    }
  },
  mutations: {
    setOptions (state, data) {
      const {options, macros, fields, contentTypes, interrelations} = data
      state.options = options
      state.macros = macros
      state.fields = fields
      state.contentTypes = contentTypes
      state.interrelations = interrelations
    },
    setForm (state, data) {
      state.form = {
        id: data.id,
        name: data.name,
        label: data.label,
        title: data.title,
        url: data.url,
        viewId: data.view_id,
        blocks: data.blocks,
        displayFields: data.fields,
        filterContentTypes: data.filter_content_types,
        filterContexts: data.filter_contexts,
        filterCriteria: data.filter_criteria,
        filterCriteriaOrders: data.filter_criteria_orders,
        interrelationId: data.interrelation_id,
        displayLinks: data.links,
        displayStyles: data.styles,
        displayTabs: {
          tabs_on: data.tabs_on,
          tabs_mode: data.tabs_mode,
          atab_on: data.atab_on,
          atab_last: data.atab_last,
          atab_label: data.atab_label,
          atab_group_label: data.atab_group_label,
          tab_fields: data.tab_fields
        },
        noResultText: data.no_result_text,
        pagination: {
          per_page_is_expand: data.per_page_is_expand,
          per_page_user: data.per_page_user,
          per_page: data.per_page,
          per_page_to_block: data.per_page_to_block,
          number_page: data.number_page,
          number_page_to_block: data.number_page_to_block
        },
        metaTags: {
          pageTitle: data.pageTitle,
          metakeywords: data.metakeywords,
          metadescription: data.metadescription
        }
      }
    },
    setFilterCriteriaForm (state, data) {
      state.filterCriteriaForm = data
    },
    setFilterContextForm (state, data) {
      state.filterContextForm = data
    },
    updateFormDisplay (state, data) {
      state.form.id = data.id
      state.form.name = data.name
      state.form.label = data.label
      state.form.title = data.title
      state.form.url = data.url
      state.form.viewId = data.view_id
    },
    updateFormDisplayFields (state, data) {
      state.form.displayFields = data
    },
    updateFormFilterContentTypes (state, data) {
      state.form.filterContentTypes = data
    },
    updateFormInterrelationId (state, data) {
      state.form.interrelationId = data
    },
    updateFormFilterCriteria (state, data) {
      state.form.filterCriteria = data
    },
    updateFormFilterContexts (state, data) {
      state.form.filterContexts = data
    },
    updateFormDisplayOrders (state, data) {
      state.form.filterCriteriaOrders = data
    },
    updateFormDisplayLinks (state, data) {
      state.form.displayLinks = data
    },
    updateFormDisplayStyles (state, data) {
      state.form.displayStyles = data
    },
    updateFormDisplayTabs (state, data) {
      state.form.displayTabs = data
    },
    updateFormDisplayNoResultText (state, data) {
      state.form.noResultText = data
    },
    updateFormDisplayMetatags (state, data) {
      state.form.metaTags = data
    },
    updateFormDisplayPagination (state, data) {
      state.form.pagination = data
    },
    updateFilterCriteriaExpands (state, {criteriaId, expands, expandLabel}) {
      const filterCriteria = state.form.filterCriteria.find(criteria => criteria.id == criteriaId)
      filterCriteria.criteria_expands = expands
      filterCriteria.expand_label = expandLabel
      filterCriteria.isExpand = 1
      filterCriteria.isRefining = 0
      state.filterCriteriaForm.criteria_expands = expands
      state.filterCriteriaForm.expand_label = expandLabel
      state.filterCriteriaForm.isRefining = 0
    }
  }
}