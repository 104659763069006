<template>
  <div>
    <label class="radio">
      <input
        v-model="direction"
        type="radio"
        :value="0"
        :name="`direction${row.id}`"
        @change="$emit('input', direction)"
      />
      <i></i> По возрастанию
    </label>
    <label class="radio">
      <input
        v-model="direction"
        type="radio"
        :value="1"
        :name="`direction${row.id}`"
        @change="$emit('input', direction)"
      />
      <i></i> По убыванию
    </label>
  </div>
</template>

<script>
  export default {
    name: 'Direction',
    props: ['value', 'row'],
    data () {
      return {
        direction: this.value
      }
    }
  }
</script>