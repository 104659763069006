<template>
  <div class="tabs-body">
    <ul class="nav nav-tabs">
      <li
        v-for="tab in tabs"
        :class="{active: tab.name == activeTab}"
        :key="tab.name"
      >
        <a @click.prevent="setActiveTab(tab)" href="#">{{tab.title}}</a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active">
        <component :is="activeTab"/>
      </div>
    </div>
  </div>
</template>

<script>
  import General from './General'
  import Robots from './Robots'
  import Sitemap from './sitemap/Sitemap'
  import Cache from './Cache'
  import Glob from './Glob'

  export default {
    name: 'SiteConf',
    components: {
      General,
      Robots,
      Sitemap,
      Cache,
      Glob
    },
    data () {
      return {
        activeTab: _hash_struct.tab || 'General',
        tabs: [
          {title: 'Информация о сайте', name: 'General'},
          {title: 'Robots.txt', name: 'Robots'},
          {title: 'Sitemap.xml', name: 'Sitemap'},
          //{title: 'Кеширование', name: 'Cache'},
          {title: 'Глобальные настройки', name: 'Glob'}
        ]
      }
    },
    methods: {
      setActiveTab (tab) {
        this.activeTab = tab.name
        CHECK_URL_ON_HASH_CHANGE = false
        window.location.hash = `siteconf?tab=${tab.name}`
      }
    }
  }
</script>