<template>
  <button @click="$emit('click')" type="button" :disabled="processing || disabled">
    <i v-if="processing" class="fa fa-circle-o-notch fa-spin"/>
    <i v-else-if="icon" :class="icon"/>
    <slot></slot>
  </button>
</template>

<script>
  export default {
    props: ['processing', 'icon', 'disabled']
  }
</script>
