<template>
  <div class="well well-sm">
    <button @click="showContentTypeModal = true" type="button" class="btn btn-link font-sm">
      <i class="fa fa-plus"></i> Добавить тип материала
    </button>
    <datatable
      :columns="columns"
      src="guglex/contentType/list"
      ref="contentTypesTable"
    />
    <!-- Форма редактирования типа материала -->
    <content-type-edit-modal
      v-if="showContentTypeModal"
      @success="$refs.contentTypesTable.update()"
      @close="showContentTypeModal = false"
    />
  </div>
</template>

<script>
  import ContentTypeEditModal from './ContentTypeModal'
  import TdLabel from './td/Label'

  export default {
    components: {ContentTypeEditModal},
    data () {
      return {
        showContentTypeModal: false
      }
    },
    computed: {
      columns () {
        return [
          {title: 'Тип материала', field: 'label', component: TdLabel, tdClass: 'text-nowrap'},
          {title: 'Метки', field: 'labels', handler: value => value.length ? value.join(', ') : '-'}
        ]
      }
    }
  }
</script>
