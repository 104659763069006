<template>
  <div>
    <div v-if="loading" class="well well-sm text-muted">Подготовка...</div>
    <div v-else class="smart-form well no-padding">
      <fieldset>
        <div class="alert alert-warning fade in">
          <i class="fa-fw fa fa-warning"></i>
          Это действие нельзя отменить!
        </div>
        <table class="table table-hover">
          <tbody>
            <tr>
              <td class="txt-color-blueDark">Наименование:</td>
              <td>
                {{form.label}}
                <div class="font-sm text-muted">{{form.name}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <footer>
        <submit
          :processing="processing"
          class="btn btn-danger"
          icon="fa fa-trash-o"
          @click="submit"
        >
          Удалить
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'InterrelationDeleteForm',
    props: ['interrelationId'],
    data () {
      return {
        form: {
          name: null,
          label: null
        },
        loading: true,
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post(`interrelation/${this.interrelationId}/delete`)
          .then(() => {
            this.$emit('deleted')
            smallBoxSuccess('Взаимосвязь удалена.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async created () {
      const {data} = await axios.get(`interrelation/${this.interrelationId}/get`)
      this.form = data.interrelation
      this.loading = false
    }
  }
</script>

<style scoped>
  td:first-child {
    white-space: nowrap;
  }
  td:first-child {
    font-weight: bold;
    text-align: right;
    width: 10px;
  }
</style>