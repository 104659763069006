<template>
  <modal name="form" @closed="$emit('close')" @opened="openedHandler" height="auto" width="500">
    <div class="well no-margin-bottom">
      <h4><strong>Переименовать</strong></h4>
      <hr/>
      <div class="font-md current-name">Новое имя <span>{{item.name}}</span>:</div>
      <div :class="{'has-error': errors.filename}">
        <input v-model="newName" @keyup.enter="submit" type="text" class="form-control input-lg" placeholder="Новое имя"/>
        <div
          v-for="error in errors.filename"
          :key="error"
          class="help-block"
        >
          {{error}}
        </div>
      </div>
      <hr/>
      <button @click="submit" type="button" class="btn btn-primary pull-right" :disabled="state">
        <i v-if="state" class="fa fa-circle-o-notch fa-spin"/> Переименовать
      </button>
      <button @click="close" type="button" class="btn btn-link pull-right">Отмена</button>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    props: ['item'],
    data () {
      return {
        newName: this.item.name,
        errors: {},
        state: false
      }
    },
    methods: {
      submit () {
        let {path} = this.item
        let {newName} = this
        this.state = true
        axios.post('gallery/rename', {path, newName})
          .then(() => {
            let newPath = path.replace(/\/[^/]+$/, `/${newName}`)
            this.errors = []
            let message = this.item.is_dir ? 'Папка переименована.' : 'Файл переименован.'
            smallBoxSuccess(message)
            this.$emit('success', {path, newPath, newName})
            this.close()
          })
          .catch(({response}) => {
            this.errors = response.data
            this.state = false
          })
      },
      close () {
        this.$modal.hide('form')
      },
      openedHandler () {
        this.$el.querySelector('input[type=text]').focus()
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>

<style scoped>
  .current-name {
    margin-bottom: 10px;
  }
  .current-name span {
    background: #eee;
  }
</style>