<template>
  <div style="position: relative">
    <content-type-edit
      v-if="activeTab == 'ContentTypeCreate'"
      @created="setEditTab"
    />
    <div v-else-if="activeTab">
      <div class="tabs-body">
        <ul class="nav nav-tabs">
          <li v-for="tab in tabs" :class="{active: tab.name == activeTab}">
            <a @click.prevent="setActiveTab(tab)" href="#">{{tab.title}}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active">
            <component
              :is="activeTab"
              :content-type-id="contentTypeId"
              @deleted="handleDeleted"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <button
        type="button"
        class="btn btn-link no-padding font-xs content-type-create-btn"
        @click="setCreateTab"
      >
        Создать тип материала
      </button>
      <datatable
        :columns="columns"
        :options="{tableClass: 'table-bordered'}"
        src="contentType/list"
        @setEditTab="setEditTab"
      />
    </div>
  </div>
</template>

<script>
  import ContentTypeEdit from './ContentTypeForm'
  import ContentTypeFields from './fields/ContentTypeFields'
  import ContentTypeMeta from './ContentTypeMeta'
  import ContentTypeDelete from './ContentTypeDelete'
  import TdLabel from './td/Label'

  export default {
    name: 'ContentTypes',
    components: {
      ContentTypeEdit,
      ContentTypeFields,
      ContentTypeMeta,
      ContentTypeDelete
    },
    data () {
      return {
        activeTab: _hash_struct.tab,
        contentTypeId: _hash_struct.query.id,
        tabs: [
          {title: 'Редактирование', name: 'ContentTypeEdit'},
          {title: 'Управление полями', name: 'ContentTypeFields'},
          {title: 'Мета теги', name: 'ContentTypeMeta'},
          {title: 'Удалить тип материала', name: 'ContentTypeDelete'}
        ]
      }
    },
    computed: {
      columns () {
        return [
          {
            title: 'ID',
            field: 'id'
          },
          {
            title: 'Наименование',
            field: 'label',
            filter: {type: 'text', width: '100%'},
            component: TdLabel,
            tdClass: 'text-nowrap'
          },
          {
            title: 'Кол-во материалов',
            field: 'content_count'
          }
        ]
      }
    },
    methods: {
      setEditTab (id) {
        this.contentTypeId = id
        this.setActiveTab({name: 'ContentTypeEdit'})
      },
      setCreateTab () {
        this.setActiveTab({name: 'ContentTypeCreate'})
      },
      setActiveTab (tab) {
        this.activeTab = tab.name
        this.setTab(tab.name)
      },
      setTab (tab) {
        CHECK_URL_ON_HASH_CHANGE = false;
        const query = []
        if (tab) {
          query.push(`tab=${tab}`)
        }
        if (this.contentTypeId) {
          query.push(`id=${this.contentTypeId}`)
        }
        window.location.hash = 'contentType' + (query.length ? `?${query.join('&')}` : '')
      },
      handleDeleted () {
        this.contentTypeId = null
        this.activeTab = null
        this.setTab()
      }
    }
  }
</script>

<style scoped>
  .content-type-create-btn {
    position: absolute;
    top: -22px;
    left: 49px;
  }
</style>