<template>
  <div>
    <div>{{value}}</div>
    <div class="font-xs text-muted">{{row.url}}</div>
  </div>
</template>

<script>
  export default {
    props: ['value', 'row']
  }
</script>
