<template>
  <div>
    <label class="label">Порядок полей для источника вкладок</label>
    <datatable
      :columns="columns"
      :options="{sortableRows: true}"
      :sort="false"
      :src="items"
      @edit="showEditForm"
      @rowSortDragstart="handleRowSortDragstart"
      @rowSortDragenter="handleRowSortDragenter"
      @removeRow="handleRemoveRow"
    />
    <button
      class="btn btn-link no-padding font-sm"
      type="button"
      style="margin-left: 10px;"
      @click="showCreateForm"
    >
      Добавить поле
    </button>
    <tab-field-form
      v-if="showForm"
      v-model="items[editIdx]"
      :order="lastOrder"
      @close="showForm = false"
    />
  </div>
</template>

<script>
  import TdField from './td/Field'
  import TabFieldForm from './TabFieldForm'

  export default {
    name: 'DisplayTabFields',
    components: { TabFieldForm },
    props: ['value'],
    data () {
      const items = JSON.parse(JSON.stringify(this.value)).map((item, idx) => {
        item.order = idx
        return item
      })
      return {
        items,
        editIdx: null,
        showForm: false,
        rowSortMovedId: null
      }
    },
    computed: {
      columns () {
        return [
          {
            title: '#',
            number: true
          },
          {
            title: 'Поле для вкладок',
            field: 'field_id',
            component: TdField
          },
          {
            title: 'Метка вкладки',
            field: 'label',
            handler: value => value || '<span class="text-muted">[label]</span>'
          },
          {
            title: '',
            removeRowButton: true
          }
        ]
      },
      lastOrder () {
        return Math.max.apply(null, this.items.map(item => item.order)) + 1
      }
    },
    watch: {
      items (items) {
        this.$emit('input', JSON.parse(JSON.stringify(items)))
      }
    },
    methods: {
      showCreateForm () {
        this.editIdx = this.items.length
        this.showForm = true
      },
      showEditForm (id) {
        this.editIdx = this.items.findIndex(item => item.id == id)
        this.showForm = true
      },
      handleRowSortDragstart (movedId) {
        this.rowSortMovedId = movedId
      },
      handleRowSortDragenter (enterId) {
        let movedItem = this.items.find(item => item.id == this.rowSortMovedId)
        let movedOrder = movedItem.order
        let enterItem = this.items.find(item => item.id == enterId)
        let enterOrder = enterItem.order
        movedItem.order = enterOrder
        enterItem.order = movedOrder
        this.items
          .sort((a, b) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          })
      },
      handleRemoveRow (id) {
        const idx = this.items.findIndex(item => item.id == id)
        this.items.splice(idx, 1)
      }
    }
  }
</script>