<template>
  <div>
    <slot v-bind="{ext, src, isImage, base64String, height, width}"></slot>
  </div>
</template>

<script>
  export default {
    props: ['file'],
    data () {
      return {
        base64String: null,
        height: null,
        width: null
      }
    },
    computed: {
      ext () {
        let value = this.base64String || ''
        switch (value.charAt(0)) {
          case '/': return 'jpg'
          case 'i': return 'png'
          case 'R': return 'gif'
          default: return false
        }
      },
      src () {
        if (this.isImage) {
          let {base64String, ext} = this
          return `data:image/${ext};base64,${base64String}`
        }
        return false
      },
      isImage () {
        return ['jpg', 'png', 'gif'].includes(this.ext)
      }
    },
    mounted () {
      (file => {
        return new Promise(resolve => {
          let reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            resolve(reader.result.split(',')[1])
          }
        })
      })(this.file)
        .then(data => {
          this.base64String = data
          if (this.isImage) {
            let image = new Image;
            image.src = this.src;
            image.onload = () => {
              this.height = image.height
              this.width = image.width
            }
          }
        })
    }
  }
</script>
