<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <vp-editor v-else v-model="code"/>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Html',
    props: ['blockId', 'value'],
    data () {
      return {
        code: '',
        loading: true
      }
    },
    watch: {
      code () {
        this.emit()
      }
    },
    methods: {
      emit () {
        this.$emit('input', {html: this.code})
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getBlockTypeSettings/Html`)
      this.code = data.html
      this.loading = false
      this.$emit('loaded')
      this.emit()
    }
  }
</script>