<template>
  <div class="popper-box">
    <a v-show="hasAText" @click.prevent="open" :disabled="disabled" href="#" class="text-nowrap" :class="aClass" :tabindex="aTabindex">
      <slot name="a-text">{{aText}}</slot>
    </a>
    <div v-show="state" class="popper popover" :class="xPlacement">
      <div v-if="!noArrow" class="arrow"></div>
      <div v-if="state">
        <h3 v-if="title" class="popover-title text-nowrap">
          <strong>{{title}}</strong>
        </h3>
        <div class="popover-content clearfix">
          <slot :data="slotData"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Popper from 'popper.js'

  let isChild = function (inner, outer) {
    let findAttribute = '__popper__find__child__by__attribute__'
    inner.setAttribute(findAttribute, 1)
    let child = outer.querySelector('[' + findAttribute + '="1"]')
    inner.removeAttribute(findAttribute)
    return !!child
  }

  export default {
    props: ['placement', 'a-class', 'a-text', 'title', 'disabled', 'tabindex', 'no-arrow'],
    data () {
      return {
        state: false,
        popper: null,
        reference: null,
        xPlacement: null,
        slotData: null
      }
    },
    computed: {
      hasAText () {
        let aText = this.aText
        return this.$slots['a-text'] || (typeof aText != 'undefined' && aText.trim() != '')
      },
      aTabindex () {
        return typeof this.tabindex == 'undefined' ? '' : this.tabindex
      }
    },
    watch: {
      '$store.state.common.domDocumentEvents.click' (event) {
        this.domDocumentClickHandler(event)
      },
      '$store.state.common.domDocumentEvents.escape' () {
        this.close()
      }
    },
    methods: {
      open (event, slotData, selfClose) {
        if (this.state) {
          if (!selfClose || (selfClose && event.target === this.reference)) {
            this.close()
            return
          }
        }

        this.state = true
        this.slotData = slotData
        this.setReference(event)
        this.setPlacement()
        let popperElement = this.$el.children[1]
        let popperOptions = {
          placement: this.xPlacement,
          onCreate: data => {
            this.xPlacement = data.attributes['x-placement']
          },
          onUpdate: data => {
            this.xPlacement = data.attributes['x-placement']
          }
        }
        this.$nextTick(() => {
          this.$emit('open')
          if (this.popper) {
            this.popper.destroy()
          }
          this.popper = new Popper(this.reference, popperElement, popperOptions)
          this.$children.forEach(childComponent => {
            childComponent.$on('hook:updated', this.popper.scheduleUpdate)
            childComponent.$on('shouldClose', this.close)
          })
          let input = this.$el.querySelector('input[type=text], textarea')
          if (input) {
            input.focus()
          }
        })
      },
      close () {
        if (this.state) {
          this.state = false
          this.$emit('close')
          if (this.popper) {
            this.$nextTick(() => {
              this.popper.destroy()
            })
          }
        }
      },
      scheduleUpdate () {
        this.popper && this.popper.scheduleUpdate()
      },
      setReference (event) {
        this.reference = event.target
      },
      setPlacement () {
        let placement = this.placement || 'top'
        let reference = this.reference
        if (reference.dataset.popperPlacement) {
          placement = reference.dataset.popperPlacement
        }
        this.xPlacement = placement
      },
      domDocumentClickHandler (e) {
        if (!this.state) {
          return
        }

        let popperElement = this.$el.children[1]
        let popperLink = this.$el.children[0]

        if (popperLink == e.target || isChild(e.target, popperLink)) {
          return
        }

        if (isChild(e.target, popperElement)) {
          if (e.target.dataset.popper == 'close') {
            this.close()
          }
        } else if (e.target !== this.reference) {
          this.close()
        }
      }
    },
    mounted () {
      this.$el.addEventListener('DOMNodeInserted', this.scheduleUpdate, false)
    },
    destroyed () {
      this.$el.removeEventListener('DOMNodeInserted', this.scheduleUpdate, false)
      if (this.popper) {
        this.popper.destroy()
      }
    }
  }
</script>

<style scoped>
  /* Popper */
  .popper .popover-content .btn,
  .popper .popover-buttons .btn{
    margin-left: 10px
  }
  .popper hr {
    margin: 10px 0;
  }
  .popper .popover-content .form-actions {
    margin-top: 10px;
  }
  /* Popper from scoped */
  .popper.popover {
    display: block;
    max-width: none;
  }
  .popper.popover.top,
  .popper.popover.top-start,
  .popper.popover.top-end
  {
    top: -5px !important;
  }
  .popper.popover.left {
    left: -10px !important;
  }

  .popover.top-start > .arrow {
    left: 6%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #999;
    border-top-color: rgba(0,0,0,.25);
    bottom: -11px
  }

  .popover.top-start > .arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff
  }
  .popover.top-end > .arrow {
    right: 6%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #999;
    border-top-color: rgba(0,0,0,.25);
    bottom: -11px
  }

  .popover.top-end > .arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff
  }

  .popover.bottom-start > .arrow {
    left: 6%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0,0,0,.25);
    top: -11px
  }

  .popover.bottom-start > .arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff
  }
  .popover.bottom-start, .popover.bottom-end {
    margin-top: 10px;
  }

  .popover.bottom-end > .arrow {
    right: 6%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0,0,0,.25);
    top: -11px
  }

  .popover.bottom-end > .arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff
  }

  .popover.left-start {
    left: -10px !important;
    top: -7px !important;
  }

  .popover.left-start > .arrow {
    top: 7%;
    right: -10px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0,0,0,.25);
  }

  .popover.left-start > .arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: #fff;
    bottom: -10px;
  }

  .popover.right-start {
    left: 10px !important;
    top: -7px !important;
  }

  .popover.right-start > .arrow {
    top: 7%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: #999;
    border-right-color: rgba(0,0,0,.25)
  }

  .popover.right-start > .arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #fff
  }

  .popover-content h5.sm {
    margin-top: 0;
  }
</style>
