<template>
  <div>
    Ссылки отображения
    <button
      class="btn btn-link no-padding font-sm"
      type="button"
      @click="showCreateForm"
    >
      Создать
    </button>
    <p v-for="(link, idx) in links" :key="link.id">
      <span class="text-muted">
        [display_link_{{link.order}}] |
      </span>
      <button
        class="btn btn-link no-padding font-sm"
        type="button"
        @click="showEditForm(idx)"
      >
        {{link.text || '(без тайтла)'}}
      </button>
    </p>
    <display-link-edit-form
      v-if="showForm"
      :value="form"
      @close="showForm = false"
    />
  </div>
</template>

<script>
  import DisplayLinkEditForm from './DisplayLinkEditForm'

  export default {
    name: 'DisplayLinks',
    components: { DisplayLinkEditForm },
    data () {
      return {
        form: null,
        showForm: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      links () {
        return this.display.form.displayLinks
      }
    },
    watch: {
      showForm (flag) {
        if (!flag) {
          this.form = null
        }
      }
    },
    methods: {
      showCreateForm () {
        this.showForm = true
      },
      showEditForm (idx) {
        this.form = JSON.parse(JSON.stringify(this.links[idx]))
        this.showForm = true
      }
    }
  }
</script>