<template>
  <div>
    <div v-if="loading" class="well well-sm text-muted">Подготовка...</div>
    <div class="smart-form well no-padding">
      <fieldset>
        <section>
          <header>Основные настройки</header>
        </section>
        <section>
          <label class="label">Машинное имя</label>
          <label class="input">
            <input v-model="form.name" placeholder="Машинное имя" type="text"/>
          </label>
          <div class="note">Машинное имя.</div>
        </section>
        <section>
          <label class="label">Метка</label>
          <label class="input">
            <input v-model="form.label" placeholder="Метка" type="text"/>
          </label>
          <div class="note">Метка.</div>
        </section>
        <section>
          <label class="label">Описание</label>
          <label class="textarea">
            <label class="textarea">
                <textarea
                  v-model="form.description"
                  rows="5"
                  placeholder="Описание"
                ></textarea>
            </label>
          </label>
          <div class="note">Описание.</div>
        </section>
      </fieldset>
      <footer>
        <submit
          :processing="processing"
          class="btn btn-primary"
          icon="fa fa-save"
          @click="submit"
        >
          Сохранить
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'InterrelationEditForm',
    props: ['interrelationId'],
    data () {
      return {
        form: {
          name: null,
          label: null,
          description: null
        },
        processing: false,
        loading: true
      }
    },
    computed: {
      isCreate () {
        return !this.interrelationId
      }
    },
    methods: {
      submit () {
        this.processing = true
        const url = `interrelation/${this.isCreate ? 'create' : `${this.interrelationId}/edit`}`
        const params = {
          form: this.form
        }
        axios.post(url, params)
          .then(({data}) => {
            if (this.isCreate) {
              this.$emit('created', data.id)
            }
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async created () {
      if (!this.isCreate) {
        const {data} = await axios.get(`interrelation/${this.interrelationId}/get`)
        this.form = data.interrelation
      }
      this.loading = false
    }
  }
</script>