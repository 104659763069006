<template>
  <modal name="form" @closed="$emit('close')" height="auto">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>Фильтр по типу материала</h4>
        <hr/>
        <section>
          <label class="label">
            Тип материала
            <button
              class="btn btn-link font-sm no-padding"
              type="button"
              @click="addItem"
            >
              Добавить
            </button>
          </label>
          <table v-if="items.length">
            <tbody>
              <tr v-for="(item, idx) in items" :key="item.key">
                <td>
                  <xselect2
                    v-model="item.form"
                    label="Выбор типа материала"
                    tk="label"
                    :key="item.key"
                    :src="contentTypes"
                    width="100%"
                  />
                </td>
                <td>
                  <button
                    class="btn btn-link no-padding"
                    type="button"
                    @click="items.splice(idx, 1)"
                  >
                    <i class="fa fa-times"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-muted">Тип материала не задан</div>
        </section>
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            :disabled="!isEdited"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'
  import _uniqueId from 'lodash/uniqueId'

  export default {
    name: 'DisplayContentTypeEditForm',
    data () {
      const items = this.$store.state.display.form.filterContentTypes.map(item => {
        item.key = _uniqueId()
        if (!item.form) {
          item.form = {
            id: item.id,
            label: `${item.label} (${item.name})`
          }
        }
        return item
      })
      return {
        items,
        isEdited: false,
        processing: false
      }
    },
    computed: {
      displayId () {
        return this.$store.state.display.form.id
      },
      contentTypes () {
        return this.$store.state.display.contentTypes.map(item => {
          return {
            id: item.id,
            text: `${item.label} (${item.name})`
          }
        })
      }
    },
    watch: {
      items: {
        deep: true,
        handler () {
          this.isEdited = true
        }
      }
    },
    methods: {
      submit () {
        this.processing = true
        const filteredItems = this.items.filter((item, idx, items) => {
          if (item.form.id) {
            const dupIdx = items.findIndex(_item => _item.form.id == item.form.id)
            return idx === dupIdx
          }
          return false
        })
        const contentTypeIds = filteredItems.map(item => item.form.id)
        axios.post(`dview/display/${this.displayId}/filter/contentType/edit`, {contentTypeIds})
          .then(() => {
            this.$store.commit('display/updateFormFilterContentTypes', filteredItems)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      addItem () {
        this.items.unshift({
          key: _uniqueId(),
          form: {
            id: null,
            label: ''
          }
        })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>

<style scoped>
  table {
    width: 100%;
  }
  td {
    padding: 5px 5px 0 0;
  }
</style>