<template>
  <div>
    <section>
      <label class="label">
        Подстановочный шаблон метки
      </label>
      <label class="input">
        <input
          v-model="wildcardTitle"
          placeholder="Подстановочный шаблон метки"
          class="form-control"
          type="text"
        />
      </label>
      <div class="note">
        Подстановочный шаблон метки для вывода в заголовок отображения. Используйте [value] для подстановки значение фильтра
      </div>
    </section>
    <section>
      <div class="row">
        <div class="col" :class="isNotNullOperator ? 'col-sm-12' : 'col-sm-4'">
          <label class="label">
            Оператор
          </label>
          <label class="input">
            <select v-model="operator" class="form-control">
              <option :value="null" selected disabled>Выберите опретаор</option>
              <option
                v-for="operator in operators"
                :key="operator.symbol"
                :value="operator.symbol"
              >
                {{operator.label}}
              </option>
            </select>
          </label>
        </div>
        <template v-if="!isNotNullOperator">
          <div class="col" :class="isBetweenOperator ? 'col-sm-4' : 'col-sm-8'">
            <label class="label">
              Значение фильтра {{isBetweenOperator ? 'Min' : ''}}
            </label>
            <label
              v-if="isListable"
              class="select select-multiple"
            >
              <select
                v-model="collectValues"
                multiple
                size="6"
                class="custom-scroll"
              >
                <option
                  v-for="item in fieldValues"
                  :key="item.value"
                  :value="item.value"
                >
                  {{item.label}}
                </option>
              </select>
            </label>
            <template v-else-if="isArrayableOperator">
                <textarea
                  v-model="transformCollectValues"
                  placeholder="Значения фильтра"
                  rows="6"
                  class="form-control"
                  style="width: 600px; padding: 6px 12px"
                ></textarea>
              <div class="note">
                Каждое значение с новой строки.
              </div>
            </template>
            <label v-else class="input">
              <input
                v-if="isBetweenOperator"
                v-model="collectValueMin"
                placeholder="Значение фильтра Min"
                class="form-control"
                type="text"
              />
              <input
                v-else
                v-model="collectValue"
                placeholder="Значение фильтра"
                class="form-control"
                type="text"
              />
            </label>
          </div>
          <div v-if="isBetweenOperator" class="col col-sm-4">
            <label class="label">
              Значение фильтра Max
            </label>
            <label class="input">
              <input
                v-model="collectValueMax"
                placeholder="Значение фильтра Max"
                class="form-control"
                type="text"
              />
            </label>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'DefaultEditForm',
    computed: {
      form () {
        const form = JSON.parse(JSON.stringify(this.display.filterCriteriaForm))
        form.collect = form.collect || {}
        const collect = form.collect
        if (!Array.isArray(collect.values)) {
          collect.values = []
        }
        if (typeof collect.value == 'undefined') {
          collect.value = null
        }
        if (typeof collect.valueMin == 'undefined') {
          collect.valueMin = null
        }
        if (typeof collect.valueMax == 'undefined') {
          collect.valueMax = null
        }
        return form
      },
      wildcardTitle: {
        get () {
          return this.form.wildcard_title
        },
        set (value) {
          const {form} = this
          form.wildcard_title = value
          this.updateForm(form)
        }
      },
      operator: {
        get () {
          return this.form.operator || null
        },
        set (value) {
          const {form} = this
          form.operator = value
          this.updateForm(form)
        }
      },
      collectValue: {
        get () {
          return this.form.collect.value
        },
        set (value) {
          const {form} = this
          form.collect.value = value
          this.updateForm(form)
        }
      },
      collectValues: {
        get () {
          return this.form.collect.values
        },
        set (values) {
          const {form} = this
          form.collect.values = values
          this.updateForm(form)
        }
      },
      transformCollectValues: {
        get () {
          return this.form.collect.values.join('\n')
        },
        set (value) {
          const {form} = this
          form.collect.values = value.split('\n')
          this.updateForm(form)
        }
      },
      collectValueMin: {
        get () {
          return this.form.collect.valueMin
        },
        set (value) {
          const {form} = this
          form.collect.valueMin = value
          this.updateForm(form)
        }
      },
      collectValueMax: {
        get () {
          return this.form.collect.valueMax
        },
        set (value) {
          const {form} = this
          form.collect.valueMax = value
          this.updateForm(form)
        }
      },
      display () {
        return this.$store.state.display
      },
      operators () {
        const operators = this.display.options.operators
        if (this.isListable) {
          return operators.filter(
            operator => ['in', 'and', 'not in', 'is not null'].includes(operator.symbol)
          )
        }
        return operators
      },
      isBetweenOperator () {
        return ['between', 'not between'].includes(this.form.operator)
      },
      isArrayableOperator () {
        return ['in', 'and', 'not in'].includes(this.form.operator)
      },
      isNotNullOperator () {
        return this.form.operator == 'is not null'
      },
      isListable () {
        return Boolean(this.fieldValues.length)
      },
      fieldValues () {
        return this.form.field.values
      }
    },
    methods: {
      updateForm (form) {
        this.$store.commit('display/setFilterCriteriaForm', form)
      }
    }
  }
</script>