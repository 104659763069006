<template>
  <modal name="form" @closed="$emit('close')" :scrollable="true" height="auto" width="1200">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          {{ removing ? 'Удалить' : 'Настроить' }} фильтр: {{ form.field.label }} ({{ form.field.name }})
          <div v-if="!removing" class="smart-form" style="margin-top: 8px;">
            <label class="checkbox">
              <input v-model="isExpand" type="checkbox">
              <i></i> Раскрыть этот фильтр для посетителей
            </label>
          </div>
        </h4>
        <hr/>
        <section v-if="removing">
          <div>
            Вы хотите удалить фильтр: {{ form.field.label }} ({{ form.field.name }})?
          </div>
          <button
            class="btn btn-link font-sm no-padding"
            type="button"
            @click="removing = false"
          >
            Отмена
          </button>
        </section>
        <section v-else>
          <component :is="editForm"/>
          <hr/>
          <interrelation-edit-form/>
        </section>
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            class="btn btn-link"
            @click="remove"
          >
            <span :class="{'text-danger': removing}">
              Удалить
            </span>
          </submit>
          <submit
            v-if="!removing"
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import DefaultEditForm from './DefaultEditForm'
  import ExpandEditForm from './ExpandEditForm'
  import InterrelationEditForm from './InterrelationEditForm'
  import axios from 'axios'

  export default {
    name: 'DisplayCriteriaEditForm',
    components: {
      DefaultEditForm,
      ExpandEditForm,
      InterrelationEditForm
    },
    data () {
      return {
        removing: false,
        processing: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      form () {
        return JSON.parse(JSON.stringify(this.display.filterCriteriaForm))
      },
      isExpand: {
        get () {
          return this.form.isExpand
        },
        set (value) {
          const {form} = this
          form.isExpand = value
          this.$store.commit('display/setFilterCriteriaForm', form)
        }
      },
      editForm () {
        return this.isExpand ? 'ExpandEditForm' : 'DefaultEditForm'
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post(`dview/display/filter/criteria/${this.form.id}/edit`, this.form)
          .then(({data}) => {
            const display = JSON.parse(JSON.stringify(this.display))
            const idx = display.form.filterCriteria.findIndex(item => item.id == this.form.id)
            display.form.filterCriteria.splice(idx, 1, data)
            this.$store.commit('display/updateFormFilterCriteria', display.form.filterCriteria)
            this.$store.commit('display/setFilterCriteriaForm', data)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      remove () {
        if (!this.removing) {
          this.removing = true
          return
        }
        this.processing = true
        axios.post(`dview/display/filter/criteria/${this.form.id}/delete`)
          .then(() => {
            const display = JSON.parse(JSON.stringify(this.display))
            const idx = display.form.filterCriteria.findIndex(item => item.id == this.form.id)
            display.form.filterCriteria.splice(idx, 1)
            this.$store.commit('display/updateFormFilterCriteria', display.form.filterCriteria)
            this.close()
            smallBoxSuccess('Фильтр удален.')
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>