<template>
  <modal name="form" @closed="$emit('close')" height="auto" :scrollable="true">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          Удалить отображение
        </h4>
        <hr/>
        <section>
          <div class="alert alert-warning fade in">
            <i class="fa-fw fa fa-warning"></i>
            Это действие нельзя отменить!
          </div>
          <div>Вы действительно хотите удалить отображение?</div>
          <div>Машинное имя: {{form.name}}</div>
        </section>
        <hr/>
        <div class="pull-right">
          <submit @click="submit" :processing="processing" class="btn btn-link">
            Удалить
          </submit>
          <button @click="close" type="button" class="btn btn-link">Закрыть</button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DisplayDeleteForm',
    data () {
      const displayForm = this.$store.state.display.form
      return {
        form: {
          id: displayForm.id,
          name: displayForm.name,
          view_id: displayForm.viewId
        },
        processing: false
      }
    },
    methods: {
      submit () {
        const {form} = this
        this.processing = true
        axios.post(`dview/display/${form.id}/delete`)
          .then(() => {
            smallBoxSuccess('Выполнено.')
            window.location.hash = `dview?tab=Display&id=${form.view_id}`
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>