<template>
  <div>
    <div v-if="loading" class="well well-sm text-muted">Подготовка...</div>
    <div v-else class="smart-form well no-padding">
      <fieldset>
        <div class="alert alert-warning fade in">
          <i class="fa-fw fa fa-warning"></i>
          Это действие нельзя отменить!
        </div>
        <table class="table table-hover">
          <tbody>
            <tr>
              <td class="txt-color-blueDark">Машинное имя:</td>
              <td>{{form.name}}</td>
            </tr>
            <tr>
              <td class="txt-color-blueDark">Описание типа материала:</td>
              <td>{{form.description}}</td>
            </tr>
            <tr>
              <td class="txt-color-blueDark">Кол-во материалов, использующих этот тип:</td>
              <td>{{contentCount}}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <footer>
        <submit @click="submit" :processing="processing" class="btn btn-danger" icon="fa fa-trash-o">
          Удалить
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'ContentTypeDelete',
    props: ['contentTypeId'],
    data () {
      return {
        form: {
          name: null,
          description: null
        },
        contentCount: 0,
        loading: true,
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post(`contentType/${this.contentTypeId}/delete`)
          .then(() => {
            this.$emit('deleted')
            smallBoxSuccess('Тип материала удален.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async created () {
      const {data} = await axios.get(`contentType/${this.contentTypeId}/get`)
      this.form = data.contentType
      this.contentCount = data.contentCount
      this.loading = false
    }
  }
</script>

<style scoped>
  td:first-child {
    white-space: nowrap;
  }
  td:first-child {
    font-weight: bold;
    text-align: right;
    width: 10px;
  }
</style>