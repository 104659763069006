<template>
  <modal name="form" @closed="$emit('close')" height="auto" width="800" :scrollable="true">
    <div class="well no-margin-bottom">
      <h4>
        <strong>{{item.name}}</strong>
        <table class="preview-header">
          <tbody>
            <tr>
              <td>
                <div><small>Размер: {{item.width}}&times;{{item.height}}</small></div>
                <div><small>Вес файла: {{item.size}}</small></div>
                <div><small>Дата: {{time}}</small></div>
              </td>
              <td>
                <div><small>Альт. текст: <input v-model="form.alt" @keyup="isEdited = true" @keyup.enter="submit" type="text"/></small></div>
                <div><small>ID Youtube: <input v-model="form.video" @keyup="isEdited = true" @keyup.enter="submit" type="text"/></small></div>
              </td>
              <td class="preview-submit">
                <div v-if="isEdited">
                  <button @click="submit" type="button" class="btn btn-primary">
                    Применить
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </h4>
      <hr/>
      <iframe v-if="item.video" width="100%" height="425" :src="`https://www.youtube.com/embed/${item.video}`" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
      <div v-else class="preview-image">
        <img :src="`/storage/${item.preview}`"/>
      </div>
      <hr/>
      <table class="actions">
        <tbody>
          <tr>
            <td>
              <button @click="$emit('prev')" type="button" class="btn btn-link">
                <i class="glyphicon glyphicon-chevron-left"/>
              </button>
              <button @click="$emit('next')" type="button" class="btn btn-link">
                <i class="glyphicon glyphicon-chevron-right"/>
              </button>
              <button @click="$emit('batch')" type="button" class="btn btn-link">
                <span v-if="isBatched">Снять выбор</span>
                <span v-else>Выбрать</span>
              </button>
            </td>
            <td>
              <button @click="$modal.show('removeForm')" type="button" class="btn btn-link">Удалить</button>
              <button @click="$emit('close')" type="button" class="btn btn-link">Закрыть</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal name="removeForm" height="auto" width="350">
      <div class="well no-margin-bottom">
        <div>
          <strong>Удалить этот файл?</strong>
        </div>
        <hr/>
        <button @click="remove" type="button" class="btn btn-primary pull-right" :disabled="state">
          <i v-if="state" class="fa fa-circle-o-notch fa-spin"/> Удалить файл
        </button>
        <button @click="$modal.hide('removeForm')" type="button" class="btn btn-link pull-right">Отмена</button>
        <div class="clearfix"></div>
      </div>
    </modal>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    props: ['item', 'is-batched'],
    data () {
      return {
        width: null,
        height: null,
        state: false,
        form: {},
        isEdited: false
      }
    },
    computed: {
      time () {
        let d = new Date(this.item.time)
        return d.toLocaleString()
      }
    },
    watch: {
      item () {
        this.setForm()
      }
    },
    methods: {
      remove () {
        let params = {
          paths: [this.item.path]
        }
        this.state = true
        axios.post('gallery/remove', params)
          .then(() => {
            this.state = false
            smallBoxSuccess('Файл удален.')
            this.close('removeForm')
            this.$emit('removed')
          })
      },
      submit () {
        let {alt, video} = this.form
        let {path} = this.item
        axios.post('gallery/editItem', {alt, video, path})
          .then(() => {
            this.$emit('edit', this.form)
            this.isEdited = false
            smallBoxSuccess('Успешно отредактировано.')
          })
      },
      setForm () {
        let {alt, video} = this.item
        this.form = {alt, video}
        this.isEdited = false
      },
      close (modal) {
        this.$modal.hide(modal)
      }
    },
    mounted () {
      this.$modal.show('form')
      this.setForm()
    }
  }
</script>

<style scoped>
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    border: 1px solid #eee;
  }
  .actions {
    width: 100%;
  }
  .actions td {
    width: 50%;
  }
  .actions td:first-child {
    text-align: left;
  }
  .actions td:last-child {
    text-align: right;
  }
  .preview-header {
    width: 100%;
  }
  .preview-header td:first-child {
    width: 200px;
  }
  .preview-header td {
    padding-right: 70px;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
  }
  .preview-header input[type=text] {
    border: none;
    border-bottom: 1px dashed #eee;
    margin-left: 5px;
    width: 100%;
    color: #555;
  }
  .preview-submit {
    text-align: right !important;
    padding-right: 0 !important;
  }
  .preview-image {
    height: 365px;
    line-height: 365px;
    text-align: center;
  }
  .preview-image img {
    display: inline;
    vertical-align: middle;
  }
</style>