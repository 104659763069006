<template>
  <div class="well well-sm">
    <button @click="showSynonymModal = true" type="button" class="btn btn-link font-sm">
      <i class="fa fa-plus"></i> Создать синоним
    </button>
    <datatable
      :columns="columns"
      src="guglex/synonym/list"
      ref="synonymsTable"
    />
    <!-- Форма редактирования синонима -->
    <synomym-edit-modal
      v-if="showSynonymModal"
      @success="$refs.synonymsTable.update"
      @close="showSynonymModal = false"
    />
  </div>
</template>

<script>
  import SynomymEditModal from './SynonymModal'
  import TdLabel from './td/Label'

  export default {
    components: {SynomymEditModal},
    data () {
      return {
        showSynonymModal: false,
        loading: true
      }
    },
    computed: {
      columns () {
        return [
          {title: 'Признак', field: 'attribute_label', component: TdLabel, tdClass: 'text-nowrap'},
          {title: 'Значение', field: 'word', tdClass: 'text-nowrap'},
          {title: 'Синонимы', field: 'synonyms', handler: value => value.length ? value.join(', ') : '-', tdClass: 'text-nowrap'}
        ]
      }
    }
  }
</script>
