<template>
  <div style="padding: 0 10px 10px;">
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <div v-else>
      <table class="table table-bordered table-striped">
        <tbody>
          <tr class="info">
            <td colspan="2" class="smart-form">
              <span class="font-md" style="color: #333">
                Основные настройки
                <button
                  class="btn btn-link font-xs no-padding"
                  type="button"
                  @click="showDisplayEditForm = true"
                >
                  Редактировать
                </button>
              </span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Метка</td>
            <td>
              {{display.form.label}}
            </td>
          </tr>
          <tr>
            <td>Машинное имя</td>
            <td>
              {{display.form.name}}
            </td>
          </tr>
          <tr>
            <td>Заголовок отображения</td>
            <td>
              {{display.form.title}}
            </td>
          </tr>
          <tr>
            <td>URL адрес отображения</td>
            <td>
              {{display.form.url}}
            </td>
          </tr>
          <tr class="info">
            <td colspan="2" class="smart-form">
              <span class="font-md" style="color: #333">Настройка полей отображения и фильтров</span>
            </td>
          </tr>
          <tr>
            <td>Поля</td>
            <td>Фильтры</td>
          </tr>
          <tr>
            <td rowspan="5">
              <!-- Поля отображения -->
              <display-fields/>
            </td>
            <td>
              <!-- Типы материалов отображения -->
              <display-content-types/>
            </td>
          </tr>
          <tr>
            <td class="filter">
              <!-- Критерии фильтрации отображения -->
              <display-criteria/>
            </td>
          </tr>
          <tr>
            <td class="filter">
              <!-- Сортировка -->
              <display-order/>
            </td>
          </tr>
          <tr>
            <td class="filter">
              <!-- Контекстные фильтры -->
              <display-context/>
            </td>
          </tr>
          <tr>
            <td class="filter">
              <!-- Взаимосвязи отображения -->
              <display-interrelation/>
            </td>
          </tr>

          <tr class="info">
            <td colspan="2">
              <span class="font-md" style="color: #333;">
                Настройка ссылок отображения
              </span>
            </td>
          </tr>
          <tr>
            <td class="filter" colspan="2">
              <!-- Ссылки отображения -->
              <display-links/>
            </td>
          </tr>

          <tr class="info">
            <td colspan="2">
              <span class="font-md" style="color: #333;">
                Настройка стилей отображения
              </span>
            </td>
          </tr>
          <tr>
            <td class="filter" colspan="2">
              <!-- Стили отображения -->
              <display-styles/>
            </td>
          </tr>

          <tr class="info">
            <td colspan="2">
              <span class="font-md" style="color: #333;">
                Другие настройки
              </span>
            </td>
          </tr>
          <tr>
            <td>Группировка результата по вкладкам</td>
            <td>
              <button
                class="btn btn-link no-padding font-sm"
                type="button"
                @click="showDisplayTabsForm = true"
              >
                Настроить
              </button>
            </td>
          </tr>
          <tr>
            <td>Текст при отсутствии результата</td>
            <td>
              <button
                class="btn btn-link no-padding font-sm"
                type="button"
                @click="showDisplayNoResultEditForm = true"
              >
                Настроить
              </button>
            </td>
          </tr>
          <tr>
            <td>Постраничный вывод</td>
            <td>
              <button
                class="btn btn-link no-padding font-sm"
                type="button"
                @click="showDisplayPaginationEditForm = true"
              >
                Настроить
              </button>
            </td>
          </tr>
          <tr>
            <td>Мета теги</td>
            <td>
              <button
                class="btn btn-link no-padding font-sm"
                type="button"
                @click="showDisplayMetatagsEditForm = true"
              >
                Настроить
              </button>
            </td>
          </tr>
          <tr class="info">
            <td colspan="2">
              <span class="font-md" style="color: #333;">
                Блоки
              </span>
            </td>
          </tr>
          <template v-if="display.form.blocks.length">
            <tr v-for="block in display.form.blocks">
              <td colspan="2">
                <span
                  class="fa fa-circle"
                  :class="block.enabled ? 'text-success' : 'text-muted'"
                ></span>&nbsp;
                <a :href="`/#block?tab=BlockEditForm&id=${block.id}`" target="_blank">
                  {{block.description}} <span class="text-muted">({{block.name}})</span>
                </a>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="2">
              Отображение не выводится в блоках.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <display-edit-form
      v-if="showDisplayEditForm"
      @close="showDisplayEditForm = false"
    />
    <display-tabs-edit-form
      v-if="showDisplayTabsForm"
      @close="showDisplayTabsForm = false"
    />
    <display-no-result-edit-form
      v-if="showDisplayNoResultEditForm"
      @close="showDisplayNoResultEditForm = false"
    />
    <display-metatags-edit-form
      v-if="showDisplayMetatagsEditForm"
      @close="showDisplayMetatagsEditForm = false"
    />
    <display-pagination-edit-form
      v-if="showDisplayPaginationEditForm"
      @close="showDisplayPaginationEditForm = false"
    />
  </div>
</template>

<script>
  import DisplayEditForm from './DisplayEditForm'
  import DisplayFields from './field/DisplayFields'
  import DisplayContentTypes from './filters/content_type/DisplayContentTypes'
  import DisplayInterrelation from './filters/interrelation/DisplayInterrelation'
  import DisplayCriteria from './filters/criteria/DisplayCriteria'
  import DisplayContext from './filters/context/DisplayContext'
  import DisplayOrder from './order/DisplayOrder'
  import DisplayLinks from './links/DisplayLinks'
  import DisplayTabsEditForm from './tabs/DisplayTabsEditForm'
  import DisplayNoResultEditForm from './DisplayNoResultEditForm'
  import DisplayMetatagsEditForm from './DisplayMetatagsEditForm'
  import DisplayPaginationEditForm from './DisplayPaginationEditForm'
  import DisplayStyles from './styles/DisplayStyles'

  export default {
    name: 'DisplayForm',
    components: {
      DisplayEditForm,
      DisplayFields,
      DisplayContentTypes,
      DisplayInterrelation,
      DisplayCriteria,
      DisplayContext,
      DisplayOrder,
      DisplayLinks,
      DisplayStyles,
      DisplayTabsEditForm,
      DisplayNoResultEditForm,
      DisplayMetatagsEditForm,
      DisplayPaginationEditForm
    },
    props: ['displayId'],
    data () {
      return {
        showDisplayEditForm: false,
        showDisplayTabsForm: false,
        showDisplayNoResultEditForm: false,
        showDisplayMetatagsEditForm: false,
        showDisplayPaginationEditForm: false,
        loading: true
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      }
    },
    async created () {
      await this.$store.dispatch('display/initOptions')
      await this.$store.dispatch('display/initDisplay', this.displayId)
      this.loading = false
    }
  }
</script>