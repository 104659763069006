<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <template v-else>
      <fieldset>
        <section>
          <header>Настройка блока группы фильтров</header>
        </section>
        <section>
          <label class="label display-inline">Группа фильтров</label>
          <button
            class="btn btn-link no-padding font-xs add-filter-btn"
            type="button"
            @click="editFilter"
          >
            Добавить фильтр
          </button>
          <table class="table table-bordered table-filters">
            <thead>
              <tr>
                <th class="width-1">Метка</th>
                <th class="width-1 text-nowrap">URL маска</th>
                <th class="width-1">Отображение</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(filter, idx) in filters"
                :key="filter.id"
                :style="filter.id == rowSortMovedFilterId ? 'background: #ccc' : null"
                :draggable="true"
                @dragstart="rowSortMovedFilterId = filter.id"
                @dragenter="rowSortDragenter(filter.id)"
                @dragend="rowSortMovedFilterId = null"
              >
                <td class="text-nowrap">
                  <button
                    class="btn btn-link no-padding font-sm"
                    type="button"
                    @click="editFilter(idx)"
                  >
                    {{filter.label || '(без метки)'}}
                  </button>
                </td>
                <td class="text-nowrap">{{filter.path}}</td>
                <td class="text-nowrap">
                  <a :href="`/#dview?tab=Display&id=${filter.display.view_id}&displayId=${filter.display_id}`" target="_blank">
                    {{getDisplayText(filter.display_id)}}
                  </a>
                </td>
                <td>
                  <button
                    class="btn btn-link no-padding font-sm"
                    type="button"
                    @click="removeFilter(idx)"
                  >
                    <i class="fa fa-times"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </fieldset>
      <dview-filter-edit-modal
        v-if="showFilterEditModal"
        v-model="filters[editFilterIdx]"
        :displays="displays"
        @close="showFilterEditModal = false"
      />
    </template>
  </div>
</template>

<script>
  import DviewFilterEditModal from './DviewFilterEditModal'
  import axios from 'axios'

  export default {
    name: 'DviewFilterGroup',
    components: { DviewFilterEditModal },
    props: ['blockId'],
    data () {
      return {
        filters: [],
        displays: [],
        editFilterIdx: null,
        showFilterEditModal: false,
        rowSortMovedFilterId: null,
        loading: true
      }
    },
    watch: {
      filters () {
        this.emit()
      }
    },
    methods: {
      editFilter (idx) {
        this.editFilterIdx = Number.isInteger(idx) ? idx : this.filters.length
        this.showFilterEditModal = true
      },
      removeFilter (idx) {
        this.filters.splice(idx, 1)
      },
      emit () {
        this.$emit('input', JSON.parse(JSON.stringify(this.filters)))
      },
      getDisplayText (displayId) {
        return this.displays
          .find(display => display.id == displayId)
          .text
      },
      rowSortDragenter (enterFilterId) {
        const filters = this.filters
        const movedFilter = filters.find(filter => filter.id == this.rowSortMovedFilterId)
        const movedOrder = movedFilter.order
        const enterFilter = filters.find(filter => filter.id == enterFilterId)
        const enterOrder = enterFilter.order
        movedFilter.order = enterOrder
        enterFilter.order = movedOrder
        filters
          .sort((a, b) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          })
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getBlockTypeSettings/DviewFilterGroup`)
      this.filters = data.filters
      this.displays = data.displays.map(display => {
        return {
          id: display.id,
          text: display.label
        }
      })
      this.loading = false
      this.$emit('loaded')
      this.emit()
    }
  }
</script>

<style scoped>
  .add-filter-btn {
    margin-bottom: 3px;
    margin-left: 10px;
  }
  .table td {
    vertical-align: middle;
  }
</style>