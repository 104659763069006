<template>
  <modal name="form" @closed="$emit('close')" height="auto">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          <strong>Выбрать материал</strong>
        </h4>
        <hr/>
        <section>
          <label class="label">Материал</label>
          <label class="input">
            <xselect2
              label="Выберите материал"
              v-model="content"
              tk="title"
              src="content/selectContents"
              width="100%"
            />
          </label>
        </section>
        <hr/>
        <div class="pull-right">
          <button
            :disabled="!content.id"
            class="btn btn-link"
            @click="apply"
          >
            Применить
          </button>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  export default {
    name: 'ContentEditModal',
    props: ['value'],
    data () {
      const value = this.value
      const content = value
        ? {id: value.id, title: value.title}
        : {id: null, title: 'Выберите материал'}
      return {
        content
      }
    },
    methods: {
      apply () {
        if (this.content.id) {
          this.$emit('input', this.content)
        }
        this.close()
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>