<template>
  <block-regions-list
    :items="value ? value.split(',') : []"
    class="smart-form"
  />
</template>

<script>
  import BlockRegionsList from '../BlockRegionsList'

  export default {
    name: 'Regions',
    components: { BlockRegionsList },
    props: ['row', 'value']
  }
</script>