<template>
  <modal name="form" @closed="$emit('close')" width="900" height="auto" :scrollable="true">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          Клонировать отображение - {{form.label}} ({{form.name}})
        </h4>
        <hr/>
        <div class="row">
          <section class="col col-6">
            <section>
              <header>Настройки отображения</header>
            </section>
            <section>
              <label class="label">Метка</label>
              <label class="input">
                <input
                  v-model="form.label"
                  type="text"
                  placeholder="Метка"
                />
              </label>
            </section>
            <section>
              <label class="label">Машинное имя</label>
              <label class="input">
                <input
                  v-model="form.name"
                  type="text"
                  placeholder="Машинное имя"
                />
              </label>
            </section>
          </section>
          <section class="col col-6">
            <section>
              <header>Представления для клонирования</header>
            </section>
            <div v-if="loading">Загрузка...</div>
            <template v-else>
              <label class="checkbox">
                <input
                  v-model="form.dviews_ids"
                  type="checkbox"
                  :value="currentView.id"
                />
                <i></i> {{currentView.label}} <span class="text-muted">(Текущее представление)</span>
              </label>
              <label
                v-for="view in otherViews"
                :key="view.id"
                class="checkbox"
              >
                <input
                  v-model="form.dviews_ids"
                  type="checkbox"
                  :value="view.id"
                />
                <i></i> {{view.label}}
              </label>
            </template>
          </section>
        </div>
        <hr/>
        <div class="pull-right">
          <submit @click="submit" :processing="processing" class="btn btn-link">
            Выполнить
          </submit>
          <button @click="close" type="button" class="btn btn-link">Закрыть</button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DisplayCloneForm',
    data () {
      const displayForm = this.$store.state.display.form
      return {
        form: {
          id: displayForm.id,
          name: displayForm.name,
          label: displayForm.label,
          viewId: displayForm.viewId,
          dviews_ids: [displayForm.viewId]
        },
        dviews: [],
        loading: true,
        processing: false
      }
    },
    computed: {
      currentView () {
        return this.dviews.find(dview => dview.id == this.form.viewId)
      },
      otherViews () {
        return this.dviews.filter(dview => dview.id != this.form.viewId)
      }
    },
    methods: {
      submit () {
        this.processing = true
        const {form} = this
        axios.post(`dview/display/${form.id}/clone`, form)
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
      axios.get('dview/getDviews')
        .then(({data}) => {
          this.dviews = data
          this.loading = false
        })
    }
  }
</script>