<template>
  <div>
    <button-menu
      title="Управление"
      icon="fa fa-edit"
      btn-class="btn btn-default btn-xs"
      style="margin-bottom: 5px;"
      :items="{addField: 'Добавить поле'}"
      @addField="showAddFieldForm = true"
    />
    <div class="field-box">
      <ol>
        <li
          v-for="(displayField, idx) in form.displayFields"
          :key="displayField.id"
          :draggable="true"
          @dragstart="rowSortMovedFieldId = displayField.id"
          @dragenter="rowSortDragenter(displayField.id)"
          @dragend="rowSortDragend"
        >
          <button
            class="btn btn-link no-padding"
            type="button"
            @click="editIdx = idx"
          >
            {{displayField.label || displayField.field.label}} ({{displayField.name}})
          </button>
        </li>
      </ol>
    </div>
    <display-field-create-form
      v-if="showAddFieldForm"
      @created="addField"
      @close="showAddFieldForm = false"
    />
    <display-field-edit-form
      v-if="isEditable"
      v-model="form.displayFields[editIdx]"
      @deleted="removeField"
      @close="editIdx = null"
    />
  </div>
</template>

<script>
  import DisplayFieldCreateForm from './DisplayFieldCreateForm'
  import DisplayFieldEditForm from './DisplayFieldEditForm'
  import axios from 'axios'

  export default {
    name: 'DisplayFields',
    components: {
      DisplayFieldCreateForm,
      DisplayFieldEditForm
    },
    data () {
      const displayFields = JSON.parse(JSON.stringify(this.$store.state.display.form.displayFields)).map((field, idx) => {
        field.order = idx
        return field
      })
      return {
        form: {
          displayFields
        },
        showAddFieldForm: false,
        editIdx: null,
        rowSortMovedFieldId: null,
      }
    },
    computed: {
      isEditable () {
        return this.editIdx != null
      },
      displayId () {
        return this.$store.state.display.form.id
      }
    },
    watch: {
      'form.displayFields': {
        deep: true,
        handler (displayFields) {
          this.$store.commit('display/updateFormDisplayFields', displayFields)
        }
      }
    },
    methods: {
      addField (displayField) {
        this.form.displayFields.push(displayField)
      },
      removeField (id) {
        const idx = this.form.displayFields.findIndex(item => item.id == id)
        this.form.displayFields.splice(idx, 1)
      },
      rowSortDragenter (enterFieldId) {
        const fields = this.form.displayFields
        const movedField = fields.find(field => field.id == this.rowSortMovedFieldId)
        const movedOrder = movedField.order
        const enterField = fields.find(field => field.id == enterFieldId)
        const enterOrder = enterField.order
        movedField.order = enterOrder
        enterField.order = movedOrder
        fields
          .sort((a, b) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          })
      },
      async rowSortDragend () {
        this.rowSortMovedFieldId = null
        const params = {
          ids: this.form.displayFields.map(field => field.id),
          displayId: this.displayId
        }
        await axios.post('dview/display/field/sort', params)
        smallBoxSuccess('Выполнено.')
      }
    }
  }
</script>

<style scoped>
  .field-box ol{
    padding-left: 25px
  }
  .field-box ol button {
    margin-top: -3px
  }
</style>