<template>
  <modal name="form" @closed="$emit('close')" height="auto">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>{{removing ? 'Удалить' : 'Установить'}} взаимосвязь</h4>
        <hr/>
        <section>
          <div v-if="!removing">
            <label class="label">
              Взаимосвязь
              <button
                v-if="display.form.interrelationId"
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="removing = true"
              >
                Удалить
              </button>
            </label>
            <xselect2
              v-model="form"
              label="Выбор взаимосвязи"
              tk="label"
              :src="interrelations"
              width="100%"
            />
            <div class="note">
              Применяется для вывода взаимосвязанных материалов в контексте текущего материала.
            </div>
          </div>
          <div v-if="!display.form.interrelationId" class="text-muted" style="margin-top: 10px;">
            Взаимосвязь ещё не установлена.
          </div>
          <div v-else-if="removing">
            <div>
              Вы хотите удалить взаимосвязь: {{current.label}}?
            </div>
            <button
              class="btn btn-link font-sm no-padding"
              type="button"
              @click="removing = false"
            >
              Отмена
            </button>
          </div>
        </section>
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DisplayInterrelationEditForm',
    data () {
      const displayForm = this.$store.state.display.form
      const interrelation = this.$store.state.display.interrelations
        .find(item => item.id == displayForm.interrelationId)
      const form = {
        id: displayForm.interrelationId,
        label: interrelation ? interrelation.label : ''
      }
      return {
        form,
        current: JSON.parse(JSON.stringify(form)),
        removing: false,
        processing: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      interrelations () {
        return this.display.interrelations.map(item => {
          return {
            id: item.id,
            text: `${item.label} (${item.name})`
          }
        })
      }
    },
    methods: {
      submit () {
        this.processing = true
        this.form.id = this.removing ? null : this.form.id
        const params = {interrelationId: this.form.id}
        axios.post(`dview/display/${this.display.form.id}/filter/interrelation/edit`, params)
          .then(() => {
            smallBoxSuccess('Выполнено.')
            this.$store.commit('display/updateFormInterrelationId', this.form.id)
            this.close()
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>