<template>
  <div>
    <div v-if="loading" class="well well-sm text-muted">Подготовка...</div>
    <div v-else class="smart-form well no-padding">
      <fieldset>
        <div class="row">
          <section class="col col-6">
            <section>
              <header>Основные настройки</header>
            </section>
            <section>
              <label class="label">Название типа материала</label>
              <label class="input">
                <input v-model="form.label" placeholder="Название типа материала" type="text"/>
              </label>
              <div class="note">Название типа материала</div>
            </section>

            <section>
              <label class="label">Метка типа материала (используется на сайте)</label>
              <label class="input">
                <input v-model="form.site_label" placeholder="Метка типа материала (используется на сайте)" type="text"/>
              </label>
              <div class="note">Метка типа материала (используется на сайте)</div>
            </section>

            <section>
              <label class="label">Машинное имя</label>
              <label class="input">
                <input v-model="form.name" placeholder="Машинное имя" type="text"/>
              </label>
              <div class="note">Машинное имя</div>
            </section>

            <section>
              <label class="label">Описание типа материала</label>
              <label class="textarea">
                <textarea
                  v-model="form.description"
                  rows="5"
                  cols="50"
                  placeholder="Описание"
                ></textarea>
              </label>
              <div class="note">Описание типа материала.</div>
            </section>
          </section>
          <section class="col col-6">
            <section>
              <header>Дополнительные настройки</header>
            </section>
            <section>
              <label class="label">Тип страницы</label>
              <label class="select">
                <select v-model="form.page_type">
                  <option :value="null">Не задано</option>
                  <option v-for="item in pageTypes" :value="item.name">{{item.title}}</option>
                </select>
                <i></i>
              </label>
              <div class="note">Тип страницы</div>
            </section>
            <section>
              <label class="label">Класс материала</label>
              <label class="select">
                <select v-model="form.content_class_id">
                  <option :value="null">Не задано</option>
                  <option v-for="item in contentClasses" :value="item.id">{{item.label}}</option>
                </select>
                <i></i>
              </label>
              <div class="note">Класс материала</div>
            </section>
            <section>
              <label class="label">Частота обновления материалов по умолчанию</label>
              <label class="select">
                <select v-model="form.changefreq">
                  <option :value="null" selected disabled>Не задано</option>
                  <option v-for="(label, value) in changefreqOptions" :value="value">{{label}}</option>
                </select>
                <i></i>
              </label>
            </section>
            <section>
              <label class="label">Приоритетность URL по умолчанию</label>
              <label class="select">
                <select v-model="form.priority">
                  <option :value="null" selected disabled>Не задано</option>
                  <option v-for="(label, value) in priorityOptions" :value="value">{{label}}</option>
                </select>
                <i></i>
              </label>
            </section>
          </section>
        </div>
      </fieldset>
      <footer>
        <submit @click="submit" :processing="processing" class="btn btn-primary" icon="fa fa-save">
          Сохранить
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'ContentTypeForm',
    props: ['contentTypeId'],
    data () {
      return {
        form: {
          name: null,
          label: null,
          site_label: null,
          description: null,
          content_class_id: null,
          changefreq: null,
          priority: null,
          page_type: null
        },
        contentClasses: [],
        changefreqOptions: [],
        priorityOptions: [],
        processing: false,
        loading: true
      }
    },
    computed: {
      isCreate () {
        return !this.contentTypeId
      },
      pageTypes () {
        return this.$store.state.common.template.pageTypes.filter(item => item.selectable)
      }
    },
    methods: {
      submit () {
        this.processing = true
        const url = this.contentTypeId ? `contentType/${this.contentTypeId}/edit` : 'contentType/create'
        axios.post(url, this.form)
          .then(({data}) => {
            if (this.isCreate) {
              this.$emit('created', data.id)
            }
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async created () {
      await this.$store.dispatch('common/initTemplate')
      const {data} = await axios.get('contentType/getOptions')
      this.contentClasses = data.contentClasses
      this.changefreqOptions = data.changefreqOptions
      this.priorityOptions = data.priorityOptions
      if (!this.isCreate) {
        const {data} = await axios.get(`contentType/${this.contentTypeId}/get`)
        this.form = data.contentType
      }
      this.loading = false
    }
  }
</script>