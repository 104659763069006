<template>
  <modal name="form" @closed="$emit('close')" height="auto" width="1000" :scrollable="true">
    <div class="no-margin-bottom" style="height: 100%">
      <div>
        <div class="well">
          <h4>
            <strong>Создать поле</strong>
          </h4>
        </div>
        <field-create-form
          @created="$emit('created', $event)"
        />
      </div>
    </div>
  </modal>
</template>

<script>
  import FieldCreateForm from '../../fields/FieldEditForm'

  export default {
    components: { FieldCreateForm },
    methods: {
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>
