<template>
  <div>
    <div class="row" style="margin-bottom: 10px;">
      <div class="col col-sm-3">
        <div class="inline-group">
          <label class="label">
            Тип фильтра
          </label>
          <label class="radio">
            <input
              v-model="input"
              value="checkbox"
              type="radio"
            />
            <i></i> Чекбокс
          </label>
          <label class="radio">
            <input
              v-model="input"
              value="range"
              type="radio"
            />
            <i></i> Диапазон
          </label>
        </div>
      </div>
      <div class="col col-sm-3">
        <label class="label">
          Исключающий фильтр
        </label>
        <div class="smart-form">
          <label class="checkbox">
            <input v-model="isExclusion" type="checkbox">
            <i></i> Исключающий фильтр
          </label>
        </div>
      </div>
      <div class="col col-sm-3">
        <template v-if="input == 'checkbox'">
          <label class="label">
            Открытый по умолчанию
          </label>
          <div class="smart-form">
            <label class="checkbox">
              <input v-model="isOpened" type="checkbox">
              <i></i> Открытый по умолчанию
            </label>
          </div>
        </template>
      </div>
      <div class="col col-sm-3">
        <template v-if="input == 'checkbox'">
          <label class="label">
            Показать в две колонки
          </label>
          <div class="smart-form">
            <label class="checkbox">
              <input v-model="isTwoColumns" type="checkbox">
              <i></i> Показать в две колонки
            </label>
          </div>
        </template>
      </div>
    </div>
    <expand-range-item
      v-if="form.input == 'range'"
    />
    <template v-else>
      <div class="row" style="margin-bottom: 12px;">
        <div class="col col-sm-3">
          <label class="label">
            Шаблон метки раскрытого фильтра
          </label>
          <label class="input">
            <input
              v-model="expandLabel"
              placeholder="Шаблон метки раскрытого фильтра"
              type="text"
            />
          </label>
          <div class="note">
            Используйте подстановочный шаблон <strong>[value]</strong> для вывода значения раскрытого фильтра.
          </div>
        </div>
        <div class="col col-sm-9">
          <label class="label">
            Чекбоксы, которые требуется исключить из фильтра
          </label>
          <chips v-model="expandIgnore" placeholder="Введите значения"/>
          <div class="note">
            Введите значение и нажмите клавишу "ENTER"
          </div>
        </div>
      </div>
      <expand-multiple-items
        v-model="expandItems"
      />
    </template>
  </div>
</template>

<script>
  import ExpandMultipleItems from './ExpandMultipleItems'
  import ExpandRangeItem from './ExpandRangeItem'

  export default {
    name: 'ExpandItems',
    components: {
      ExpandMultipleItems,
      ExpandRangeItem
    },
    computed: {
      form () {
        return JSON.parse(JSON.stringify(this.$store.state.display.filterCriteriaForm))
      },
      input: {
        get () {
          return this.form.input
        },
        set (value) {
          const {form} = this
          form.input = value
          this.updateForm(form)
        }
      },
      isExclusion: {
        get () {
          return this.form.isExclusion
        },
        set (value) {
          const {form} = this
          form.isExclusion = value
          this.updateForm(form)
        }
      },
      isOpened: {
        get () {
          return this.form.isOpened
        },
        set (value) {
          const {form} = this
          form.isOpened = value
          this.updateForm(form)
        }
      },
      isTwoColumns: {
        get () {
          return this.form.isTwoColumns
        },
        set (value) {
          const {form} = this
          form.isTwoColumns = value
          this.updateForm(form)
        }
      },
      expandItems: {
        get () {
          return this.form.criteria_expands
        },
        set (value) {
          const {form} = this
          form.criteria_expands = value
          this.updateForm(form)
        }
      },
      expandLabel: {
        get () {
          return this.form.expand_label
        },
        set (value) {
          const {form} = this
          form.expand_label = value
          this.updateForm(form)
        },
      },
      expandIgnore: {
        get () {
          return this.form.expand_ignore
        },
        set (items) {
          const {form} = this
          form.expand_ignore = items
          this.updateForm(form)
        }
      }
    },
    methods: {
      updateForm (form) {
        this.$store.commit('display/setFilterCriteriaForm', form)
      }
    }
  }
</script>