<template>
  <div style="position: relative">
    <interrelation-edit-form
      v-if="activeTab == 'InterrelationCreate'"
      @created="setEditTab"
    />
    <interrelation-export
      v-else-if="activeTab == 'InterrelationExport'"
    />
    <interrelation-import
      v-else-if="activeTab == 'InterrelationImport'"
    />
    <div v-else-if="activeTab">
      <div class="tabs-body">
        <ul class="nav nav-tabs">
          <li
            v-for="tab in tabs"
            :class="{active: tab.name == activeTab}"
            :key="tab.name"
          >
            <a @click.prevent="setActiveTab(tab)" href="#">{{tab.title}}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active">
            <component
              :is="activeTab"
              :interrelation-id="interrelationId"
              @deleted="handleDeleted"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <button
        type="button"
        class="btn btn-link no-padding font-xs interrelation-top-btn interrelation-create-btn"
        @click="setCreateTab"
      >
        Создать взаимосвязь
      </button>
      <button
        type="button"
        class="btn btn-link no-padding font-xs interrelation-top-btn interrelation-export-btn"
        @click="setExportTab"
      >
        Экспорт взаимосвязей
      </button>
      <button
        type="button"
        class="btn btn-link no-padding font-xs interrelation-top-btn interrelation-import-btn"
        @click="setImportTab"
      >
        Импорт взаимосвязей
      </button>
      <datatable
        :columns="columns"
        :options="{tableClass: 'table-bordered'}"
        src="interrelation/list"
        @setEditTab="setEditTab"
      />
    </div>
  </div>
</template>

<script>
  import InterrelationEditForm from './InterrelationEditForm'
  import InterrelationDeleteForm from './InterrelationDeleteForm'
  import InterrelationExport from './InterrelationExport'
  import InterrelationImport from './InterrelationImport'
  import TdName from './td/Name'

  export default {
    name: 'Interrelations',
    components: {
      InterrelationEditForm,
      InterrelationDeleteForm,
      InterrelationExport,
      InterrelationImport
    },
    data () {
      return {
        activeTab: _hash_struct.tab,
        interrelationId: _hash_struct.query.id,
        tabs: [
          {title: 'Настроить взаимосвязь', name: 'InterrelationEditForm'},
          {title: 'Удалить взаимосвязь', name: 'InterrelationDeleteForm'}
        ]
      }
    },
    computed: {
      columns () {
        return [
          {
            title: 'Наименование',
            field: 'name',
            filter: {type: 'text', width: '100%'},
            component: TdName,
            tdClass: 'text-nowrap'
          },
          {
            title: 'Кол-во связей',
            field: 'count'
          },
          {
            title: 'Описание',
            field: 'description',
            filter: {type: 'text', width: '100%'}
          }
        ]
      }
    },
    methods: {
      setCreateTab () {
        this.setActiveTab({name: 'InterrelationCreate'})
      },
      setEditTab (id) {
        this.interrelationId = id
        this.setActiveTab({name: 'InterrelationEditForm'})
      },
      setExportTab () {
        this.setActiveTab({name: 'InterrelationExport'})
      },
      setImportTab () {
        this.setActiveTab({name: 'InterrelationImport'})
      },
      setActiveTab (tab) {
        this.activeTab = tab.name
        this.setTab(tab.name)
      },
      setTab (tab) {
        CHECK_URL_ON_HASH_CHANGE = false;
        const query = []
        if (tab) {
          query.push(`tab=${tab}`)
        }
        if (this.interrelationId) {
          query.push(`id=${this.interrelationId}`)
        }
        window.location.hash = 'interrelation' + (query.length ? `?${query.join('&')}` : '')
      },
      handleDeleted () {
        this.interrelationId = null
        this.activeTab = null
        this.setTab()
      }
    }
  }
</script>

<style scoped>
  .interrelation-top-btn {
    position: absolute;
    top: -22px;
  }
  .interrelation-create-btn {
    left: 47px;
  }
  .interrelation-export-btn {
    left: 180px;
  }
  .interrelation-import-btn {
    left: 320px;
  }
</style>