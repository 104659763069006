<template>
  <button
    class="btn btn-link no-padding font-sm"
    type="button"
    @click="$parent.$emit('edit', row.id)"
  >
    {{field.label}} ({{field.name}})
  </button>
</template>

<script>
  export default {
    name: 'Field',
    props: ['value', 'row'],
    computed: {
      fields () {
        return this.$store.state.display.fields
      },
      field () {
        return this.fields.find(field => field.id == this.value)
      }
    }
  }
</script>