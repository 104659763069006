<template>
  <modal name="form" @closed="$emit('close')" :scrollable="true" height="auto" width="600">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          {{removing ? 'Удалить' : (isCreate ? 'Создать' : 'Редактировать')}} стиль отображения
        </h4>
        <hr/>
        <section v-if="removing">
          <div>
            Вы хотите удалить стиль отображения "{{styleLabel}}"?
          </div>
          <button
            class="btn btn-link font-sm no-padding"
            type="button"
            @click="removing = false"
          >
            Отмена
          </button>
        </section>
        <section v-else>
          <section>
            <label class="label">
              Стиль отображения
            </label>
            <label class="select">
              <select v-model="form.style">
                <option :value="null" selected disabled>Выберите стиль отображения</option>
                <option
                  v-for="style in styles"
                  :key="style"
                  :value="style"
                >
                  {{getStyleLabel(style)}}
                </option>
              </select>
              <i></i>
            </label>
          </section>
          <div v-if="display.form.displayFields.length">
            <label class="label">
              Поля стиля отображения
              <button
                class="btn btn-link no-padding font-sm"
                type="button"
                @click="toggleAll"
              >
                <span v-if="isAllCheckedFields">
                  Снять все
                </span>
                <span v-else>
                  Отметить все
                </span>
              </button>
            </label>
          </div>
          <div v-else class="text-danger">
            Поля отображения не доступны.
          </div>
          <ol v-if="form.fields.length">
            <li
              v-for="(displayStyleField, idx) in form.fields"
              :key="displayStyleField.id"
              :style="displayStyleField.id == rowSortMovedFieldId ? 'background: #ccc' : null"
              :draggable="true"
              @dragstart="rowSortMovedFieldId = displayStyleField.id"
              @dragenter="rowSortDragenter(displayStyleField.id)"
              @dragend="rowSortMovedFieldId = null"
            >
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked
                    @change="form.fields.splice(idx, 1)"
                  />
                  <button
                    class="btn btn-link no-padding"
                    type="button"
                    @click="showFieldEditForm(displayStyleField.display_field_id)"
                  >
                    {{
                      displayStyleField.label ||
                      displayStyleField.display_field.label ||
                      displayStyleField.display_field.field.label
                    }} ({{displayStyleField.display_field.field.name}})
                  </button>
                </label>
              </div>
            </li>
          </ol>
          <div v-else-if="displayFields.length">
            Отметьте поля из списка.
          </div>
          <div v-if="displayFields.length">
            <hr/>
            <label class="label">Выбор полей</label>
            <ol>
              <li
                v-for="displayField in displayFields"
                :key="displayField.id"
              >
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      @change="addDisplayStyleField(displayField)"
                    />
                    {{displayField.label || displayField.field.label}} ({{displayField.name}})
                  </label>
                </div>
              </li>
            </ol>
          </div>
        </section>
        <hr/>
        <div class="pull-right">
          <submit
            v-if="!isCreate"
            :processing="processing"
            class="btn btn-link"
            @click="remove"
          >
            <span :class="{'text-danger': removing}">
              Удалить
            </span>
          </submit>
          <submit
            v-if="!removing"
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <display-style-field-edit-form
      v-if="isEditable"
      v-model="form.fields[editIdx]"
      @close="editIdx = null"
    />
  </modal>
</template>

<script>
  import DisplayStyleFieldEditForm from './DisplayStyleFieldEditForm'
  import _uniqueId from 'lodash/uniqueId'
  import axios from 'axios'

  export default {
    name: 'DisplayStyleEditForm',
    props: ['value'],
    components: { DisplayStyleFieldEditForm },
    data () {
      const display = this.$store.state.display
      const existsDisplayStyles = display.form.displayStyles.map(item => item.style)
      let form = this.value ? JSON.parse(JSON.stringify(this.value)) : null
      const isCreate = !form
      if (isCreate) {
        form = {
          id: -_uniqueId(),
          display_id: display.form.id,
          style: null,
          fields: []
        }
      }
      const styles = ['flip', 'grid', 'list'].filter(style => {
        return style == form.style || !existsDisplayStyles.includes(style)
      })
      return {
        form,
        isCreate,
        styles,
        rowSortMovedFieldId: null,
        editIdx: null,
        removing: false,
        processing: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      displayFields () {
        const ids = this.form.fields.map(item => item.display_field_id)
        return this.display.form.displayFields.filter(displayField => {
          return !ids.includes(displayField.id)
        })
      },
      isAllCheckedFields () {
        return this.form.fields.length == this.display.form.displayFields.length
      },
      isEditable () {
        return this.editIdx != null
      },
      styleLabel () {
        return this.getStyleLabel(this.form.style)
      }
    },
    methods: {
      rowSortDragenter (enterFieldId) {
        const fields = this.form.fields
        const movedField = fields.find(field => field.id == this.rowSortMovedFieldId)
        const movedOrder = movedField.order
        const enterField = fields.find(field => field.id == enterFieldId)
        const enterOrder = enterField.order
        movedField.order = enterOrder
        enterField.order = movedOrder
        fields
          .sort((a, b) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          })
      },
      submit () {
        if (!this.form.style) {
          smallBoxErrors('Выберите стиль отображения.')
          return
        }

        this.processing = true
        const url = `dview/display/style/${this.isCreate ? 'create' : `${this.form.id}/edit`}`
        axios.post(url, this.form)
          .then(({data}) => {
            const displayStyles = JSON.parse(JSON.stringify(this.display.form.displayStyles))
            if (this.isCreate) {
              displayStyles.push(data)
            } else {
              const idx = displayStyles.findIndex(item => item.id == this.form.id)
              displayStyles.splice(idx, 1, data)
            }
            this.updateDisplayStyles(displayStyles)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      remove () {
        if (!this.removing) {
          this.removing = true
          return
        }

        this.processing = true
        axios.post(`dview/display/style/${this.form.id}/delete`)
          .then(() => {
            const displayStyles = JSON.parse(JSON.stringify(this.display.form.displayStyles))
            const idx = displayStyles.findIndex(item => item.id == this.form.id)
            displayStyles.splice(idx, 1)
            this.updateDisplayStyles(displayStyles)
            this.close()
            smallBoxSuccess('Стиль отображения удален.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      toggleAll () {
        if (this.isAllCheckedFields) {
          this.form.fields = []
        } else {
          const ids = this.form.fields.map(item => item.display_field_id)
          this.display.form.displayFields
            .filter(displayField => !ids.includes(displayField.id))
            .forEach(displayField => {
              this.addDisplayStyleField(displayField)
            })
        }
      },
      showFieldEditForm (displayFieldId) {
        this.editIdx = this.form.fields.findIndex(item => item.display_field_id == displayFieldId)
      },
      addDisplayStyleField (displayField) {
        const displayStyleField = {
          id: -_uniqueId(),
          display_style_id: this.form.id,
          display_field_id: displayField.id,
          order: displayField.order,
          label: displayField.label,
          colon: displayField.colon,
          round: displayField.round,
          dec_point: displayField.dec_point,
          thousand: displayField.thousand,
          convert: displayField.convert,
          dateformat: displayField.dateformat,
          prefix: displayField.prefix,
          prefix_space: displayField.prefix_space,
          suffix: displayField.suffix,
          suffix_space: displayField.suffix_space,
          empty_text: displayField.empty_text,
          empty_zero: displayField.empty_zero,
          empty_hide: displayField.empty_hide,
          alter_text: displayField.alter_text,
          alter_nl2br: displayField.alter_nl2br,
          alter_link: displayField.alter_link,
          alter_link_href: displayField.alter_link_href,
          alter_link_text: displayField.alter_link_text,
          alter_link_class: displayField.alter_link_class,
          alter_link_class_active: displayField.alter_link_class_active,
          alter_link_title: displayField.alter_link_title,
          alter_link_rel: displayField.alter_link_rel,
          alter_link_target: displayField.alter_link_target,
          list_min_max: displayField.list_min_max,
          list_limit: displayField.list_limit,
          list_wrap: displayField.list_wrap,
          list_wrap_class: displayField.list_wrap_class,
          list_delimiter: displayField.list_delimiter,
          hidden: displayField.hidden,
          image_thumb: displayField.image_thumb,
          display_field: displayField
        }
        this.form.fields.push(displayStyleField)
      },
      updateDisplayStyles (displayStyles) {
        this.$store.commit('display/updateFormDisplayStyles', displayStyles)
      },
      getStyleLabel (style) {
        switch (style) {
          case 'flip': return 'Таблица (Flip)'
          case 'grid': return 'Сетка'
          case 'list': return 'Список'
          default: return 'Неизвестный стиль отображения'
        }
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>

<style scoped>
  .checkbox input[type=checkbox] {
    position: initial;
    margin-left: -40px;
  }

  ol {
    margin-left: 20px;
  }
</style>