<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <template v-else>
      <fieldset>
        <section>
          <header>Настройка содержимого блока хлебных крошек</header>
        </section>
        <section>
          <label class="label">Выбор взаимосвязи</label>
          <label class="select">
            <select v-model="form.interrelationId" @change="emit">
              <option :value="null">Нет</option>
              <option v-for="item in interrelations" :value="item.id">{{item.name}} ({{item.label}})</option>
            </select>
            <i></i>
          </label>
          <div class="note">
            <p>Применяется для вывода ряда ссылок на связанные материалы в контексте родительской/дочерней страницы.</p>
            <p>Примечание: Оставьте это поле пустым, если не нужно выводить взаимосвязи.</p>
          </div>
        </section>
        <section>
          <header>Настройка вывода смежных крошек</header>
          <div class="note">
            <p>Укажите количество колонок для выпадающего списка смежных крошек.</p>
            <p>Количество можно задать отдельно для каждой крошки.</p>
          </div>
        </section>
        <label class="label">Количество колонок по умолчанию</label>
        <div class="row">
          <section class="col col-3">
            <label class="input">
              <input
                v-model="form.colsSetDefault"
                type="text"
                placeholder="Количество колонок"
                @input="emit"
              />
            </label>
            <div class="note">Количество колонок по умолчанию</div>
          </section>
        </div>
        <section>
          <label class="checkbox">
            <input
              v-model="form.colsSplit"
              type="checkbox"
              @change="emit"
            />
            <i></i> Количество колонок отдельно для каждой крошки
          </label>
        </section>
        <section v-if="form.colsSplit">
          <div class="list-values">
            <div v-for="(item, idx) in form.colsSet" class="row">
              <section class="col col-3">
                <label class="input">
                  <i
                    class="icon-prepend"
                  >
                    #{{idx + 1}}
                  </i>
                  <i
                    class="icon-append fa fa-times"
                    @click="removeValue(idx)"
                  ></i>
                  <input
                    v-model="form.colsSet[idx]"
                    type="text"
                    placeholder="Количество колонок"
                    @input="emit"
                  />
                  <i
                    v-if="form.colsSet.length == idx + 1"
                    class="fa fa-lg fa-fw fa-plus-circle txt-color-green"
                    @click="pushValue"
                  ></i>
                </label>
              </section>
            </div>
          </div>
        </section>
      </fieldset>
    </template>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Breadcrumbs',
    props: ['blockId'],
    data () {
      return {
        form: {
          colsSet: [],
          colsSetDefault: null,
          colsSplit: null,
          interrelationId: null
        },
        interrelations: [],
        loading: true
      }
    },
    methods: {
      removeValue (idx) {
        this.form.colsSet.splice(idx, 1)
        this.emit()
        if (!this.form.colsSet.length) {
          this.form.colsSet.push(1)
        }
      },
      pushValue () {
        this.form.colsSet.push(1)
        this.emit()
      },
      emit () {
        this.$emit('input', JSON.parse(JSON.stringify(this.form)))
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getBlockTypeSettings/Breadcrumbs`)
      this.form.colsSet = data.colsSet || []
      this.form.colsSetDefault = data.colsSetDefault
      this.form.colsSplit = data.colsSplit
      this.form.interrelationId = data.interrelationId
      this.interrelations = data.interrelations
      this.loading = false
      this.$emit('loaded')
      this.emit()
    }
  }
</script>

<style scoped>
  .list-values .fa-plus-circle {
    position: absolute;
    top: 0;
    right: -33px;
    cursor: pointer;
    padding: 10px 5px;
  }
  .list-values .fa-times {
    cursor: pointer;
  }
  .list-values .fa-times:hover {
    color: red;
  }
  .list-values .icon-prepend {
    font-size: 90%;
  }
  .list-values section:first-child {
    padding-right: 3px;
  }
  .list-values-labels section {
    margin-bottom: 0
  }
  .list-values-analize ul {
    margin-left: 20px
  }
</style>