<template>
  <div>
    <div v-if="loading" class="well well-sm text-muted">Подготовка...</div>
    <div v-else class="smart-form well no-padding">
      <fieldset>
        <div class="row">
          <section class="col col-4">
            <section>
              <header>Основные настройки</header>
            </section>
            <section>
              <label class="input">
                <input v-model="form.label" placeholder="Имя поля" type="text"/>
              </label>
              <div class="note">Имя поля.</div>
            </section>
            <section>
              <label class="input">
                <input v-model="form.name" placeholder="Машинное имя" type="text"/>
              </label>
              <div class="note">Машинное имя. Допускаются символы латиницы и подчеркивание.</div>
            </section>
            <section>
              <label class="textarea">
                <label class="textarea">
                <textarea
                  v-model="form.description"
                  rows="5"
                  placeholder="Описание поля"
                ></textarea>
                </label>
              </label>
              <div class="note">Описание поля по использованию.</div>
            </section>
          </section>
          <section class="col col-8">
            <section>
              <header>Тип поля</header>
            </section>
            <template v-if="isCreate || isStatical">
              <section>
                <label class="select" :class="{'state-disabled': isUsed}">
                  <select v-model="form.type" :disabled="isUsed" :class="{'text-muted': isUsed}">
                    <option :value="null" disabled selected>Выберите тип из списка</option>
                    <option
                      v-for="item in options.staticalTypes"
                      :key="item.type"
                      :value="item.type"
                    >
                      {{item.label}}
                    </option>
                  </select>
                  <i></i>
                </label>
                <div class="note">Тип поля.</div>
              </section>
              <field-settings
                v-if="isSettingsAble"
                v-model="form"
                :options="options"
                :used-info="usedInfo"
              />
            </template>
            <section v-else>
              <label class="input state-disabled">
                <input :value="`System::${form.type}`" disabled>
              </label>
              <div class="note">Тип поля (complex).</div>
            </section>
          </section>
        </div>
      </fieldset>
      <footer>
        <submit @click="submit" :processing="processing" class="btn btn-primary" icon="fa fa-save">
          Сохранить
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import FieldSettings from './settings/FieldSettings'
  import axios from 'axios'

  export default {
    name: 'FieldEditForm',
    components: { FieldSettings },
    props: ['fieldId'],
    data () {
      return {
        form: {
          label: null,
          name: null,
          description: null,
          type: null,
          defaultValue: null,
          listDelimiter: 'nothing',
          inputType: 'input',
          availableValues: []
        },
        usedInfo: {
          expandFilters: 0,
          contents: 0
        },
        isStatical: true,
        options: {
          staticalTypes: []
        },
        processing: false,
        loading: true
      }
    },
    computed: {
      isCreate () {
        return !this.fieldId
      },
      isSettingsAble () {
        return ['integer', 'double', 'decimal', 'varchar', 'date'].includes(this.form.type)
      },
      isUsed () {
        return !!this.usedInfo.contents
      }
    },
    methods: {
      submit () {
        this.processing = true
        const url = this.isCreate ? 'field/create' : `field/${this.fieldId}/edit`
        axios.post(url, this.form)
          .then(({data}) => {
            if (this.isCreate) {
              this.$emit('created', data.id)
            }
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async created () {
      const {data} = await axios.get('field/getOptions')
      this.options = data
      if (!this.isCreate) {
        const {data} = await axios.get(`field/${this.fieldId}/get`)
        this.form = data.field
        this.usedInfo = data.usedInfo
        this.isStatical = data.isStatical
      }
      this.loading = false
    }
  }
</script>