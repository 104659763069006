<template>
  <modal name="form" @closed="$emit('close')" @opened="openedHandler" height="auto" width="800" :scrollable="true">
    <div class="well no-margin-bottom" style="height: 100%">
      <h4>
        <strong>Связь с материалами</strong>
        <div><small>Файлы для связи с материалами: {{paths.length}} шт.</small></div>
      </h4>
      <hr/>
      <label class="font-md">Поиск материалов</label>
      <input v-model="filter.content" @input="applyFilter('content')" type="text" class="form-control input-lg" placeholder="Введите заголовок или URL материала"/>
      <div class="help-block font-xs">Поиск по заголовку или URL материала.</div>
      <table class="actions">
        <tbody>
          <tr>
            <td class="width-1 text-nowrap">
              <div>Материалы: {{batched.length}}</div>
              <div>Файлы: {{paths.length}}</div>
            </td>
            <td class="width-1">
              <a v-if="paths.length" @click.prevent="bind" href="#" class="btn btn-link" :disabled="!batched.length">
                <i class="fa fa-check-circle"/> Связать
              </a>
            </td>
            <td>
              <a @click.prevent="unbind" href="#" class="btn btn-link" :disabled="!batched.length">
                <i class="fa fa-minus-circle"/> Отвязать
              </a>
              <div v-if="!paths.length" class="text-danger">
                <i class="fa fa-warning"/> Выбранные материалы будут отвязаны от всех файлов.
              </div>
            </td>
            <td class="width-1 text-nowrap">
              <label class="checkbox">
                <input v-model="filter.withFiles" @change="applyFilter('withFiles')" type="checkbox" :true-value="1" :false-value="0"/> Показать только с файлами
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="result">
        <datatable
          :columns="columns"
          v-model="batched"
          :filter="filter"
          :options="{limit: 15}"
          src="gallery/listContents"
          ref="contents"
        />
      </div>
      <hr/>
      <button @click="close" type="button" class="btn btn-link pull-right">Закрыть</button>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import TdTitle from './ContentBinding/td/Title'
  import TdFiles from './ContentBinding/td/Files'
  import axios from 'axios'

  export default {
    props: ['paths'],
    data () {
      return {
        columns: [
          {title: 'ID', field: 'id'},
          {title: 'Заголовок, URL', field: 'title', component: TdTitle, thFullWidth: true},
          {title: 'Файлы', field: 'items', component: TdFiles}
        ],
        batched: [],
        filter: {
          content: '',
          withFiles: 0
        }
      }
    },
    methods: {
      bind () {
        let params = {paths: this.paths, contentIds: this.batched}
        axios.post('gallery/bind', params)
          .then(() => {
            this.$refs.contents.update()
            smallBoxSuccess('Файлы и материалы успешно связаны.')
          })
      },
      unbind () {
        let params = {paths: this.paths, contentIds: this.batched}
        axios.post('gallery/unbind', params)
          .then(() => {
            this.$refs.contents.update()
            smallBoxSuccess('Файлы и материалы успешно отвязаны.')
          })
      },
      applyFilter (name) {
        this.batched = []
        this.$refs.contents.applyFilter(name)
      },
      close () {
        this.$modal.hide('form')
      },
      openedHandler () {
        this.$el.querySelector('input[type=text]').focus()
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>

<style scoped>
  .actions {
    margin-bottom: 10px;
  }
  .actions td {
    padding: 0 5px;
  }
  .actions td:nth-child(2) {
    padding-left: 40px;
  }
  .actions .btn-link {
    padding: 0;
  }
</style>