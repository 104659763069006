<template>
  <div>
    <div class="smart-form mb-20">
      <label class="checkbox">
        <input v-model="isRefining" type="checkbox">
        <i></i> Уточняющий фильтр
      </label>
      <label class="checkbox">
        <input v-model="isAutoValue" type="checkbox">
        <i></i> Автоподстановка значения
      </label>
    </div>
    <div class="row mb-20">
      <div class="col col-sm-6">
        <label class="label">
          Основная метка фильтра
        </label>
        <label class="input">
          <input
            v-model="label"
            placeholder="Основная метка фильтра"
            class="form-control"
            type="text"
          />
        </label>
      </div>
      <div class="col col-sm-6">
        <label class="label">
          Класс блока фильтра
        </label>
        <label class="input">
          <input
            v-model="filterClass"
            placeholder="Класс блока фильтра"
            class="form-control"
            type="text"
          />
        </label>
      </div>
    </div>
    <div class="row">
      <section class="col col-3">
        <label class="label">
          Шаблон метки (Заголовок)
        </label>
        <label class="input">
          <input
            v-model="wildcardTitle"
            placeholder="Шаблон метки (Заголовок)"
            type="text"
          />
        </label>
        <div class="note">
          Подстановочный шаблон метки для вывода в заголовок отображения.
          Используйте <strong>[value]</strong> для подстановки значение фильтра.
        </div>
      </section>
      <section class="col col-3">
        <label class="label">
          Шаблон метки (Фильтр) 1
        </label>
        <label class="input">
          <input
            v-model="wildcardFilter1"
            placeholder="Шаблон метки (Фильтр) 1"
            type="text"
          />
        </label>
        <div class="note">
          Выводится в фильтрах, с одним выбранным элементом.
          Используйте <strong>[label]</strong> для подстановки метки раскрытого фильтра.
        </div>
      </section>
      <section class="col col-3">
        <label class="label">
          Шаблон метки (Фильтр) 2
        </label>
        <label class="input">
          <input
            v-model="wildcardFilter2"
            placeholder="Шаблон метки (Фильтр) 2"
            type="text"
          />
        </label>
        <div class="note">
          Выводится в фильтрах, с несколькими эелементами.
          Используйте <strong>[count]</strong> для подстановки количества.
        </div>
      </section>
      <section class="col col-3">
        <label class="label">
          Метка "Все"
        </label>
        <label class="input">
          <input
            v-model="labelAll"
            placeholder="Метка &quot;Все&quot;"
            type="text"
          />
        </label>
        <div class="note">
          Применяется для типов виджета "Радиокнопка" и "Выбор из списка".
        </div>
      </section>
    </div>
    <div class="row mb-20">
      <section class="col col-3"></section>
      <section class="col col-9">
        <div class="note label-filter-note">
          Примечание:
          <ul>
            <li>Если "Шаблон метки (Фильтр) 1" не задан, будет применяться "Шаблон метки (Фильтр) 2".</li>
            <li>Если "Шаблон метки (Фильтр) 2" не задан, будет применяться "Основная метка фильтра".</li>
          </ul>
        </div>
      </section>
    </div>
    <expand-items v-if="!isRefining"/>
  </div>
</template>

<script>
  import ExpandItems from './expand_items/ExpandItems'

  export default {
    name: 'ExpandEditForm',
    components: { ExpandItems },
    computed: {
      form () {
        return JSON.parse(JSON.stringify(this.$store.state.display.filterCriteriaForm))
      },
      isRefining: {
        get () {
          return this.form.isRefining
        },
        set (value) {
          const {form} = this
          form.isRefining = value
          this.updateForm(form)
        }
      },
      isAutoValue: {
        get () {
          return this.form.isAutoValue
        },
        set (value) {
          const {form} = this
          form.isAutoValue = value
          this.updateForm(form)
        }
      },
      label: {
        get () {
          return this.form.label
        },
        set (value) {
          const {form} = this
          form.label = value
          this.updateForm(form)
        }
      },
      filterClass: {
        get () {
          return this.form.filter_class
        },
        set (value) {
          const {form} = this
          form.filter_class = value
          this.updateForm(form)
        }
      },
      wildcardTitle: {
        get () {
          return this.form.wildcard_title
        },
        set (value) {
          const {form} = this
          form.wildcard_title = value
          this.updateForm(form)
        }
      },
      wildcardFilter1: {
        get () {
          return this.form.wildcard_filter1
        },
        set (value) {
          const {form} = this
          form.wildcard_filter1 = value
          this.updateForm(form)
        }
      },
      wildcardFilter2: {
        get () {
          return this.form.wildcard_filter2
        },
        set (value) {
          const {form} = this
          form.wildcard_filter2 = value
          this.updateForm(form)
        }
      },
      labelAll: {
        get () {
          return this.form.label_all
        },
        set (value) {
          const {form} = this
          form.label_all = value
          this.updateForm(form)
        }
      }
    },
    methods: {
      updateForm (form) {
        this.$store.commit('display/setFilterCriteriaForm', form)
      }
    }
  }
</script>

<style scoped>
  .mb-20 {
    margin-bottom: 20px;
  }
  .label-filter-note {
    margin-top: -10px;
    font-weight: bold;
  }
  .label-filter-note ul {
    margin-left: 15px;
  }
  label.checkbox {
    display: inline-block;
    margin-right: 20px;
  }
</style>