<template>
  <span class="onoffswitch">
    <input :checked="Number(value)" :true-value="1" :false-value="0" type="checkbox" class="onoffswitch-checkbox">
    <label @click="emit" class="onoffswitch-label">
      <span class="onoffswitch-inner" data-swchon-text="Да" data-swchoff-text="Нет"></span>
      <span class="onoffswitch-switch" :class="{'onoffswitch-processing fa-spin': processing}"></span>
    </label>
  </span>
</template>

<script>
  export default {
    props: ['value', 'processing'],
    methods: {
      emit () {
        if (this.processing) {
          return
        }
        this.$emit('input', 1 - Number(this.value))
      }
    }
  }
</script>

<style scoped>
  .onoffswitch-processing:before {
    content: "\f1ce" !important;
    color: #777 !important;
  }
</style>