<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <div v-else>
      <button
        class="btn btn-link no-padding font-sm"
        @click="editContent"
      >
        Добавить материал
      </button>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="width-1">Материалы</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(content, idx) in contents">
            <td class="text-nowrap">
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="editContent(idx)"
              >
                {{content.id}} - {{content.title}}
              </button>
            </td>
            <td>
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="removeContent(idx)"
              >
                <i class="fa fa-times"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <content-edit-modal
        v-if="showContentEditModal"
        v-model="contents[editContentIdx]"
        @close="showContentEditModal = false"
      />
    </div>
  </div>
</template>

<script>
  import ContentEditModal from './ContentEditModal'
  import axios from 'axios'

  export default {
    name: 'BlockVisibilityByContent',
    components: { ContentEditModal },
    model: {
      prop: 'items',
      event: 'input'
    },
    props: ['items', 'blockId'],
    data () {
      return {
        contents: [],
        editContentIdx: null,
        showContentEditModal: false,
        loading: true
      }
    },
    watch: {
      contents () {
        this.emit()
      }
    },
    methods: {
      editContent (idx) {
        this.editContentIdx = Number.isInteger(idx) ? idx : this.contents.length
        this.showContentEditModal = true
      },
      removeContent (idx) {
        this.contents.splice(idx, 1)
      },
      emit () {
        const contentIds = JSON.parse(JSON.stringify(this.contents.map(content => content.id)))
        this.$emit('input', contentIds)
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getVisibilityContents`)
      this.contents = data
      this.loading = false
      this.$emit('loaded')
    }
  }
</script>