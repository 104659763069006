<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <fieldset v-else>
      <section>
        <header>Настройка содержимого блока представления</header>
      </section>
      <section>
        <label class="label">Отображение</label>
        <label class="select">
          <xselect2
            v-model="form.display"
            label="Выбор отображения"
            tk="label"
            :src="displays"
            width="100%"
            @change="emit"
          />
        </label>
      </section>
      <section v-if="form.display.id" class="btn-display-edit">
        <a
          :href="`#dview?tab=Display&id=${viewId}&displayId=${form.display.id}`"
          class="btn btn-link"
          target="_blank"
        >
          Перейти к редактированию отображения
        </a>
      </section>
    </fieldset>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Dview',
    props: ['blockId'],
    data () {
      return {
        form: {
          display: {
            id: null
          }
        },
        displays: [],
        loading: true
      }
    },
    computed: {
      viewId () {
        const displayId = this.form.display.id
        if (displayId) {
          const display = this.displays.find(display => display.id == displayId)
          return display.viewId
        }
        return null
      }
    },
    methods: {
      emit () {
        this.$emit('input', {displayId: this.form.display.id})
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getBlockTypeSettings/Dview`)
      this.displays = data.displays.map(display => {
        return {
          id: display.id,
          text: display.label,
          viewId: display.view_id
        }
      })
      if (data.displayBlock) {
        this.form.display = data.displays.find(display => display.id == data.displayBlock.display_id)
      }
      this.loading = false
      this.$emit('loaded')
      this.emit()
    }
  }
</script>