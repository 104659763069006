<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <fieldset v-else>
      <section>
        <header>Настройки содержимого блока мега-меню</header>
      </section>
      <section>
        <label class="label">Выбор мега-меню</label>
        <label class="select">
          <select v-model="form.menuId" @change="emit">
            <option :value="null" selected disabled>Выбор мега-меню</option>
            <option v-for="menu in menus" :value="menu.id">{{menu.label}}</option>
          </select>
        </label>
      </section>
    </fieldset>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Megamenu',
    props: ['blockId'],
    data () {
      return {
        form: {
          menuId: null
        },
        menus: [],
        loading: true
      }
    },
    methods: {
      emit () {
        this.$emit('input', {menuId: this.form.menuId})
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getBlockTypeSettings/Megamenu`)
      this.menus = data.menus
      if (data.menuBlock) {
        this.form.menuId = data.menuBlock.menu_id
      }
      this.loading = false
      this.$emit('loaded')
      this.emit()
    }
  }
</script>