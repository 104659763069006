<template>
  <div class="well well-sm">
    <breadcrumbs :path="path" @navigate="opendir($event)"/>
    <div class="gallery-container">
      <div class="overlay" :class="{'overlay-loader': loader}"></div>
      <table class="table">
        <tbody>
          <tr>
            <td class="files">
              <table v-if="items.length" class="table">
                <thead>
                  <tr>
                    <th class="width-1">
                      <input @change="batchItems" :checked="items.length && batchedItems.length == items.length" type="checkbox"/>
                    </th>
                    <th class="width-1">Имя</th>
                    <th>Изображение</th>
                    <th class="width-1">Вес</th>
                    <th class="width-1">Дата</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.path"
                    :class="{'bg-warning': isBatched(item.path)}"
                  >
                    <td>
                      <input @change="batchItem(item)" :checked="isBatched(item.path)" type="checkbox"/>
                    </td>
                    <td class="text-nowrap font-md" @click.self="batchItem(item)">
                      <a @click.prevent="clickItemHandler(item)" href="#">
                        <i :class="item.video ? 'fa fa-video-camera' : item.icon"/> {{item.name}}
                      </a>
                    </td>
                    <td @click="batchItem(item)">
                      <table v-if="item.is_image" class="thumb">
                        <tbody>
                          <tr>
                            <td>
                              <span class="font-xs">{{item.width}}&times;{{item.height}}</span>
                            </td>
                            <td style="width: 50px;">
                              <img :src="`/storage/${item.thumb}`" height="20"/>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td class="text-nowrap" @click="batchItem(item)">{{item.size}}</td>
                    <td class="text-nowrap" @click="batchItem(item)">{{item.time}}</td>
                  </tr>
                </tbody>
              </table>
              <div v-else>
                <h5 @click="openFilesDialog" class="upload-call-text">
                  <span v-if="loader"><i class="fa fa-circle-o-notch fa-spin text-muted"/> Обработка...</span>
                  <span v-else>Загрузите файлы...</span>
                </h5>
              </div>
            </td>
            <td class="actions">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <a @click.prevent="showContentBindingForm = true" class="btn btn-link" href="#" title="Связать с материалами">
                        <i class="fa fa-picture-o font-lg"/>
                      </a>
                    </td>
                    <td>
                      <a @click.prevent="showRenameForm = true" :disabled="batchedItems.length != 1" class="btn btn-link" href="#" title="Переименовать">
                        <i class="fa fa-pencil font-lg"/>
                      </a>
                    </td>
                    <td>
                      <a @click.prevent="showRemoveForm = true" :disabled="!batchedItems.length" class="btn btn-link" href="#" title="Удалить файлы">
                        <i class="fa fa-trash-o font-lg"/>
                      </a>
                    </td>
                    <td>
                      <a @click.prevent="openFilesDialog" class="btn btn-link" href="#" title="Загрузить файлы">
                        <i class="fa fa-upload font-lg"/>
                      </a>
                    </td>
                    <td>
                      <a @click.prevent="showMakeDirForm = true" class="btn btn-link" href="#" title="Новый каталог">
                        <i class="fa fa-folder font-lg"/>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="batchedItems.length">
                    <td colspan="5">
                      Выбрано файлов: {{batchedItems.length}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Форма переименования файла галереи -->
    <rename-form
      v-if="showRenameForm"
      :item="batchedItems[0]"
      @success="renameHandler"
      @close="showRenameForm = false"
    />

    <!-- Форма загрузки файлов в галерею -->
    <upload-form
      v-if="showUploadForm"
      :files="uploadFiles"
      :path="path"
      @success="opendir(path)"
      @close="closeUploadForm"
    />

    <!-- Форма удаления файлов из галереи -->
    <remove-form
      v-if="showRemoveForm"
      :files="batchedItems"
      @success="opendir(path)"
      @close="showRemoveForm = false"
    />

    <!-- Форма создания нового каталога -->
    <make-dir-form
      v-if="showMakeDirForm"
      :path="path"
      @success="makeDirHandler"
      @close="showMakeDirForm = false"
    />

    <!-- Предпросмотр картинки, видео -->
    <image-preview
      v-if="previewItem"
      :item="previewItem"
      :is-batched="isBatchedPreviewItem"
      @prev="prevPreviewItem"
      @next="nextPreviewItem"
      @batch="batchPreviewItem"
      @removed="removePreviewItem"
      @close="closePreviewItem"
      @edit="previewItemEditHandler"
    />

    <!-- Форма привязки файлов галереи к материалам -->
    <content-binding
      v-if="showContentBindingForm"
      :paths="batchedItems.map(item => item.path)"
      @close="showContentBindingForm = false"
    />
  </div>
</template>

<script>
  import axios from 'axios'
  import Breadcrumbs from './Breadcrumbs'
  import RenameForm from './RenameForm'
  import UploadForm from './UploadForm'
  import RemoveForm from './RemoveForm'
  import MakeDirForm from './MakeDirForm'
  import ImagePreview from './ImagePreview'
  import ContentBinding from './ContentBinding'

  export default {
    components: {Breadcrumbs, RenameForm, UploadForm, RemoveForm, MakeDirForm, ImagePreview, ContentBinding},
    data () {
      return {
        path: '',
        items: [],
        batchedItems: [],
        loader: false,
        showRenameForm: false,
        showUploadForm: false,
        showRemoveForm: false,
        showMakeDirForm: false,
        showContentBindingForm: false,
        uploadFiles: [],
        previewItem: null
      }
    },
    computed: {
      batchedItemsFiles () {
        return this.batchedItems.filter(item => !item.is_dir)
      },
      isBatchedPreviewItem () {
        let {path} = this.previewItem
        return this.batchedItems.some(item => item.path == path)
      }
    },
    methods: {
      opendir (path) {
        this.path = path || 'gallery'
        this.items = []
        this.loader = true
        this.clearBached()
        axios.get('gallery/opendir', {params: {path: this.path}})
          .then(({data}) => {
            this.items = data.items
            this.loader = false
          })
      },
      renameHandler (data) {
        let {path, newPath, newName} = data
        let item = this.items.find(item => item.path == path)
        let batchedItem = this.batchedItems[0]
        item.path = batchedItem.path = newPath
        item.name = batchedItem.name = newName
      },
      clickItemHandler (item) {
        if (item.is_image) {
          this.previewItem = item
          return
        }
        this.opendir(item.path)
      },
      makeDirHandler (data) {
        let {open, newPath} = data
        let path = open ? newPath : this.path
        this.opendir (path)
      },
      previewItemEditHandler (data) {
        let {alt, video} = data
        let {previewItem} = this
        previewItem.alt = alt
        previewItem.video = video
      },
      batchItem (item) {
        let {path, is_dir, name} = item
        let index = this.batchedItems.findIndex(item => item.path == path)
        if (index > -1) {
          this.batchedItems.splice(index, 1)
        } else {
          this.batchedItems.push({path, is_dir, name})
        }
      },
      batchItems () {
        if (this.batchedItems.length != this.items.length) {
          this.batchedItems = this.items
            .map(item => {
              let {path, is_dir, name} = item
              return {path, is_dir, name}
            })
        } else {
          this.clearBached()
        }
      },
      batchPreviewItem () {
        this.batchItem(this.previewItem)
        this.nextPreviewItem()
      },
      removePreviewItem () {
        let {path} = this.previewItem
        let index = this.items.findIndex(item => item.path == path)
        if (this.items.length == 1) {
          this.previewItem = null
        } else {
          this.nextPreviewItem()
        }
        if (index > -1) {
          this.items.splice(index, 1)
        }
      },
      nextPreviewItem () {
        let {path} = this.previewItem
        let nextIndex = this.items.findIndex(item => item.path == path) + 1
        if (this.items[nextIndex]) {
          this.previewItem = this.items[nextIndex]
        }
      },
      prevPreviewItem () {
        let {path} = this.previewItem
        let prevIndex = this.items.findIndex(item => item.path == path) - 1
        if (this.items[prevIndex]) {
          this.previewItem = this.items[prevIndex]
        }
      },
      isBatched (path) {
        return this.batchedItems.find(item => item.path == path)
      },
      closeUploadForm () {
        this.showUploadForm = false
        this.uploadFiles = []
      },
      closePreviewItem () {
        this.previewItem = null
      },
      clearBached () {
        this.batchedItems = []
      },
      openFilesDialog () {
        let input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'
        input.multiple = true
        input.click()
        input.onchange = (e) => {
          this.uploadFiles = e.target.files
          this.showUploadForm = true
        }
      }
    },
    created () {
      this.opendir()
    }
  }
</script>

<style scoped>
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }
  .overlay-loader {
    height: 100%;
    opacity: 0.5;
  }
  .gallery-container {
    position: relative;
  }
  .container-table {
    width: 100%;
  }
  .files {
    width: 80%;
  }
  .actions {
    width: 20%;
    border-left: 1px solid #ddd;
  }
  .actions table td {
    padding: 10px;
  }
  .actions .btn-link {
    padding: 0;
  }
  .upload-call-text {
    cursor: pointer;
  }
  .thumb td {
    padding: 5px;
  }
</style>