<template>
  <modal name="form" @closed="$emit('close')" height="auto" :scrollable="true" width="600">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          Параметры постраничного вывода
        </h4>
        <hr/>
        <section>
          <div class="row">
            <section class="col col-6">
              <section>
                <header>Блочное представление</header>
              </section>
              <section>
                <label class="label">
                  Количество элементов на страницу
                </label>
                <label class="input">
                  <input
                    v-model="form.per_page_to_block"
                    placeholder="Количество элементов на страницу"
                    class="form-control"
                    type="text"
                  />
                </label>
              </section>
              <section>
                <label class="label">
                  Количество страниц в результате
                </label>
                <label class="input">
                  <input
                    v-model="form.number_page_to_block"
                    placeholder="Количество страниц в результате"
                    class="form-control"
                    type="text"
                  />
                </label>
              </section>
            </section>
            <section class="col col-6">
              <section>
                <header>Страничное представление</header>
              </section>
              <section>
                <label class="label">
                  Количество элементов на страницу
                </label>
                <label class="input">
                  <input
                    v-model="form.per_page"
                    placeholder="Количество элементов на страницу"
                    class="form-control"
                    type="text"
                  />
                </label>
              </section>
              <section>
                <label class="label">
                  Количество страниц в результате
                </label>
                <label class="input">
                  <input
                    v-model="form.number_page"
                    placeholder="Количество страниц в результате"
                    class="form-control"
                    type="text"
                  />
                </label>
              </section>
            </section>
          </div>
          <div class="row">
            <div class="col col-12">
              <section class="smart-form">
                <label class="checkbox">
                  <input
                    v-model="form.per_page_is_expand"
                    type="checkbox"
                  /> <i></i> Разрешить пользователю менять количество результатов на странице
                </label>
              </section>
              <section>
                <label class="label">
                  Количество страниц в результате для пользователя
                </label>
                <label
                  class="input"
                  :class="{'state-disabled': !form.per_page_is_expand}"
                >
                  <input
                    v-model="form.per_page_user"
                    :disabled="!form.per_page_is_expand"
                    placeholder="Количество элементов на страницу"
                    class="form-control"
                    type="text"
                  />
                </label>
                <div class="note">
                  Введите количества через запятую.
                </div>
              </section>
            </div>
          </div>
        </section>
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DisplayPaginationEditForm',
    data () {
      const pagination = this.$store.state.display.form.pagination
      return {
        form: {
          per_page_is_expand: pagination.per_page_is_expand,
          per_page_user: pagination.per_page_user,
          per_page: pagination.per_page,
          per_page_to_block: pagination.per_page_to_block,
          number_page: pagination.number_page,
          number_page_to_block: pagination.number_page_to_block
        },
        processing: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      displayId () {
        return this.display.form.id
      }
    },
    methods: {
      submit () {
        const {form} = this
        this.processing = true
        axios.post(`dview/display/${this.displayId}/paginatioin/edit`, form)
          .then(() => {
            this.$store.commit('display/updateFormDisplayPagination', form)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>