<template>
  <section class="block-regions">
    <table v-if="regions.length">
      <tbody>
        <tr v-for="region in regions" :key="region.name" :class="{'text-danger': !region.available}">
          <td>Регион - {{region.title}}</td>
          <td>{{!region.available ? '(недоступен)' : ''}}</td>
          <td>
            <slot
              name="removeButton"
              :region="region"
            ></slot>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="text-danger">Регион не задан</div>
  </section>
</template>

<script>
  export default {
    name: 'BlockRegionsList',
    props: ['items'],
    computed: {
      template () {
        return this.$store.state.common.template
      },
      regions () {
        return this.items.map(item => {
          const region = this.template.regions.find(region => region.name == item)
          const available = Boolean(region)
          return {
            name: region ? region.name : item,
            title: region ? region.title : item,
            available
          }
        })
      }
    }
  }
</script>

<style scoped>
  .block-regions td {
    padding: 2px;
    white-space: nowrap;
    text-align: left;
  }
  .block-regions table tr td:first-child{
    font-weight: bold;
    padding-right: 5px;
  }
</style>