<template>
  <div>
    <div>{{value}}</div>
    <div class="text-muted">Отображения: {{row.displayCount}}</div>
  </div>
</template>

<script>
  export default {
    name: 'Description',
    props: ['row', 'value']
  }
</script>