<template>
  <ol class="breadcrumb font-lg">
    <li
      v-for="(item, i) in items"
      :key="item.path"
    >
      <a  @click.prevent="navigate(item.path, i)" href="#">
        <span v-if="i">{{item.name}}</span>
        <i v-else class="fa fa-home"/>
      </a>
    </li>
  </ol>
</template>

<script>
  export default {
    props: ['path'],
    computed: {
      items () {
        let path = this.path || ''
        let names = path.split('/')
        return names.map((name, i) => {
          let temp = names.slice(0)
          temp.splice(i + 1, temp.length)
          let path = temp.join('/')
          return {name, path}
        })
      }
    },
    methods: {
      navigate (path, i) {
        let isLast = i == this.items.length - 1
        if (!isLast) {
          this.$emit('navigate', path)
        }
      }
    }
  }
</script>

<style scoped>
  .breadcrumb {
    margin: 0;
  }
</style>