<template>
  <div
    class="td-value"
    :class="{'td-error': error}"
    :title="error ? `${field} : ${error}` : field"
  >
    {{value || error}}
  </div>
</template>

<script>
  export default {
    name: 'Td',
    props: ['value', 'row', 'field'],
    computed: {
      error () {
        return typeof this.row['@errors'][this.field] != 'undefined'
          ? this.row['@errors'][this.field]
          : this.row['@errors'][this.$attrs['column-title']]
      }
    }
  }
</script>

<style scoped>
  .td-value {
    max-height: 90px;
    overflow: auto;
  }
  .td-error {
    color: red;
    font-weight: bold;
  }
</style>