<template>
  <modal name="form" @closed="$emit('close')" height="auto" width="1000" :scrollable="true">
    <div class="well no-margin-bottom" style="height: 100%">
      <div>
        <h4>
          <strong>{{contentType.label}} - Подключить поля</strong>
        </h4>
        <hr/>
        <datatable
          :columns="columns"
          :options="{limit: 25}"
          :td="{contentTypeId: contentType.id}"
          :src="`contentType/${contentType.id}/fields/bindedList`"
          @success="$emit('success')"
        />
      </div>
      <hr/>
      <div class="pull-right">
        <button @click="close" type="button" class="btn btn-link">Закрыть</button>
      </div>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import TdBinded from './td/Binded'

  export default {
    props: ['contentType'],
    computed: {
      columns () {
        return [
          {title: 'Подключено', field: 'hasContentType', sort: 'desc', component: TdBinded},
          {title: 'Метка', field: 'label', filter: {type: 'text', width: '90%'}, tdClass: 'text-nowrap'},
          {title: 'Машинное имя', field: 'name', filter: {type: 'text', width: '90%'}, tdClass: 'text-nowrap'},
          {title: 'Тип поля', field: 'type', handler: value => value.charAt(0).toUpperCase() + value.slice(1)},
          {title: 'Обязательное поле', field: 'required', handler: value => value ? 'Да' : 'Нет'}
        ]
      }
    },
    methods: {
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>
