<template>
  <div class="smart-form well">
    <section style="position: relative;">
      <h2>Подписчики (Настройка)</h2>
      <div style="position: absolute; right:0; top:0;">
        <form
          action="/subscribers/download"
          method="post"
        >
          <button
            type="submit"
            class="btn btn-link"
          >
            <i class="fa fa-download"></i> Скачать подтвержденные Email адреса
          </button>
          <div v-html="$root.config.csrf_field"></div>
        </form>
      </div>
    </section>
    <div v-if="loading">Подготовка...</div>
    <div v-else>
      <section>
        <section>
          <header>Тема письма</header>
        </section>
        <label class="input">
          <input
            v-model="form.subject"
            type="text"
            placeholder="Тема письма"
          />
        </label>
      </section>
      <section>
        <section>
          <header>От кого</header>
        </section>
        <div
          class="row"
          style="margin: initial;"
        >
          <div
            class="col-md-3"
            style="padding-right: 5px;"
          >
            <label class="label">От кого: Email</label>
            <label class="input">
              <input
                v-model="form.fromEmail"
                type="text"
                placeholder="От кого: Email"
              />
            </label>
          </div>
          <div class="col-md-3">
            <label class="label">От кого: Имя</label>
            <label class="input">
              <input
                v-model="form.fromName"
                type="text"
                placeholder="От кого: Имя"
              />
            </label>
          </div>
        </div>
      </section>
      <section>
        <section>
          <header>Шаблон письма</header>
        </section>
        <label>
          <vp-editor v-model="form.body"/>
          <div class="note">
            Используйте токен [confirmation_url] для вставки URL подтверждения.
          </div>
        </label>
      </section>
      <footer>
        <submit
          :processing="processing"
          icon="fa fa-save"
          class="btn btn-primary"
          @click="save"
        >
          Сохранить
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Subscribers',
    data () {
      return {
        form: {
          subject: null,
          fromName: null,
          fromEmail: null,
          body: null
        },
        processing: false,
        loading: true
      }
    },
    methods: {
      save () {
        this.processing = true
        axios.post('subscribers/saveSettings', this.form)
          .then(() => {
            smallBoxSuccess('Настройки сохранены.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async mounted () {
      const {data: {subject, body, fromEmail, fromName}} = await axios.get('subscribers/getSettings')
      this.form.subject = subject
      this.form.fromName = fromName
      this.form.fromEmail = fromEmail
      this.form.body = body
      this.loading = false
    }
  }
</script>