<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <div v-else>
      <button
        class="btn btn-link no-padding font-sm"
        @click="editGeo"
      >
        Добавить географическое положение
      </button>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="width-1 text-nowrap">Географическое положение</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(geo, idx) in geos">
            <td class="text-nowrap">
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="editGeo(idx)"
              >
                {{geo.alias}}
              </button>
            </td>
            <td>
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="removeGeo(idx)"
              >
                <i class="fa fa-times"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <geo-edit-modal
        v-if="showGeoEditModal"
        v-model="geos[editGeoIdx]"
        @close="showGeoEditModal = false"
      />
    </div>
  </div>
</template>

<script>
  import GeoEditModal from './GeoEditModal'
  import axios from 'axios'

  export default {
    name: 'BlockVisibilityByGeo',
    components: { GeoEditModal },
    model: {
      prop: 'items',
      event: 'input'
    },
    props: ['items', 'blockId'],
    data () {
      return {
        geos: [],
        editGeoIdx: null,
        showGeoEditModal: false,
        loading: true
      }
    },
    watch: {
      geos () {
        this.emit()
      }
    },
    methods: {
      editGeo (idx) {
        this.editGeoIdx = Number.isInteger(idx) ? idx : this.geos.length
        this.showGeoEditModal = true
      },
      removeGeo (idx) {
        this.geos.splice(idx, 1)
      },
      getAlias (geo) {
        return [
            geo.country,
            geo.region,
            geo.city
          ]
          .filter(item => item)
          .join(', ')
      },
      emit () {
        const geoIds = JSON.parse(JSON.stringify(this.geos.map(geo => geo.id)))
        this.$emit('input', geoIds)
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getVisibilityGeo`)
      this.geos = data.map(item => {
        return {
          id: item.id,
          alias: this.getAlias(item)
        }
      })
      this.loading = false
      this.$emit('loaded')
    }
  }
</script>