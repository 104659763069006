<template>
  <div>
    Тип материала
    <button
      class="btn btn-link no-padding font-sm"
      type="button"
      @click="showEditForm = true"
    >
      Настроить
    </button>
    <div class="content-type-box">
      <ol v-if="display.form.filterContentTypes.length">
        <li v-for="contentType in display.form.filterContentTypes" :key="contentType.id">
          <template v-if="contentType.form">
            {{contentType.form.label}}
          </template>
          <template v-else>
            {{contentType.label}} ({{contentType.name}})
          </template>
        </li>
      </ol>
      <div v-else class="text-muted">Тип материала не задан</div>
    </div>
    <display-content-type-edit-form
      v-if="showEditForm"
      @close="showEditForm = false"
    />
  </div>
</template>

<script>
  import DisplayContentTypeEditForm from './DisplayContentTypeEditForm'

  export default {
    name: 'DisplayContentTypes',
    components: { DisplayContentTypeEditForm },
    data () {
      return {
        showEditForm: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      }
    }
  }
</script>

<style scoped>
  .content-type-box ol {
    padding-left: 25px;
    padding-top: 5px;
  }
</style>