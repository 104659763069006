<template>
  <a @click.prevent="$parent.$emit('edit')" href="#" class="btn btn-link font-sm no-padding">
    {{value}}
  </a>
</template>

<script>
  export default {
    name: 'Label',
    props: ['row', 'value']
  }
</script>