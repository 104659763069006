<template>
  <div class="well well-sm">
    <fieldset>
      <div v-if="loading">Загрузка...</div>
      <div v-else>
        <div class="smart-form">
          <div class="row">
            <section class="col col-6">
              <section>
                <header>Типы материалов</header>
              </section>
              <section>
                <label class="label">Лимит символов для содержимого материала</label>
                <label class="input">
                  <input
                    v-model="form.content_body_length"
                    type="text"
                    placeholder="Лимит символов для содержимого материала"
                  />
                </label>
                <div class="note">Значение "0" отменяет лимит</div>
              </section>
              <section>
                <label class="checkbox">
                  <input
                    v-model="form.content_body_required"
                    type="checkbox"
                  />
                  <i></i> Сделать содержимое материала обязательным полем
                </label>
                <div class="note">По умолчанию содержимое материала является необязательным полем</div>
              </section>
              <section>
                <label class="label">Максимальный размер для загрузки файла материала</label>
                <label class="input">
                  <input
                    v-model="form.content_file_size"
                    type="text"
                    placeholder="Максимальный размер для загрузки файла материала"
                  />
                </label>
                <div class="note">Размер по умолчанию {{ GLOB_CONTENT_FILESIZE }} Кб</div>
              </section>
            </section>
          </div>
        </div>
        <hr/>
        <submit
          @click="submit"
          :processing="processing"
          class="btn btn-primary"
          icon="fa fa-save"
        >
          Сохранить
        </submit>
      </div>
    </fieldset>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Glob',
    data () {
      return {
        form: {
          content_body_length: null,
          content_body_required: null,
          content_file_size: null
        },
        GLOB_CONTENT_FILESIZE: null,
        loading: true,
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post('siteconf/glob/save', this.form)
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    mounted () {
      axios.get('siteconf/glob/get')
        .then(({data}) => {
          this.form = data.form
          this.GLOB_CONTENT_FILESIZE = data.GLOB_CONTENT_FILESIZE
          this.loading = false
        })
    }
  }
</script>