<template>
  <div>
    <label class="label">
      Признаки
    </label>
    <div v-if="loading" class="text-muted display-inline" style="margin-left: 10px;">Подождите...</div>
    <div v-else class="pull-right" style="margin-bottom: 5px;">
      <button @click="showAttributeModal = true" type="button" class="btn btn-link font-sm" style="margin-right: 115px;">Создать признак</button>
      <button @click="add" :disabled="!addable" type="button" class="btn btn-link">
        <i class="fa fa-plus"/>
      </button>
      <xselect2
        label="Выбрать признак"
        tk="label"
        v-model="selectedAttribute"
        :src="attributesSelect"
        width="400"
      />
    </div>
    <datatable
      v-if="!loading"
      :columns="columns"
      :options="{sortableRows: true}"
      :sort="false"
      :src="items"
      :td="{contentTypeId}"
      @removeRow="handleRemoveRow"
      @rowSortDragstart="handleRowSortDragstart"
      @rowSortDragenter="handleRowSortDragenter"
    />

    <!-- Форма редактирования признака -->
    <attribute-edit-modal
      v-if="showAttributeModal"
      :contentTypeId="contentTypeId"
      @edit="handleAttributeEdit"
      @remove="handleAttributeRemove"
      @close="showAttributeModal = false"
    />
  </div>
</template>

<script>
  import AttributeEditModal from '../attributes/AttributeModal'
  import TdLabel from '../attributes/td/Label'
  import axios from 'axios'

  export default {
    components: {AttributeEditModal},
    props: ['value', 'contentTypeId'],
    data () {
      return {
        selectedAttribute: {
          id: null,
          label: null
        },
        items: [],
        attributes: [],
        attributesList: [],
        rowSortMovedAttributeId: null,
        showAttributeModal: false,
        loading: false
      }
    },
    computed: {
      columns () {
        return [
          {title: '#', number: true},
          {title: 'Название', field: 'label', component: TdLabel},
          {title: 'CCK поле', field: 'field_label', tdClass: 'text-nowrap'},
          {title: 'Не применять для альтернативного расчёта', field: 'except_alt', handler: value => value ? 'Да' : '-'},
          {title: '', removeRowButton: true}
        ]
      },
      addable () {
        if (!this.selectedAttribute.id) {
          return false
        }

        return !this.items.find(item => {
          return item.id == this.selectedAttribute.id
        })
      },
      attributesSelect () {
        return this.attributesList
          .filter(attribute => {
            return !this.items.find(item => item.id == attribute.id)
          })
      }
    },
    watch: {
      contentTypeId: {
        immediate: true,
        handler () {
          this.getAttributes()
        }
      }
    },
    methods: {
      add () {
        const exists = this.items.find(item => {
          return item.id == this.selectedAttribute.id
        })
        if (!exists) {
          const attribute = this.attributes.find(attribute => attribute.id == this.selectedAttribute.id)
          this.selectedAttribute.id = null
          this.items.push(attribute)
          this.fixOrder()
          this.$emit('input', this.items)
          this.$emit('change')
        }
      },
      getAttributes () {
        this.loading = true
        axios.get('guglex/contentType/getAttributes', {params: {contentTypeId: this.contentTypeId}})
          .then(({data}) => {
            this.items = data.items
            this.attributes = data.attributes
            this.attributesList = data.attributes
              .map(item => {
                return {
                  id: item.id,
                  text: item.label
                }
              })
            this.$emit('input', this.items)
          })
          .finally(() => {
            this.loading = false
          })
      },
      handleRowSortDragstart (movedAttributeId) {
        this.rowSortMovedAttributeId = movedAttributeId
        this.$emit('change')
      },
      handleRowSortDragenter (enterAttributeId) {
        let movedItem = this.items.find(item => item.id == this.rowSortMovedAttributeId)
        let movedOrder = movedItem.order
        let enterItem = this.items.find(item => item.id == enterAttributeId)
        let enterOrder = enterItem.order
        movedItem.order = enterOrder
        enterItem.order = movedOrder
        this.items
          .sort((a, b) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          })
      },
      handleRemoveRow (id) {
        const idx = this.items.findIndex(item => item.id == id)
        this.items.splice(idx, 1)
        this.$emit('change')
      },
      handleAttributeEdit (attribute) {
        let idx = this.items.findIndex(item => item.id == attribute.id)
        if (idx > -1) {
          this.items.splice(idx, 1, attribute)
          idx = this.attributes.findIndex(item => item.id == attribute.id)
          this.attributes.splice(idx, 1, attribute)
          const attributesListItem = this.attributesList.find(item => item.id == attribute.id)
          attributesListItem.text = attribute.label
        } else {
          this.items.push(attribute)
          this.attributes.push(attribute)
          this.attributesList.push({id: attribute.id, text: attribute.label})
        }
        this.fixOrder()
        this.showAttributeModal = false
      },
      handleAttributeRemove (id) {
        let idx = this.items.findIndex(item => item.id == id)
        this.items.splice(idx, 1)
        idx = this.attributesList.findIndex(item => item.id == id)
        this.attributesList.splice(idx, 1)
        this.$emit('change')
      },
      fixOrder () {
        this.items.forEach((item, idx) => {
          item.order = idx
        })
      }
    }
  }
</script>

<style scoped>
  .label {
    display: inline-block;
    margin-top: 8px;
  }
</style>