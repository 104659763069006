<template>
  <div>
    <label class="input">
      <input
        v-model="label"
        class="input-sm"
        placeholder="Метка"
        style="width: 200px;"
        @input="$emit('input', label)"
      />
    </label>
    <div class="text-primary">
      {{row.field.label}} ({{row.field.name}})
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Label',
    props: ['value', 'row'],
    data () {
      return {
        label: this.value
      }
    }
  }
</script>