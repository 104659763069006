<template>
  <modal name="attribute-modal-form" @closed="$emit('close')" height="auto" width="600" :scrollable="true">
    <div class="well no-margin-bottom" style="height: 100%">
      <div v-if="loading">Подготовка...</div>
      <div v-else>
        <h4>
          <strong>{{guglexAttribute ? 'Редактировать' : 'Создать'}} признак</strong>
        </h4>
        <hr/>
        <div class="smart-form">
          <section>
            <label class="label">Название</label>
            <label class="input">
              <input v-model="form.label" type="text" placeholder="Введите название признака"/>
            </label>
          </section>
          <section>
            <label class="label">CCK поле</label>
            <xselect2
              label="Выберите CCK поле"
              v-model="form.field"
              :src="fieldItems"
              @change="form.label = form.field.name"
              width="100%"
            />
          </section>
          <section>
            <label class="label">Префиксы</label>
            <chips v-model="form.prefix" placeholder="Введите префиксы"/>
          </section>
          <section>
            <label class="label">Удалить фрагменты из значения</label>
            <chips v-model="form.cut_words" placeholder="Введите фрагменты"/>
            <div class="note">Указанные фрагменты слов будут удалены из значения перед обработкой.</div>
          </section>
          <section>
            <label>
              <input v-model="form.except_alt" type="checkbox"/> Не применять для альтернативного расчёта
            </label>
          </section>
        </div>
      </div>
      <hr/>
      <div class="pull-right">
        <button v-if="guglexAttribute && !contentTypeId" @click="remove" :disabled="processing || loading" type="button" class="btn btn-link">Удалить</button>
        <submit @click="submit" :processing="processing" :disabled="loading || !formChanged" class="btn btn-link">
          Сохранить
        </submit>
        <button @click="close" type="button" class="btn btn-link">Закрыть</button>
      </div>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    props: ['value', 'contentTypeId'],
    data () {
      const guglexAttribute = this.value || {field_id: null, field_label: '', label: '', prefix: [], cut_words: [], except_alt: false}
      return {
        fieldItems: [],
        updatedGuglexAttribute: null,
        form: {
          field: {
            id: guglexAttribute.field_id,
            name: guglexAttribute.field_label
          },
          label: guglexAttribute.label,
          prefix: guglexAttribute.prefix.slice(0),
          cut_words: guglexAttribute.cut_words.slice(0),
          except_alt: guglexAttribute.except_alt
        },
        formChanged: false,
        processing: false,
        loading: true
      }
    },
    computed: {
      guglexAttribute () {
        const {value, updatedGuglexAttribute} = this
        return value || updatedGuglexAttribute
      }
    },
    watch: {
      form: {
        deep: true,
        handler () {
          this.formChanged = true
        }
      }
    },
    methods: {
      submit () {
        this.processing = true
        const {guglexAttribute, form} = this
        const {field, label, prefix, cut_words, except_alt} = form
        const action = guglexAttribute && guglexAttribute.id ? `edit/${guglexAttribute.id}` : 'create'
        const params = {
          field_id: field.id,
          label,
          prefix,
          cut_words,
          except_alt
        }
        axios.post(`guglex/attribute/${action}`, params)
          .then(({data}) => {
            this.updatedGuglexAttribute = data
            this.formChanged = false
            this.$emit('edit', data)
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      remove () {
        if (!confirm('Удалить признак?')) {
          return
        }

        axios.post(`guglex/attribute/delete/${this.guglexAttribute.id}`)
          .then(() => {
            this.$emit('remove', this.guglexAttribute.id)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
      },
      close () {
        this.$modal.hide('attribute-modal-form')
      }
    },
    mounted () {
      this.$modal.show('attribute-modal-form')
      axios.get('guglex/attribute/selectFields', {params: {contentTypeId: this.contentTypeId}})
        .then(({data}) => {
          this.fieldItems = data
            .map(item => {
              return {
                id: item.id,
                text: item.label
              }
            })
          this.loading = false
        })
    }
  }
</script>
