<template>
  <bs-switcher
    :value="value"
    :processing="processing"
    @input="submit"
  />
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Required',
    props: ['row', 'value', 'td'],
    data () {
      return {
        processing: false
      }
    },
    methods: {
      async submit (value) {
        this.processing = true
        const params = {
          fieldId: this.row.id,
          flag: value
        }
        await axios.post(`contentType/${this.td.contentTypeId}/fields/setRequiredField`, params)
        this.processing = false
        this.$emit('input', value)
        this.$parent.$emit('success')
        smallBoxSuccess('Выполнено.')
      }
    }
  }
</script>