<template>
  <div style="position: relative">
    <field-edit-form
      v-if="activeTab == 'FieldCreate'"
      @created="setEditTab"
    />
    <field-system-form
      v-else-if="activeTab == 'FieldSystem'"
    />
    <div v-else-if="activeTab">
      <div class="tabs-body">
        <ul class="nav nav-tabs">
          <li
            v-for="tab in tabs"
            :key="tab.name"
            :class="{active: tab.name == activeTab}"
          >
            <a @click.prevent="setActiveTab(tab)" href="#">{{tab.title}}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active">
            <component
              :is="activeTab"
              :field-id="fieldId"
              @deleted="handleDeleted"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <button
        type="button"
        class="btn btn-link no-padding font-xs field-create-btn"
        @click="setCreateTab"
      >
        Создать поле
      </button>
      <button
        type="button"
        class="btn btn-link no-padding font-xs field-system-btn"
        @click="setSystemTab"
      >
        Системные поля
      </button>
      <datatable
        :columns="columns"
        :options="{tableClass: 'table-bordered'}"
        src="field/list"
        @setEditTab="setEditTab"
      />
    </div>
  </div>
</template>

<script>
  import FieldEditForm from './FieldEditForm'
  import FieldDeleteForm from './FieldDeleteForm'
  import FieldSystemForm from './FieldSystemForm'
  import TdLabel from './td/Label'

  export default {
    name: 'Fields',
    components: {
      FieldEditForm,
      FieldDeleteForm,
      FieldSystemForm
    },
    data () {
      return {
        activeTab: _hash_struct.tab,
        fieldId: _hash_struct.query.id,
        tabs: [
          {title: 'Редактировать поле', name: 'FieldEditForm'},
          {title: 'Удалить поле', name: 'FieldDeleteForm'}
        ]
      }
    },
    computed: {
      columns () {
        return [
          {
            title: 'Метка',
            field: 'label',
            filter: {type: 'text', width: '100%'},
            tdClass: 'text-nowrap',
            component: TdLabel
          },
          {
            title: 'Машинное имя',
            field: 'name',
            filter: {type: 'text', width: '100%'},
            tdClass: 'text-nowrap'
          },
          {
            title: 'Тип поля',
            field: 'type',
            handler: value => value.charAt(0).toUpperCase() + value.slice(1)
          },
          {
            title: 'Типы материалов',
            field: 'contentTypeCount'
          }
        ]
      }
    },
    methods: {
      setCreateTab () {
        this.setActiveTab({name: 'FieldCreate'})
      },
      setEditTab (id) {
        this.fieldId = id
        this.setActiveTab({name: 'FieldEditForm'})
      },
      setSystemTab () {
        this.setActiveTab({name: 'FieldSystem'})
      },
      setActiveTab (tab) {
        this.activeTab = tab.name
        this.setTab(tab.name)
      },
      setTab (tab) {
        CHECK_URL_ON_HASH_CHANGE = false;
        const query = []
        if (tab) {
          query.push(`tab=${tab}`)
        }
        if (this.fieldId) {
          query.push(`id=${this.fieldId}`)
        }
        window.location.hash = 'field' + (query.length ? `?${query.join('&')}` : '')
      },
      handleDeleted () {
        this.fieldId = null
        this.activeTab = null
        this.setTab()
      }
    }
  }
</script>

<style scoped>
  .field-create-btn {
    position: absolute;
    top: -22px;
    left: 48px;
  }
  .field-system-btn {
    position: absolute;
    top: -22px;
    left: 150px;
  }
</style>