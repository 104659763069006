import axios from 'axios'

export default {
  namespaced: true,
  state: {
    template: {
      pageTypes: [],
      regions: [],
      pageTypesRegions: {}
    },
    domDocumentEvents: {
      click: null,
      escape: null
    }
  },
  actions: {
    async initTemplate ({state, commit}) {
      if (state.template.pageTypes.length) {
        return
      }
      const {data} = await axios.get('common/getTemplate')
      commit('setTemplate', data)
    }
  },
  mutations: {
    setTemplate (state, template) {
      state.template = template
    },
    domDocumentEventFired (state, event) {
      if (event instanceof KeyboardEvent) {
        if (event.key == 'Escape') {
          state.domDocumentEvents.escape = event
        }
      } else if (event instanceof MouseEvent) {
        state.domDocumentEvents.click = event
      }
    }
  },
  getters: {
  
  }
}