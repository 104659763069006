<template>
  <section class="col col-6">
    <section>
      <header>Блочное представление</header>
    </section>
    <label class="label">Когда значение фильтра недоступно</label>
    <label class="radio">
      <input
        v-model="emptyAction"
        type="radio"
        value="default"
        name="contextEmptyAction"
      />
      <i></i> Обеспечить значение по умолчанию
    </label>
    <div v-if="emptyAction == 'default'" style="margin-left: 25px;">
      <section>
        <label class="label">Тип</label>
        <label class="select">
          <select v-model="defaultType">
            <option :value="null" selected disabled>Выберите значение</option>
            <option value="content">Данные с текущего материала на странице</option>
            <option value="fixed">Фиксированное значение</option>
          </select>
          <i></i>
        </label>
      </section>
      <section v-if="defaultType == 'fixed'" class="fixed-value">
        <label class="label">Фиксированное значение</label>
        <label class="input">
          <input
            v-model="fixedValue"
            placeholder="Фиксированное значение"
          />
        </label>
      </section>
      <section>
        <label class="checkbox">
          <input
            v-model="defaultExclude"
            :true-value="1"
            :false-value="0"
            type="checkbox"
          />
          <i></i> Применить исключающий оператор
        </label>
      </section>
    </div>
    <label class="radio">
      <input
        v-model="emptyAction"
        type="radio"
        value="hide"
        name="contextEmptyAction"
      />
      <i></i> Скрыть представление
    </label>
    <div v-if="emptyAction == 'hide'" style="margin-left: 25px;">
      <label class="label">Сообщение</label>
      <label class="input">
        <input
          v-model="noResultText"
          placeholder="Фиксированное значение"
        />
      </label>
      <div class="note">Пример: "Результатов не найдено"</div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'BlockForm',
    computed: {
      display () {
        return this.$store.state.display
      },
      form () {
        return JSON.parse(JSON.stringify(this.display.filterContextForm))
      },
      emptyAction: {
        get () {
          return this.form.empty_action
        },
        set (value) {
          const {form} = this
          form.empty_action = value
          this.updateForm(form)
        }
      },
      defaultType: {
        get () {
          return this.form.default_type
        },
        set (value) {
          const {form} = this
          form.default_type = value
          this.updateForm(form)
        }
      },
      fixedValue: {
        get () {
          return this.form.fixed_value
        },
        set (value) {
          const {form} = this
          form.fixed_value = value
          this.updateForm(form)
        }
      },
      defaultExclude: {
        get () {
          return this.form.default_exclude
        },
        set (value) {
          const {form} = this
          form.default_exclude = value
          this.updateForm(form)
        }
      },
      noResultText: {
        get () {
          return this.form.no_result_text
        },
        set (value) {
          const {form} = this
          form.no_result_text = value
          this.updateForm(form)
        }
      }
    },
    methods: {
      updateForm (form) {
        this.$store.commit('display/setFilterContextForm', form)
      }
    }
  }
</script>