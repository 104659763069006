<template>
  <div>
    <div class="smart-form" style="margin-top: 8px;">
      <label class="checkbox" :class="{'state-disabled': !hasDisplayField}">
        <input
          v-model="enabled"
          :disabled="!hasDisplayField"
          type="checkbox"
        />
        <i></i> Применить этот фильтр к полю отображения
        с активной опцией "Значения из взаимосвязей"
      </label>
      <div v-if="enabled" style="margin: 10px 0 0 20px;">
        <label class="label">
          Поле отображения
        </label>
        <select
          v-model="displayFieldId"
          class="form-control"
          style="margin-bottom: 10px;"
        >
          <option
            :value="null"
            selected
            disabled
          >
            Выберите поле отображения
          </option>
          <option
            v-for="item in interrelatedDisplayFields"
            :key="item.id"
            :value="item.id"
          >
            {{item.label || item.field.label}}
          </option>
        </select>
        <label class="checkbox">
          <input
            v-model="displayFieldContext"
            type="checkbox"
          />
          <i></i> А также произвести соответствие значений текущего фильтра
          с значениями из взаимосвязей в контексте текущего материала на странице
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InterrelationEditForm',
    data () {
      const display = this.$store.state.display
      const form = display.filterCriteriaForm
      return {
        enabled: Boolean(form.display_field_id)
      }
    },
    computed: {
      form () {
        return JSON.parse(JSON.stringify(this.display.filterCriteriaForm))
      },
      displayFieldId: {
        get () {
          return this.form.display_field_id
        },
        set (value) {
          const {form} = this
          form.display_field_id = value
          this.updateForm(form)
        }
      },
      displayFieldContext: {
        get () {
          return this.form.display_field_context
        },
        set (value) {
          const {form} = this
          form.display_field_context = value
          this.updateForm(form)
        }
      },
      display () {
        return this.$store.state.display
      },
      interrelatedDisplayFields () {
        return this.display.form.displayFields.filter(displayField => {
          return this.form.field.id == displayField.field.id &&
            displayField.interrelations.length
        })
      },
      hasDisplayField () {
        return Boolean(this.interrelatedDisplayFields.length)
      }
    },
    watch: {
      enabled (flag) {
        if (!flag) {
          const {form} = this
          form.display_field_id = null
          form.display_field_context = false
          this.updateForm(form)
        }
      }
    },
    methods: {
      updateForm (form) {
        this.$store.commit('display/setFilterCriteriaForm', form)
      }
    }
  }
</script>