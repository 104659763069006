<template>
  <modal name="tabFieldForm" @closed="$emit('close')" height="auto" :scrollable="true" width="600">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          {{isCreate ? 'Добавить' : 'Редактировать'}} поле для вкладки
        </h4>
        <hr/>
        <section>
          <label class="label">
            Поле для вкладок
          </label>
          <xselect2
            v-model="field"
            label="Выбор поля для вкладок"
            tk="label"
            :src="fields"
            width="100%"
            @change="form.field_id = $event.id"
          />
        </section>
        <section>
          <label class="label">
            Метка вкладки
          </label>
          <label class="input">
            <input
              v-model="form.label"
              placeholder="Метка вкладки"
              class="form-control"
              type="text"
            />
          </label>
          <div class="note">
            <p>Используйте подстанововчный шаблон <strong>[label]</strong> для вывода метки поля для вкладки.</p>
            <p>Если оставить пустым, то значением этого поля будет <strong>[label]</strong></p>
          </div>
        </section>
        <hr/>
        <div class="pull-right">
          <button
            class="btn btn-link"
            type="button"
            @click="apply"
          >
            Применить
          </button>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import _uniqueId from 'lodash/uniqueId'

  export default {
    name: 'TabFieldForm',
    props: ['value', 'order'],
    data () {
      const form = typeof this.value == 'undefined'
        ? this.getEmptyForm()
        : JSON.parse(JSON.stringify(this.value))
      const field = this.$store.state.display.fields.find(field => field.id == form.field_id)
      return {
        form,
        field: {
          id: field ? field.id : null,
          label: field ? `${field.label} (${field.name})` : null
        }
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      fields () {
        return this.display.fields.map(field => {
          return {
            id: field.id,
            text: `${field.label} (${field.name})`
          }
        })
      },
      isCreate () {
        return typeof this.value == 'undefined'
      }
    },
    methods: {
      apply () {
        let error
        if (!this.form.field_id) {
          error = 'Выберите поле для вкладок.'
        }
        if (error) {
          smallBoxErrors(error)
          return
        }
        this.$emit('input', this.form)
        this.close()
      },
      getEmptyForm () {
        return {
          id: -_uniqueId(),
          display_id: this.$store.state.display.form.id,
          field_id: null,
          label: null,
          order: this.order
        }
      },
      close () {
        this.$modal.hide('tabFieldForm')
      }
    },
    mounted () {
      this.$modal.show('tabFieldForm')
    }
  }
</script>