<template>
  <modal name="form" @closed="$emit('close')" height="auto" :scrollable="true" width="1000">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          Текст при отстутствии результата
        </h4>
        <hr/>
        <label class="label">Текст при отстутствии результата</label>
        <label class="textarea">
          <textarea
            v-model="form.noResultText"
            rows="5"
            cols="50"
            placeholder="Введите текст при отстутствии результата"
          ></textarea>
        </label>
        <wildcards :items="wildcards"/>
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import Wildcards from '../../Wildcards'
  import axios from 'axios'

  export default {
    name: 'DisplayNoResultEditForm',
    components: { Wildcards },
    data () {
      return {
        form: {
          noResultText: this.$store.state.display.form.noResultText
        },
        wildcards: [],
        processing: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      displayId () {
        return this.display.form.id
      },
      macros () {
        return this.display.macros
      }
    },
    methods: {
      submit () {
        const {form} = this
        this.processing = true
        axios.post(`dview/display/${this.displayId}/noResultText/edit`, form)
          .then(() => {
            this.$store.commit('display/updateFormDisplayNoResultText', form.noResultText)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.wildcards = this.display.form.filterContexts
        .map(context => {
          return {
            name: context.field.name,
            label: context.field.label
          }
        })
        .concat(
          this.macros.map(item => {
            return {
              name: item[0],
              label: item[1]
            }
          })
        )
      this.$modal.show('form')
    }
  }
</script>