<template>
  <form v-if="can('contentexport.export.w')" action="/contentExport" method="post" class="smart-form well">
    <div class="row">
      <section class="col col-6">
        <section>
          <header>Выбор типов материалов.</header>
          <div class="note">Отметьте типы, материалы которых требуется экспортировать в файл.</div>
        </section>
        <section>
          <label class="checkbox checkbox-all checkbox-contenttypes">
            <input v-model="selectAllContentTypes" @change="hangleSelectAllContentTypes" type="checkbox">
            <i></i> <strong>Выбрать все</strong>
          </label>
          <label class="input content-type-filter">
            <input v-model="contentTypeLabel" placeholder="Отфильтровать типы материалов для отображения." type="text"/>
          </label>
          <div class="note">Отфильтровать типы материалов для отображения.</div>
          <hr/>
          <label v-for="contentType in contentTypes" class="checkbox">
            <input v-model="form.contentTypeIds" :value="contentType.id" name="contentTypeIds[]" type="checkbox"/> <i></i> {{contentType.label}}
          </label>
        </section>
        <section>
          <header>Применить фильтрацию материалов по полю "Title"</header>
        </section>
        <section>
          <label class="input">
            <input name="contentTitle" placeholder="Применить фильтрацию материалов по полю &quot;Title&quot;" type="text"/>
            <div class="note">Применить фильтрацию материалов по полю "Title"</div>
          </label>
        </section>
      </section>
      <section class="col col-6">
        <section>
          <header>Включить поля в файл экспорта.</header>
          <div class="note">Отметьте поля, которые требуется включить в файл.</div>
        </section>
        <section>
          <label :class="{'state-disabled': !form.contentTypeIds.length}" class="checkbox checkbox-all checkbox-fields">
            <input v-model="selectAllFields" @change="handleSelectAllFields" :disabled="!form.contentTypeIds.length" type="checkbox">
            <i></i> <strong>Все CCK поля</strong>
          </label>
          <hr>
          <label v-for="field in fields" class="checkbox">
            <input v-model="form.fields" :value="field.name" name="fields[]" type="checkbox"/> <i></i> {{field.label}} <span class="text-muted">({{field.name}})</span>
          </label>
        </section>
        <section>
          <header>Дополнительные поля материала.</header>
          <div class="note">Отметьте поля, которые требуется включить в файл.</div>
        </section>
        <section>
          <label v-for="(label, name) in config.contentFields" class="checkbox">
            <input :value="name" name="contentFields[]" type="checkbox"/> <i></i> {{label}} <span class="text-muted">({{name}})</span>
          </label>
        </section>
      </section>
    </div>
    <section>
      <header>Настройки файла экспорта</header>
    </section>
    <div class="row">
      <div class="col col3">
        <section>
          <label class="label">Разделитель столбцов в csv</label>
          <label class="select">
            <select name="csv_delimiter">
              <option value=",">Запятая</option>
              <option value=";" selected>Точка с запятой</option>
            </select>
            <i></i>
          </label>
        </section>
        <section>
          <label class="checkbox">
            <input name="to_windows_1251" value="1" type="checkbox" checked>
            <i></i> Скачать файл в кодировке Windows-1251
          </label>
        </section>
      </div>
    </div>
    <footer>
      <button type="submit" class="btn btn-primary">
        <i class="fa fa-download"></i> Экспорт
      </button>
    </footer>
    <div v-html="config.csrf_field"></div>
  </form>
</template>

<script>
  import _escapeRegExp from 'lodash/escapeRegExp';

  export default {
    data () {
      return {
        selectAllContentTypes: false,
        selectAllFields: false,
        form: {
          contentTypeIds: [],
          fields: []
        }
      }
    },
    computed: {
      contentTypes () {
        const split = this.contentTypeLabel.split(' ').filter(string => string.trim())
        const regexp = new RegExp(split.map(string => `(?=.*${_escapeRegExp(string)})`).join(''), 'i')
        return this.config.contentTypes.filter(contentType => {
          return regexp.test(contentType.label)
        })
      },
      contentTypeIds () {
        return this.contentTypes.map(contentType => contentType.id)
      },
      fields () {
        const fields = []
        this.form.contentTypeIds.forEach(contentTypeId => {
          this.contentTypes
            .find(contentType => contentType.id == contentTypeId)
            .fields
              .forEach(field => {
                if (!fields.some(listedField => listedField.id == field.id)) {
                  fields.push(field)
                }
              })
        })
        return fields
          .sort((a, b) => a.label.localeCompare(b.label))
      },
      fieldNames () {
        return this.fields.map(field => field.name)
      },
      config () {
        return this.$root.config
      },
      contentTypeLabel: {
        get () {
          return this.$store.state.cookies.contentExportContentTypeLabel || ''
        },
        set (value) {
          this.$store.commit('cookies/contentExportSetContentTypeLabel', value)
        }
      },
      can () {
        return window.has_access
      }
    },
    watch: {
      'form.contentTypeIds' (value, oldValue) {
        this.selectAllContentTypes = value.length == this.contentTypeIds.length
        this.selectAllFields = this.form.fields.length == this.fieldNames.length

        if (value.length < oldValue.length) {
          this.form.fields = this.form.fields.filter(formField => this.fieldNames.includes(formField))
        }
      },
      contentTypeLabel () {
        this.form.contentTypeIds = []
      },
      fields (value) {
        if (!value.length) {
          this.selectAllFields = false
          this.form.fields = []
        }
      },
      'form.fields' (value) {
        this.selectAllFields = value.length && value.length == this.fieldNames.length
      }
    },
    methods: {
      hangleSelectAllContentTypes () {
        this.form.contentTypeIds = this.selectAllContentTypes ? this.contentTypeIds : []
      },
      handleSelectAllFields () {
        this.form.fields = this.selectAllFields ? this.fieldNames : []
      }
    }
  }
</script>

<style scoped>
  hr {
    margin: 10px 0;
  }
  .content-type-filter {
    width: 285px;
  }
</style>