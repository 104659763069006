<template>
  <div>
    Контекстные фильтры
    <button-menu
      title="Управление"
      btn-class="btn btn-link font-sm no-padding"
      style="margin-bottom: 5px;"
      :items="buttonMenu"
      @addFilter="showFilterAddForm = true"
      @reorderFilters="showReorderFiltersForm = true"
    />
    <div class="contexts-box">
      <ol>
        <li v-for="(contextItem, idx) in contextItems" :key="contextItem.id">
          <button
            class="btn btn-link font-sm no-padding"
            type="button"
            @click="editIdx = idx"
          >
            {{contextItem.field.label}} ({{contextItem.field.name}})
          </button>
        </li>
      </ol>
    </div>
    <display-context-create-form
      v-if="showFilterAddForm"
      @close="showFilterAddForm = false"
    />
    <display-context-edit-form
      v-if="isEditable"
      @close="editIdx = null"
    />
    <display-context-order-form
      v-if="showReorderFiltersForm"
      @close="showReorderFiltersForm = false"
    />
  </div>
</template>

<script>
  import DisplayContextCreateForm from './DisplayContextCreateForm'
  import DisplayContextEditForm from './DisplayContextEditForm'
  import DisplayContextOrderForm from './DisplayContextOrderForm'

  export default {
    name: 'DisplayContext',
    components: {
      DisplayContextCreateForm,
      DisplayContextEditForm,
      DisplayContextOrderForm
    },
    data () {
      return {
        buttonMenu: {
          addFilter: 'Добавить поле',
          reorderFilters: 'Порядок контекстных фильтров'
        },
        showFilterAddForm: false,
        showReorderFiltersForm: false,
        editIdx: null
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      contextItems () {
        return this.display.form.filterContexts
      },
      isEditable () {
        return this.editIdx != null
      }
    },
    watch: {
      editIdx (idx) {
        let form = null
        if (idx != null) {
          form = JSON.parse(JSON.stringify(this.contextItems[idx]))
        }
        this.$store.commit('display/setFilterContextForm', form)
      }
    }
  }
</script>

<style scoped>
  .contexts-box ol{
    padding-left: 25px
  }
  .contexts-box ol button {
    margin-top: -3px
  }
  .contexts-box li {
    margin-bottom: 4px;
  }
</style>