<template>
  <div class="well well-sm">
    <fieldset>
      <div v-if="loading">Загрузка...</div>
      <div v-else>
        <div class="smart-form">
          <section>
            <header>Настройка файла robots.txt</header>
          </section>
          <section>
            <label class="textarea textarea-resizable">
              <textarea
                v-model="form.robots"
                rows="30"
                class="custom-scroll"
                placeholder="Параметры индексирования сайта для роботов поисковых систем."
              />
            </label>
            <div class="note">
              <p>Параметры индексирования сайта для роботов поисковых систем.</p>
              <p>Используйте подстановочный шаблон <strong>[host]</strong> для вывода значения текущего хоста.</p>
            </div>
          </section>
        </div>
        <hr/>
        <submit
          @click="submit"
          :processing="processing"
          class="btn btn-primary"
          icon="fa fa-save"
        >
          Сохранить
        </submit>
      </div>
    </fieldset>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Robots',
    data () {
      return {
        form: {
          robots: null
        },
        loading: true,
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post('siteconf/robots/save', this.form)
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    mounted () {
      axios.get('siteconf/robots/get')
        .then(({data}) => {
          this.form = data
          this.loading = false
        })
    }
  }
</script>