<template>
  <div>
    <div v-if="loading" class="text-muted">Подготовка...</div>
    <div v-else>
      <button
        class="btn btn-link no-padding font-sm"
        @click="editUrl"
      >
        Добавить URL
      </button>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="width-1">URL</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(url, idx) in urls">
            <td class="text-nowrap">
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="editUrl(idx)"
              >
                {{url}}
              </button>
            </td>
            <td>
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="removeUrl(idx)"
              >
                <i class="fa fa-times"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <url-edit-modal
        v-if="showUrlEditModal"
        v-model="urls[editUrlIdx]"
        @close="showUrlEditModal = false"
      />
    </div>
  </div>
</template>

<script>
  import UrlEditModal from './UrlEditModal'
  import axios from 'axios'

  export default {
    name: 'BlockVisibilityByPageUrl',
    components: { UrlEditModal },
    model: {
      prop: 'items',
      event: 'input'
    },
    props: ['items', 'blockId'],
    data () {
      return {
        urls: [],
        editUrlIdx: null,
        showUrlEditModal: false,
        loading: true
      }
    },
    watch: {
      urls () {
        this.emit()
      }
    },
    methods: {
      editUrl (idx) {
        this.editUrlIdx = Number.isInteger(idx) ? idx : this.urls.length
        this.showUrlEditModal = true
      },
      removeUrl (idx) {
        this.urls.splice(idx, 1)
      },
      emit () {
        this.$emit('input', JSON.parse(JSON.stringify(this.urls)))
      }
    },
    async created () {
      const {data} = await axios.get(`block/${this.blockId}/getVisibilityUrls`)
      this.urls = data.map(item => item.url)
      this.loading = false
      this.$emit('loaded')
    }
  }
</script>