<template>
  <div>
    <div class="font-md">
      <a v-if="value" @click.prevent="showFilesForm = true" href="#">
        {{value}}
      </a>
      <span v-else>{{value}}</span>
    </div>
    <files-form v-if="showFilesForm" :content-id="row.id" @close="showFilesForm = false" @removed="$emit('input', $event)"/>
  </div>
</template>

<script>
  import FilesForm from '../FilesForm'

  export default {
    components: {FilesForm},
    props: ['value', 'row'],
    data () {
      return {
        showFilesForm: false
      }
    }
  }
</script>
