export default {
  namespaced: true,
    state: {
      error: {
        status: null,
          items: [],
          item: null
      }
},
  mutations: {
    add (state, data) {
      state.error.status = data.status
      if (typeof data.errors == 'object') {
        const items = []
        for (const [key, errors] of Object.entries(data.errors)) { // eslint-disable-line no-unused-vars
          errors.forEach(error => {
            if (!state.error.items.includes(error)) {
              items.push(error)
            }
          })
        }
        
        if (items.length) {
          state.error.items = items
          state.error.item = null
        }
      } else if (state.error.item != data.errors) {
        state.error.items = []
        state.error.item = data.errors
      }
    },
    clear (state) {
      state.error.status = null
      state.error.items = []
      state.error.item = null
    }
  }
}