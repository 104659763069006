<template>
  <modal name="form" @closed="$emit('close')" height="auto" width="1000" :scrollable="true">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          {{showDelete ? 'Удалить' : 'Настроить'}} поле: {{form.label || form.field.label}} ({{form.name}})
          <p style="font-size: 12px;">
            Прототип: {{ form.field.label }} ({{ form.field.name }})
          </p>
        </h4>
        <hr/>
        <div v-if="!showDelete" class="smart-form">
          <label class="checkbox">
            <input v-model="editable" type="checkbox">
            <i></i> <span>Редактировать</span>
          </label>
          <button
            class="btn btn-link no-padding delete-field-button"
            @click="showDelete = true"
          >
            Удалить поле &nbsp;<i class="fa fa-trash-o font-md"></i>
          </button>
        </div>
        <template v-if="showDelete">
          <div class="font-md">
            Хотите удалить поле отображения?
          </div>
        </template>
        <template v-else>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr class="info">
                <td colspan="2">
                  <span class="font-md">Машинное имя</span>
                </td>
              </tr>
              <tr>
                <td title="Используется для вывода поля в шаблоне.">
                  Машинное имя
                </td>
                <td>
                  <xtext
                    v-model="form.name"
                    :editable="editable"
                    label="Машинное имя"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Скрытое поле выводится в отображение при помощи подстановочного шаблона &quot;[hidden_<Машинное имя>]&quot;.">
                  Скрытое поле
                </td>
                <td>
                  <xselect
                    v-model="form.hidden"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Скрытое поле"
                    compact
                  />
                </td>
              </tr>

              <template v-if="form.is_statical_storage">
                <tr class="info">
                  <td colspan="2">
                    <span class="font-md">Значения из взаимосвязей</span>
                  </td>
                </tr>
                <tr>
                  <td title="Если взаимосвязи установлены, то значением текущего поля будет массив значений из соответствующих полей взаимосвязанных материалов.">
                    Установить взаимосвязи
                  </td>
                  <td>
                    <display-field-interrelatioins
                      v-model="form.interrelations"
                      :editable="editable"
                    />
                  </td>
                </tr>
                <tr>
                  <td title="Сортировка по значению.">
                    Сортировка по значению
                  </td>
                  <td>
                    <xselect
                      v-model="form.interrelation_order"
                      :editable="editable"
                      :options="options.order"
                      pk="value"
                      tk="text"
                      label="Сортировка по значению"
                      compact
                    />
                  </td>
                </tr>
              </template>
              <tr class="info">
                <td colspan="2">
                  <span class="font-md">Массив значений</span>
                </td>
              </tr>
              <tr>
                <td title="Установить лимит для вывода количества значений из массива. 0 - вывести все значения.">
                  Лимит значений
                </td>
                <td>
                  <xtext
                    v-model="form.list_limit"
                    :editable="editable"
                    label="Лимит значений"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr v-if="form.is_numeric">
                <td title="Вывести только минимальное и максимальное значения, которые доступны в качестве подстановочных шаблонов [value_min] и [value_max] соответственно.">
                  Значения Min, Max
                </td>
                <td>
                  <xselect
                    v-model="form.list_min_max"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Значения Min, Max"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Обернуть вывод в тег &lt;ul&gt;.">
                  Значения в виде списка &lt;ul&gt;
                </td>
                <td>
                  <xselect
                    v-model="form.list_wrap"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Значения в виде списка &lt;ul&gt;"
                    compact
                  />
                </td>
              </tr>
              <tr title="Класс для тега &lt;ul&gt;.">
                <td>
                  Класс для тега &lt;ul&gt;
                </td>
                <td>
                  <xtext
                    v-model="form.list_wrap_class"
                    :editable="editable"
                    label="Класс для тега ul"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Не применяется, если вывод обернут в тег &lt;ul&gt;.">
                  Разделитель значений списка
                </td>
                <td>
                  <xselect
                    v-model="form.list_delimiter"
                    :editable="editable"
                    :options="options.listDelimiter"
                    pk="value"
                    tk="text"
                    label="Разделитель значений списка"
                    compact
                  />
                </td>
              </tr>
              <tr class="info">
                <td colspan="2">
                  <span class="font-md">Метка и значение</span>
                </td>
              </tr>
              <tr>
                <td style="width:40%">Метка</td>
                <td>
                  <xtext
                    v-model="form.label"
                    :editable="editable"
                    label="Метка"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td>Двоеточие после метки</td>
                <td>
                  <xselect
                    v-model="form.colon"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Двоеточие после метки"
                    compact
                  />
                </td>
              </tr>
              <tr v-if="form.field.type == 'date'">
                <td>Формат даты</td>
                <td>
                  <xtext
                    v-model="form.dateformat"
                    :editable="editable"
                    label="Формат даты"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <template v-if="form.is_numeric">
                <tr>
                  <td title="Текущее значение будет сконвертировано в валюту пользователя. Конвертация обслуживается модулем валют на сайте. Для настройки валют перейдите в соответствующий раздел в модуля валют.">
                    Значение в валюте пользователя
                  </td>
                  <td>
                    <xselect
                      v-model="form.convert"
                      :editable="editable"
                      :options="options.bool"
                      pk="value"
                      tk="text"
                      label="Значение в валюте пользователя"
                      compact
                    />
                  </td>
                </tr>
                <tr>
                  <td>Точность округления</td>
                  <td>
                    <xselect
                      v-model="form.round"
                      :editable="editable"
                      :options="options.round"
                      pk="value"
                      tk="text"
                      label="Точность округления"
                      compact
                    />
                  </td>
                </tr>
                <tr>
                  <td title="Какой символ использовать в качестве десятичного разделителя.">
                    Десятичный разделитель
                  </td>
                  <td>
                    <xselect
                      v-model="form.dec_point"
                      :editable="editable"
                      :options="options.numeric"
                      pk="value"
                      tk="text"
                      label="Десятичный разделитель"
                      compact
                    />
                  </td>
                </tr>
                <tr>
                  <td title="Какой символ использовать в качестве разделителя тысяч.">
                    Разделитель тысяч
                  </td>
                  <td>
                    <xselect
                      v-model="form.thousand"
                      :editable="editable"
                      :options="options.numeric"
                      pk="value"
                      tk="text"
                      label="Разделитель тысяч"
                      compact
                    />
                  </td>
                </tr>
              </template>
              <tr>
                <td title="Текст для вывода перед значением, например, символ валюты.">
                  Префикс
                </td>
                <td>
                  <xtext
                    v-model="form.prefix"
                    :editable="editable"
                    label="Префикс"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Установить пробел между префиксом и значением.">
                  Пробел между префиксом и значением
                </td>
                <td>
                  <xselect
                    v-model="form.prefix_space"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Пробел между префиксом и значением"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Текст для вывода после значения, например, символ валюты.">
                  Суффикс
                </td>
                <td>
                  <xtext
                    v-model="form.suffix"
                    :editable="editable"
                    label="Суффикс"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Установить пробел между суффиксом и значением.">
                  Пробел между суффиксом и значением
                </td>
                <td>
                  <xselect
                    v-model="form.suffix_space"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Пробел между суффиксом и значением"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Регистр букв.">
                  Регистр букв
                </td>
                <td>
                  <xselect
                    v-model="form.letter_case"
                    :editable="editable"
                    :options="options.letterCases"
                    pk="value"
                    tk="text"
                    label="Регистр букв"
                    compact
                  />
                </td>
              </tr>

              <tr class="info">
                <td colspan="2">
                  <span class="font-md">Перезаписать результат</span>
                </td>
              </tr>
              <tr>
                <td title="Текст для переопределения вывода этого поля своим текстом или подстановочными шаблонами.">
                  Текст
                </td>
                <td>
                  <xtext
                    v-model="form.alter_text"
                    :editable="editable"
                    label="Текст"
                    width="300"
                    textarea
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Если отмечено, все символы новой строки (например, \n) преобразуются в HTML-тег BR">
                  Преобразование строк в &lt;br&gt;
                </td>
                <td>
                  <xselect
                    v-model="form.alter_nl2br"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Преобразование строк в &lt;br&gt;"
                    compact
                  />
                </td>
              </tr>
              <template v-if="form.field.type == 'file'">
                <tr>
                  <td>Использовать миниатюру изображения</td>
                  <td>
                    <xselect
                      v-model="form.image_thumb"
                      :editable="editable"
                      :options="options.thumbs"
                      pk="name"
                      tk="label"
                      label="Использовать миниатюру изображения"
                      compact
                    />
                  </td>
                </tr>
                <tr>
                  <td>Подключить галерею</td>
                  <td>
                    <xselect
                      v-model="form.gallery"
                      :editable="editable"
                      :options="options.bool"
                      pk="value"
                      tk="text"
                      label="Подключить галерею"
                      compact
                    />
                  </td>
                </tr>
                <tr>
                  <td title="0 - вывести все изображения">
                    Количество изображений в галерее
                  </td>
                  <td>
                    <xtext
                      v-model="form.gallery_limit"
                      :editable="editable"
                      label="Количество изображений в галерее"
                      width="300"
                      compact
                    />
                  </td>
                </tr>
              </template>

              <tr class="info">
                <td colspan="2">
                  <span class="font-md">Поведение при отсутствии результата</span>
                </td>
              </tr>
              <tr>
                <td title="Применить это содержимое, если результат будет пустым. Вы также можете использовать подстановочные шаблоны.">
                  Текст
                </td>
                <td>
                  <xtext
                    v-model="form.empty_text"
                    :editable="editable"
                    label="Текст"
                    width="300"
                    textarea
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Включите для отображения &quot;текста при отсутствии результатов&quot;, если поле содержит число 0.">
                  Обрабатывать число 0 как пустое значение
                </td>
                <td>
                  <xselect
                    v-model="form.empty_zero"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Обрабатывать число 0 как пустое значение"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td>Скрыть при пустом значении</td>
                <td>
                  <xselect
                    v-model="form.empty_hide"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Скрыть при пустом значении"
                    compact
                  />
                </td>
              </tr>

              <tr class="info">
                <td colspan="2">
                  <span class="font-md">Результат в виде ссылки</span>
                </td>
              </tr>
              <tr>
                <td>Результат в виде ссылки</td>
                <td>
                  <xselect
                    v-model="form.alter_link"
                    :editable="editable"
                    :options="options.bool"
                    pk="value"
                    tk="text"
                    label="Результат в виде ссылки"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Если оставить пустым, то ссылка будет вести на текущий материал. Используйте подстановочный шаблон [value], чтобы вывести значение поля.">
                  Путь ссылки
                </td>
                <td>
                  <xtext
                    v-model="form.alter_link_href"
                    :editable="editable"
                    label="Путь ссылки"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Если оставить пустым, то текстом ссылки будет значение поля. Используйте подстановочный шаблон [content_title], чтобы вывести в текст ссылки заголовок текущего материала.">
                  Текст ссылки
                </td>
                <td>
                  <xtext
                    v-model="form.alter_link_text"
                    :editable="editable"
                    label="Текст ссылки"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Добавить CSS класс к ссылке. Для перечисления нескольких классов используйте пробел.">
                  CSS класс ссылки
                </td>
                <td>
                  <xtext
                    v-model="form.alter_link_class"
                    :editable="editable"
                    label="CSS класс ссылки"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Класс будет присвоен ссылке, URL которой совпадает с текущим URL страницы.">
                  CSS класс активной ссылки
                </td>
                <td>
                  <xtext
                    v-model="form.alter_link_class_active"
                    :editable="editable"
                    label="CSS класс активной ссылки"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td title="Добавляет поясняющий текст к ссылке в виде всплывающей подсказки. Такая подсказка отображается, когда курсор мыши задерживается на ссылке.">
                  Текст в Title
                </td>
                <td>
                  <xtext
                    v-model="form.alter_link_title"
                    :editable="editable"
                    label="Текст в Title"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Rel атрибут
                </td>
                <td>
                  <xtext
                    v-model="form.alter_link_rel"
                    :editable="editable"
                    label="Rel атрибут"
                    width="300"
                    compact
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Target атрибут
                </td>
                <td>
                  <xtext
                    v-model="form.alter_link_target"
                    :editable="editable"
                    label="Target атрибут"
                    width="300"
                    compact
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <section>
            <wildcards :items="wildcards"/>
          </section>
        </template>
        <hr/>
        <div class="pull-right">
          <template v-if="showDelete">
            <submit
              :processing="deleteProcessing"
              class="btn btn-link"
              @click="deleteField"
            >
              <span class="text-danger">
                Удалить
              </span>
            </submit>
            <button
              type="button"
              class="btn btn-link"
              @click="showDelete = false"
            >
              Отменить
            </button>
          </template>
          <template v-else>
            <submit
              :processing="sumbitProcessing"
              :disabled="!editable"
              class="btn btn-link"
              @click="submit"
            >
              Применить
            </submit>
            <button
              type="button"
              class="btn btn-link"
              @click="close"
            >
              Закрыть
            </button>
          </template>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import DisplayFieldInterrelatioins from './DisplayFieldInterrelatioins'
  import Wildcards from '../../../Wildcards'
  import axios from 'axios'

  export default {
    name: 'DisplayFieldEditForm',
    components: {
      Wildcards,
      DisplayFieldInterrelatioins
    },
    props: ['value'],
    data () {
      return {
        form: JSON.parse(JSON.stringify(this.value)),
        wildcards: [],
        showDelete: false,
        editable: false,
        sumbitProcessing: false,
        deleteProcessing: false
      }
    },
    computed: {
      options () {
        return this.$store.state.display.options
      },
      macros () {
        return this.$store.state.display.macros
      }
    },
    methods: {
      submit () {
        this.sumbitProcessing = true
        const {form} = this
        form.interrelationIds = form.interrelations.map(item => {
          return item.form ? item.form.id : item.id
        })
        axios.post(`dview/display/field/${form.id}/edit`, form)
          .then(() => {
            this.$emit('input', JSON.parse(JSON.stringify(form)))
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.sumbitProcessing = false
          })
      },
      deleteField () {
        this.deleteProcessing = true
        axios.post(`dview/display/field/${this.form.id}/delete`)
          .then(() => {
            this.$emit('deleted', this.form.id)
            this.close()
            smallBoxSuccess('Поле отображения удалено.')
          })
          .finally(() => {
            this.deleteProcessing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.wildcards = [
        {label: 'Значение поля', name: '[value]'},
        {label: 'Исходное значение поля', name: '[value_orig]'},
        {label: 'Значение поля Min', name: '[value_min]'},
        {label: 'Значение поля Max', name: '[value_max]'},
        {label: 'Абсолютное значение', name: '[value_abs]'},
        {label: 'Вывод значения скрытого поля', name: '[hidden_<Машинное имя скрытого поля>]'},
        {label: 'Вывод значения элемента массива скрытого поля', name: '[hidden_i_<Машинное имя скрытого поля>]'},
        {label: 'Ссылка на материал', name: '[content_link]'},
        {label: 'Заголовок материала', name: '[content_title]'},
        {label: 'ID материала', name: '[content_id]'},
        {label: 'ID поля', name: '[field_id]'},
        {label: 'CSS (классы "up", "down")', name: '[css_up_down]'}
      ].concat(
        this.macros.map(item => {
          return {
            name: item[0],
            label: item[1]
          }
        })
      )
      this.$modal.show('form')
    }
  }
</script>

<style scoped>
  .smart-form {
    position: relative;
  }
  .delete-field-button {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
  }
</style>