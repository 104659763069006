<template>
  <modal name="form" @closed="$emit('close')" :scrollable="true" height="auto">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          Настроить порядок контекстных фильтров
        </h4>
        <hr/>
        <datatable
          :columns="columns"
          :options="{sortableRows: true}"
          :sort="false"
          :src="items"
          @rowSortDragstart="handleRowSortDragstart"
          @rowSortDragenter="handleRowSortDragenter"
        />
        <hr/>
        <div class="pull-right">
          <submit
            :processing="processing"
            class="btn btn-link"
            @click="submit"
          >
            Применить
          </submit>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import TdLabel from './td/Label'
  import axios from 'axios'

  export default {
    name: 'DisplayContextOrderForm',
    data () {
      const items = JSON.parse(JSON.stringify(this.$store.state.display.form.filterContexts))
        .sort((a, b) => {
          if (a.order < b.order) return -1
          if (a.order > b.order) return 1
          return 0
        })
        .map((item, idx) => {
          item.order = idx
          return item
        })
      return {
        items,
        rowSortMovedId: null,
        processing: false
      }
    },
    computed: {
      columns () {
        return [
          {
            title: '#',
            number: true
          },
          {
            title: 'Контекстный фильтр',
            component: TdLabel
          }
        ];
      },
      display () {
        return this.$store.state.display
      }
    },
    methods: {
      submit () {
        this.processing = true
        const items = this.items.map(item => {
          return {
            id: item.id,
            order: item.order
          }
        })
        axios.post('dview/display/filter/context/order', {items})
          .then(() => {
            this.$store.commit('display/updateFormFilterContexts', this.items)
            this.close()
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      handleRowSortDragstart (movedId) {
        this.rowSortMovedId = movedId
      },
      handleRowSortDragenter (enterId) {
        let movedItem = this.items.find(item => item.id == this.rowSortMovedId)
        let movedOrder = movedItem.order
        let enterItem = this.items.find(item => item.id == enterId)
        let enterOrder = enterItem.order
        movedItem.order = enterOrder
        enterItem.order = movedOrder
        this.items
          .sort((a, b) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>