<template>
  <div>
    <button @click="showSynonymModal = true" type="button" class="btn btn-link no-padding">{{value}}</button>

    <!-- Форма редактирования синонима -->
    <synomym-edit-modal
      v-if="showSynonymModal"
      :value="row"
      @success="$parent.$parent.$refs.synonymsTable.update()"
      @close="showSynonymModal = false"
    />
  </div>
</template>

<script>
  import SynomymEditModal from '../SynonymModal'

  export default {
    components: {SynomymEditModal},
    props: ['row', 'value'],
    data () {
      return {
        showSynonymModal: false
      }
    }
  }
</script>
