<template>
  <input type="hidden" :placeholder="label">
</template>

<script>
  export default {
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: Object,
      src: [String, Array],
      width: String,
      label: {
        type: String,
        default: ''
      },
      pk: {
        type: String,
        'default': 'id' // primary key
      },
      tk: {
        type: String,
        'default': 'name' // text key
      }
    },
    data () {
      return {
        field: null,
        unsaved: false
      }
    },
    computed: {
      id () {
        return this.value[this.pk]
      },
      text () {
        return this.value[this.tk]
      }
    },
    watch: {
      src (value) {
        if (Array.isArray(value)) {
          this.select2refresh()
        }
      }
    },
    methods: {
      select2apply () {
        let beforeId = this.id
        let data = this.field.select2('data')
        if (data) {
          this.unsaved = beforeId != data.id
          if (this.unsaved) {
            let payload = JSON.parse(JSON.stringify(this.value))
            payload[this.pk] = data.id
            payload[this.tk] = data.text
            this.$emit('change', payload)
          }
        }
      },
      select2destroy () {
        this.field.select2('destroy')
      },
      select2init () {
        const vm = this
        const $element = $(this.$el)
        const params = {
          minimumResultsForSearch: 0,
          //placeholder: this.label,
          width: this.width,
          multiple: false
        }
        if (typeof this.src == 'string') {
          params.ajax = {
            quietMillis: 100,
            url () { return vm.src },
            dataType: 'json',
            type: 'GET',
            data (term, page) {
              let offset = page - 1
              return {
                query: term,
                offset: offset
              }
            },
            results (items) {
              let results = []
              items.forEach(item => {
                results.push({
                  id: item[vm.pk],
                  text: String(item[vm.tk])
                })
              })
              return { results: results, more: results.length == 10 }
            }
          }
        } else {
          params.data = this.src
        }

        this.field = $element.select2(params)
        if (this.id) {
          this.field.select2('data', {id: this.id, text: this.text})
        }

        this.field.on('change', () => {
          this.select2apply()
        })
      },
      select2reset () {
        this.field.select2('val', '')
      },
      select2refresh () {
        this.$nextTick(() => {
          this.select2reset()
          this.field.select2({data: this.src, width: this.width})
        })
      }
    },
    mounted () {
      this.select2init()
    },
    destroyed () {
      this.select2destroy()
    }
  }
</script>