<template>
  <span class="xfield" :title="label">
    <div v-if="state" class="xfield-editable">
      <div :class="textarea ? 'xfield-textarea' : ''" class="xfield-input">
        <textarea v-if="textarea" :id="'xtext-' + id" :value="value" :rows="rows" :style="'width:'+(textareaWidth || width)+'px;'" :placeholder="label" class="form-control"></textarea>
        <input v-else :id="'xtext-' + id" :value="value" :style="'width:'+width+'px;'" :placeholder="label" class="form-control" type="text">
        <span v-if="!textarea" @click="clear" class="glyphicon glyphicon-remove-circle"></span>
      </div>
      <button @click="apply" type="button" class="btn btn-primary btn-sm"><i class="glyphicon glyphicon-ok"></i></button>
      <button @click="reset(currentValue)" type="button" class="btn btn-default btn-sm"><i class="glyphicon glyphicon-remove"></i></button>
    </div>
    <div v-else class="xfield-static" :class="{'empty-value':isEmpty, 'compact': (compact && editable), 'unsaved': unsaved}" :style="'width:'+width+'px;'">
      <span v-if="!(!editable && isEmpty)" @click="toggle(true)">
        <span v-html="stateLabel"></span>
        <template v-if="required && isEmpty">
          &nbsp;<sup class="fa fa-asterisk font-xs required"></sup>
        </template>
      </span>
      <span v-else class="text-muted"><i>не задано</i></span>
      <a v-if="!compact && editable" @click.prevent="toggle" href="#" class="toggle font-xs">изменить</a>
    </div>
    <div v-if="error" v-html="error" class="text-danger font-xs"></div>
  </span>
</template>

<script>
  import _uniqueid from 'lodash/uniqueId'

  export default {
    props: {
      value: [String, Number],
      errors: [String, Array],
      required: Boolean,
      width: String,
      textareaWidth: String,
      label: {
        type: String,
        default: ''
      },
      textarea: Boolean,
      rows: {
        type: [String, Number],
        'default': 4
      },
      editable: {
        type: Boolean,
        'default': true
      },
      compact: Boolean
    },
    data () {
      return {
        id: _uniqueid(),
        field: null,
        state: false,
        unsaved: false,
        initValue: null,
        currentValue: null
      }
    },
    methods: {
      apply () {
        this.currentValue = this.field.value
        this.unsaved = this.currentValue != this.initValue
        this.state = false
        this.$emit('input', this.field.value)
        if (this.unsaved) {
          this.$emit('changed', this.field.value)
        }
      },
      reset (value) {
        let isUndefinedValue = (typeof value == 'undefined')
        this.state = false
        this.$emit('input', isUndefinedValue ? this.initValue : value)
        if (isUndefinedValue) {
          this.unsaved = false
          this.currentValue = this.initValue
        }
      },
      clear () {
        this.field.value = ''
        this.field.focus()
      },
      toggle (compactOnly) {
        if (!this.editable) {
          return
        }
        if (compactOnly === true && !this.compact) {
          return
        }
        this.state = !this.state
      },
      on () {
        this.$nextTick(() => {
          if (this.field === null) {
            this.initValue = this.currentValue = this.value
          }
          this.field = document.getElementById('xtext-' + this.id)
          this.field.focus()
        })
        setTimeout(() => {
          document.documentElement.addEventListener('click', this.onDocumentAction, false)
          document.documentElement.addEventListener('keyup', this.onDocumentAction, false)
        }, 100)
      },
      off () {
        document.documentElement.removeEventListener('click', this.onDocumentAction, false)
        document.documentElement.removeEventListener('keyup', this.onDocumentAction, false)
      },
      onDocumentAction (e) {
        if (e.type == 'click') {
          if (!this.$el.contains(e.target)) {
            this.state = false
          }
        } else if (e.type == 'keyup') {
          switch (e.which) {
            case 27: this.reset(this.currentValue); break
            case 13:
              if (!this.textarea) {
                this.apply()
              }
              break
          }
        }
      }
    },
    watch: {
      state (value) {
        if (value) {
          this.on()
        } else {
          this.off()
        }
      }
    },
    computed: {
      error () {
        return (this.errors instanceof Array) ? this.errors.join('<br>') : this.errors
      },
      isEmpty () {
        return !this.value
      },
      stateLabel () {
        let value = this.value || this.label
        if (!this.textarea) {
          return value
        }
        return value
          ? value.split('\n')
            .map(row => $('<div>').html(row).text())
            .join('<br>')
          : ''
      }
    },
    created () {
      this.$parent.$on('success', () => {
        this.initValue = this.value
        this.unsaved = false
      })
    }
  }
</script>