<template>
  <dview
    :block-id="blockId"
    @input="$emit('input', $event)"
    @loaded="$emit('loaded')"
  />
</template>

<script>
  import Dview from './Dview'

  export default {
    name: 'DviewProductAbout',
    components: { Dview },
    props: ['blockId']
  }
</script>