<template>
  <modal name="form" @closed="$emit('close')" height="auto" width="600" :scrollable="true">
    <div class="well no-margin-bottom" style="height: 100%">
      <div v-if="loading">Подготовка...</div>
      <div v-else>
        <h4>
          <strong>{{guglexSynonym ? 'Редактировать' : 'Создать'}} синоним</strong>
        </h4>
        <hr/>
        <div class="smart-form">
          <section>
            <label class="label">Признак</label>
            <xselect2
              label="Выберите признак"
              v-model="form.attribute"
              :src="attributeItems"
              width="100%"
            />
          </section>
          <section>
            <label class="label">Значение</label>
            <xselect2
              label="Выберите значение"
              v-model="form.word"
              tk="value"
              :src="`guglex/synonym/selectFieldValues?attributeId=${form.attribute.id || ''}`"
              width="100%"
            />
          </section>
          <section>
            <label class="label">Синонимы</label>
            <chips v-model="form.synonyms" placeholder="Введите синонимы"/>
            <div class="note">Символ (*) звёздочка допускается в начале и в конце синонима.</div>
          </section>
          <section>
            <label class="label">Минус-слова</label>
            <chips v-model="form.minus_words" placeholder="Введите минус-слова"/>
          </section>
        </div>
      </div>
      <hr/>
      <div class="pull-right">
        <button v-if="guglexSynonym" @click="remove" :disabled="processing || loading" type="button" class="btn btn-link">Удалить</button>
        <submit @click="submit" :processing="processing" :disabled="loading || !formChanged" class="btn btn-link">
          Сохранить
        </submit>
        <button @click="close" type="button" class="btn btn-link">Закрыть</button>
      </div>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    props: ['value'],
    data () {
      const guglexSynonym = this.value || {word: '', attribute_id: null, attribute_label: '', synonyms: [], minus_words: []}
      return {
        attributeItems: [],
        updatedGuglexSynonym: null,
        form: {
          attribute: {
            id: guglexSynonym.attribute_id,
            name: guglexSynonym.attribute_label
          },
          word: {
            id: -1,
            value: guglexSynonym.word
          },
          synonyms: guglexSynonym.synonyms.slice(0),
          minus_words: guglexSynonym.minus_words.slice(0)
        },
        formChanged: false,
        processing: false,
        loading: true
      }
    },
    computed: {
      guglexSynonym () {
        const {value, updatedGuglexSynonym} = this
        return value || updatedGuglexSynonym
      }
    },
    watch: {
      form: {
        deep: true,
        handler () {
          this.formChanged = true
        }
      }
    },
    methods: {
      submit () {
        this.processing = true
        const {guglexSynonym, form} = this
        const {attribute, word, synonyms, minus_words} = form
        const action = guglexSynonym && guglexSynonym.id ? `edit/${guglexSynonym.id}` : 'create'
        const params = {
          attribute_id: attribute.id,
          word: word.value,
          synonyms,
          minus_words
        }
        axios.post(`guglex/synonym/${action}`, params)
          .then(({data}) => {
            this.updatedGuglexSynonym = data
            this.formChanged = false
            this.$emit('success')
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      remove () {
        if (!confirm('Удалить синоним?')) {
          return
        }

        axios.post(`guglex/synonym/delete/${this.guglexSynonym.id}`)
          .then(() => {
            this.$emit('success')
            this.close()
            smallBoxSuccess('Выполнено.')
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
      axios.get('guglex/synonym/selectAttributes')
        .then(({data}) => {
          this.attributeItems = data
            .map(item => {
              return {
                id: item.id,
                text: item.label
              }
            })
          this.loading = false
        })
    }
  }
</script>
