<template>
  <a href="#" class="forn-sm" @click.prevent="">
    {{row.field.label}}
  </a>
</template>

<script>
  export default {
    name: 'Label',
    props: ['value', 'row']
  }
</script>