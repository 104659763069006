<template>
  <modal name="form" @closed="$emit('close')" height="auto">
    <div class="well">
      <h4>Добавить поле в отображение</h4>
      <hr/>
      <div>
        <section>
          <label class="label">Машинное имя</label>
          <label class="input">
            <input v-model="form.name" placeholder="Машинное имя" type="text"/>
          </label>
        </section>
        <section>
          <label class="label">ССК поле</label>
          <xselect2
            v-model="form.field"
            label="Выбор ССК поля"
            tk="label"
            :src="fields"
            width="100%"
          />
        </section>
      </div>
      <hr/>
      <div class="pull-right">
        <submit
          :processing="processing"
          :disabled="!submitable"
          class="btn btn-link"
          @click="submit"
        >
          Применить
        </submit>
        <button
          type="button"
          class="btn btn-link"
          @click="close"
        >
          Закрыть
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DisplayFieldCreateForm',
    data () {
      return {
        form: {
          name: '',
          field: {
            id: null,
            label: ''
          }
        },
        processing: false
      }
    },
    computed: {
      displayId () {
        return this.$store.state.display.form.id
      },
      fields () {
        return this.$store.state.display.fields.map(field => {
          return {
            id: field.id,
            text: `${field.label} (${field.name})`
          }
        })
      },
      submitable () {
        const {form} = this
        return form.name.trim() && form.field.id
      }
    },
    methods: {
      submit () {
        this.processing = true
        const params = {
          name: this.form.name,
          field_id: this.form.field.id
        }
        axios.post(`dview/display/${this.displayId}/field/create`, params)
          .then(({data}) => {
            data.interrelations = []
            this.$emit('created', data)
            this.close()
            smallBoxSuccess('Поле отображения добавлено.')
          })
          .finally(() => {
            this.processing = false
          })
      },
      close () {
        this.$modal.hide('form')
      }
    },
    mounted () {
      this.$modal.show('form')
    }
  }
</script>