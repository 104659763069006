<template>
  <div>
    <div>
      <button @click="$parent.$emit('setEditTab', row.id)" type="button" class="btn btn-link no-padding">
        {{row.label}}
      </button>
    </div>
    <div class="text-muted font-xs">{{row.name}}</div>
  </div>
</template>

<script>
  export default {
    name: 'Name',
    props: ['value', 'row']
  }
</script>