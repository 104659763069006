<template>
  <modal name="filter_form" @closed="$emit('close')" :scrollable="true" height="auto" width="600">
    <div class="no-margin-bottom" style="height: 100%">
      <div class="well">
        <h4>
          {{isCreate ? 'Создать' : 'Редактировать'}} фильтр
        </h4>
        <hr/>
        <section>
          <label class="label">
            ССК поле
          </label>
          <label class="select">
            <xselect2
              v-model="field"
              label="Выбор ССК поля"
              tk="label"
              :src="fields"
              width="100%"
              @change="form.field_id = $event.id"
            />
          </label>
        </section>
        <section>
          <label class="label">
            Оператор
          </label>
          <label class="select">
            <select v-model="form.operator">
              <option :value="null" selected disabled>Выберите оператор</option>
              <option value="=">=</option>
              <option value="!=">!=</option>
              <option value=">">&gt;</option>
              <option value=">=">&gt;=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value="in">in</option>
            </select>
            <i></i>
          </label>
        </section>
        <section>
          <label class="checkbox">
            <input
              v-model="form.value_by_cid"
              :true-value="1"
              :false-value="0"
              type="checkbox"
            />
            <i></i> Получить значение из материала с идентификатором в GET запросе
          </label>
        </section>
        <section v-if="!form.value_by_cid">
          <label class="label">
            Значение по умолчанию
          </label>
          <label class="input">
            <input
              v-model="form.value"
              placeholder="Значение по умолчанию"
              class="form-control"
              type="text"
            />
          </label>
          <div class="note">
            Если используется оператор "in", то значения по умолчанию нужно перечислять через запятую.
          </div>
        </section>
        <hr/>
        <div class="pull-right">
          <button
            class="btn btn-link"
            type="button"
            @click="apply"
          >
            Применить
          </button>
          <button
            type="button"
            class="btn btn-link"
            @click="close"
          >
            Закрыть
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </modal>
</template>

<script>
  import _uniqueId from 'lodash/uniqueId'

  export default {
    name: 'FilterForm',
    props: ['value', 'displayLinkId'],
    data () {
      const form = typeof this.value == 'undefined'
        ? this.getEmptyForm()
        : JSON.parse(JSON.stringify(this.value))
      const field = this.$store.state.display.fields.find(field => field.id == form.field_id)
      return {
        form,
        field: {
          id: field ? field.id : null,
          label: field ? `${field.label} (${field.name})` : null
        }
      }
    },
    computed: {
      isCreate () {
        return typeof this.value == 'undefined'
      },
      fields () {
        return this.$store.state.display.fields.map(field => {
          return {
            id: field.id,
            text: `${field.label} (${field.name})`
          }
        })
      }
    },
    watch: {
      'form.value_by_cid' (flag) {
        if (flag) {
          this.form.value = null
        }
      }
    },
    methods: {
      apply () {
        let error
        if (!this.form.field_id) {
          error = 'Выберите ССК поле.'
        } else if (!this.form.operator) {
          error = 'Выберите оператор.'
        }
        if (error) {
          smallBoxErrors(error)
          return
        }
        this.$emit('input', this.form)
        this.close()
      },
      getEmptyForm () {
        return {
          id: -_uniqueId(),
          display_link_id: this.displayLinkId,
          field_id: null,
          operator: null,
          value_by_cid: 1,
          value: null
        }
      },
      close () {
        this.$modal.hide('filter_form')
      }
    },
    mounted () {
      this.$modal.show('filter_form')
    }
  }
</script>