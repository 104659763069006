<template>
  <div class="well well-sm">
    <button @click="showAttributeModal = true" type="button" class="btn btn-link font-sm">
      <i class="fa fa-plus"></i> Создать признак
    </button>
    <datatable
      :columns="columns"
      src="guglex/attribute/list"
      ref="attributesTable"
    />
    <!-- Форма редактирования признака -->
    <attribute-edit-modal
      v-if="showAttributeModal"
      @edit="handleAttributeEdit"
      @remove="handleAttributeRemove"
      @close="showAttributeModal = false"
    />
  </div>
</template>

<script>
  import AttributeEditModal from './AttributeModal'
  import TdLabel from './td/Label'

  export default {
    components: {AttributeEditModal},
    data () {
      return {
        items: [],
        showAttributeModal: false
      }
    },
    computed: {
      columns () {
        return [
          {title: 'Название', field: 'label', component: TdLabel, tdClass: 'text-nowrap'},
          {title: 'CCK поле', field: 'field_label', tdClass: 'text-nowrap'},
          {title: 'Не применять для альтернативного расчёта', field: 'except_alt', handler: value => value ? 'Да' : '-'}
        ]
      }
    },
    methods: {
      handleAttributeEdit () {
        this.$refs.attributesTable.update()
        return true
      },
      handleAttributeRemove () {
        this.$refs.attributesTable.update()
      }
    }
  }
</script>
