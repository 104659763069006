<template>
  <div>
    <div v-if="editable">
      <div v-if="items.length">
        <div v-if="active">
          <div
            v-for="(item, idx) in items"
            :key="item.key"
            class="select-interrelation"
          >
            <xselect2
              v-model="item.form"
              label="Выбор взаимосвязи"
              tk="label"
              :src="interrelations"
              width="65%"
            />
            <template v-if="idx == 0">
              <button
                class="btn btn-primary"
                type="button"
                @click="addItem"
              >
                <i class="fa fa-plus"/>
              </button>
              <div style="display: inline">
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="removeItem(0)"
                >
                  <i class="fa fa-minus"/>
                </button>
                <button
                  class="btn btn-success"
                  type="button"
                  @click="emit"
                >
                  <i class="fa fa-check"/>
                </button>
                <button
                  class="btn btn-default"
                  type="button"
                  @click="reset"
                >
                  <i class="fa fa-times"/>
                </button>
              </div>
            </template>
            <button
              v-else
              class="btn btn-primary"
              type="button"
              @click="removeItem(idx)"
            >
              <i class="fa fa-minus"/>
            </button>
          </div>
        </div>
        <div v-else>
          <ol>
            <li
              v-for="item in items"
              :key="item.form.label"
            >
              <button
                class="btn btn-link font-sm no-padding"
                type="button"
                @click="active = true"
              >
                {{item.form.label}}
              </button>
            </li>
          </ol>
        </div>
      </div>
      <button
        v-else
        class="btn btn-link font-sm no-padding"
        @click="addItem"
      >
        Выбор взаимосвязи
      </button>
    </div>
    <div v-else>
      <template v-if="items.length">
        <ol>
          <li
            v-for="item in items"
            :key="item.form.label"
          >
            {{item.form.label}}
          </li>
        </ol>
      </template>
      <i v-else class="text-muted">не задано</i>
    </div>
  </div>
</template>

<script>
  import _uniqueId from 'lodash/uniqueId'

  export default {
    name: 'DisplayFieldInterrelatioins',
    props: ['value', 'editable'],
    data () {
      const items = JSON.parse(JSON.stringify(this.value)).map(item => {
        item.key = _uniqueId()
        if (!item.form) {
          item.form = {
            id: item.id,
            label: item.label
          }
        }
        return item
      })
      const itemsOrig = JSON.parse(JSON.stringify(items))

      return {
        items,
        itemsOrig,
        active: false
      }
    },
    computed: {
      interrelations () {
        return this.$store.state.display.interrelations.map(item => {
          return {
            id: item.id,
            text: item.label
          }
        })
      }
    },
    methods: {
      addItem () {
        this.active = true
        this.items.push({
          key: _uniqueId(),
          form: {
            id: null,
            label: ''
          }
        })
      },
      removeItem (idx) {
        this.items.splice(idx, 1)
        this.emit()
      },
      reset () {
        this.items = JSON.parse(JSON.stringify(this.itemsOrig))
        this.$emit('input', JSON.parse(JSON.stringify(this.items)))
        this.active = false
      },
      emit () {
        this.items = this.items.filter(item => item.form.id)
        this.$emit('input', JSON.parse(JSON.stringify(this.items)))
        this.active = false
      }
    }
  }
</script>

<style scoped>
  ol {
    margin-left: 14px;
  }
  ol button {
    margin-top: -3px;
  }
  .select-interrelation {
    margin-bottom: 5px;
  }
</style>