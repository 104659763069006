<template>
  <div>
    <h5>Настройки "Changefreq" и "Priority" для типов материала</h5>
    <datatable
      :columns="columns"
      src="siteconf/sitemap/contentTypes/list"
      @payload="payload"
      @edit="edit"
    />
    <edit-form-modal
      v-if="form"
      :row="form"
      :changefreqOptions="changefreqOptions"
      :priorityOptions="priorityOptions"
      @close="form = null"
    />
  </div>
</template>

<script>
  import EditFormModal from './EditFormModal'
  import TdLabel from './td/Label'

  export default {
    name: 'ContentTypes',
    components: { EditFormModal },
    data () {
      return {
        form: null,
        changefreqOptions: null,
        priorityOptions: null
      }
    },
    computed: {
      columns () {
        return [
          {
            title: 'Тип материала',
            field: 'name',
            filter: {type: 'text', width: '100%'},
            component: TdLabel,
            tdClass: 'text-nowrap'
          },
          {
            title: 'Changefreq',
            field: 'changefreq'
          },
          {
            title: 'Priority',
            field: 'priority'
          }
        ]
      }
    },
    methods: {
      edit (row) {
        this.form = row
      },
      payload ({changefreqOptions, priorityOptions}) {
        this.changefreqOptions = changefreqOptions
        this.priorityOptions = priorityOptions
      }
    }
  }
</script>