<template>
  <div class="smart-form well no-padding">
    <fieldset>
      <section>
        <header v-if="contentType.label">
          <span class="text-info">{{ contentType.label }}</span> - ССК поля
          <button-menu
            title="Выбор полей"
            icon="fa fa-plus"
            align="right"
            btn-class="btn btn-default btn-xs"
            class="pull-right"
            style="margin-top: -5px;"
            :items="{createField: 'Создать новое поле', bindFields: 'Подключить поля'}"
            @createField="showFieldCreateModal = true"
            @bindFields="showFieldsBindModal = true"
          />
          <div class="clearfix"></div>
        </header>
        <div v-else class="text-muted">Загрузка...</div>
      </section>
      <section>
        <datatable
          :columns="columns"
          :options="{sortableRows: true, limit: 0}"
          :td="{contentTypeId}"
          :src="`contentType/${contentTypeId}/fields/list`"
          ref="fieldsTable"
          @payload="handlePayload"
          @rowSortDragstart="handleRowSortDragstart"
          @rowSortDragenter="handleRowSortDragenter"
          @rowSortDragend="handleRowSortDragend"
        />
      </section>
    </fieldset>
    <fields-bind-modal
      v-if="showFieldsBindModal"
      :content-type="contentType"
      @success="fieldsTable.update()"
      @close="showFieldsBindModal = false"
    />
    <field-create-modal
      v-if="showFieldCreateModal"
      @created="handleFieldCreated"
      @close="showFieldCreateModal = false"
    />
  </div>
</template>

<script>
  import FieldCreateModal from './ContentTypeFieldCreateModal'
  import FieldsBindModal from './bind/ContentTypeFieldsBindModal'
  import TdLabel from './td/Label'
  import TdRequired from './td/Required'
  import axios from 'axios'

  export default {
    name: 'ContentTypeFields',
    components: { FieldCreateModal, FieldsBindModal },
    props: ['contentTypeId'],
    data () {
      return {
        rowSortMovedFieldId: null,
        showFieldsBindModal: false,
        showFieldCreateModal: false,
        contentType: {
          label: null
        }
      }
    },
    computed: {
      columns () {
        return [
          {title: '#', field: 'order'},
          {
            title: 'Метка',
            field: 'label',
            filter: {type: 'text', width: '90%'},
            tdClass: 'text-nowrap',
            component: TdLabel
          },
          {title: 'Машинное имя', field: 'name', filter: {type: 'text', width: '90%'}, tdClass: 'text-nowrap'},
          {title: 'Тип поля', field: 'type', handler: value => value.charAt(0).toUpperCase() + value.slice(1)},
          {title: 'Обязательное поле', field: 'required', component: TdRequired}
        ]
      },
      fieldsTable () {
        return this.$refs.fieldsTable
      }
    },
    methods: {
      async handleFieldCreated (fieldId) {
        const params = {
          fieldId,
          flag: true
        }
        await axios.post(`contentType/${this.contentTypeId}/fields/bind`, params)
        this.fieldsTable.update()
        this.showFieldCreateModal = false
      },
      handlePayload (contentType) {
        this.contentType = contentType
      },
      handleRowSortDragstart (movedFieldId) {
        this.rowSortMovedFieldId = movedFieldId
      },
      handleRowSortDragenter (enterFieldId) {
        const fields = this.fieldsTable.data
        const movedField = fields.find(field => field.id == this.rowSortMovedFieldId)
        const movedOrder = movedField.order
        const enterField = fields.find(field => field.id == enterFieldId)
        const enterOrder = enterField.order
        movedField.order = enterOrder
        enterField.order = movedOrder
        fields
          .sort((a, b) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          })
      },
      handleRowSortDragend () {
        const fields = this.fieldsTable.data.map(field => field.id)
        axios.post(`contentType/${this.contentTypeId}/fields/order`, {fields})
          .then(() => {
            this.fieldsTable.update()
            smallBoxSuccess('Выполнено.')
          })
      }
    }
  }
</script>