<template>
  <component
    :is="'Html'"
    :block-id="blockId"
    @input="$emit('input', $event)"
    @loaded="$emit('loaded')"
  />
</template>

<script>
  import Html from './Html'

  export default {
    name: 'FooterText',
    components: { Html },
    props: ['blockId']
  }
</script>