<template>
  <div class="smart-form well">
    <section style="position: relative;">
      <h2>Импорт материалов</h2>
      <div style="position: absolute; right:0; top:0;">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="reset"
          >
            <i class="fa fa-trash-o"></i> Сбросить импорт
          </button>
          <submit
            :processing="processing"
            :disabled="isUploading || isImporting || !conf"
            class="btn btn-success btn-sm"
            icon="fa fa-rocket"
            @click="start"
          >
            Запустить импорт
          </submit>
        </div>
      </div>
    </section>
    <div v-if="conf">
      <div class="smart-form">
        <section>
          <table class="table table-bordered table-striped table-hover">
            <tbody>
              <tr>
                <td style="width: 15%;">Режим импорта</td>
                <td>{{ conf.mode == 'insert' ? 'Вставка новых материалов' : 'Обновление существующих материалов' }}</td>
              </tr>
              <tr>
                <td>Тип материала</td>
                <td>{{ conf.contentType.label }}</td>
              </tr>
              <tr>
                <td>Файл импорта</td>
                <td>{{ conf.filename }}</td>
              </tr>
              <tr>
                <td nowrap>Время загрузки файла</td>
                <td>{{ new Date(conf.parse_time).toLocaleString() }}</td>
              </tr>
              <tr>
                <td>Ответственный</td>
                <td>{{ conf.user.email }}</td>
              </tr>
              <tr>
                <td>Всего строк в файле</td>
                <td>{{ conf.totalCount }}</td>
              </tr>
              <tr>
                <td>Строк с ошибками</td>
                <td>{{ conf.errorCount }}</td>
              </tr>
              <tr>
                <td>Импортировано</td>
                <td>{{ conf.importedCount }}</td>
              </tr>
              <tr>
                <td>Готово к импорту</td>
                <td>{{ conf.totalCount - conf.errorCount - conf.importedCount }}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>

      <div v-if="isUploading">
        Обработка файла...&nbsp;
        <span v-if="conf.totalCount">{{ parseInt(conf.processedCount / conf.totalCount * 100) }}%</span>
      </div>
      <div
        v-else
        style="position: relative;"
      >
        <h4 style="margin-bottom: 10px;">Содержимое файла</h4>
        <div style="position: absolute; right:0; top:-3px;">
          <div class="btn-group">
            <label
              class="btn btn-default btn-sm"
              :class="{'active': filter.filter == 'errors'}"
            >
              <input
                v-model="filter.filter"
                value="errors"
                class="radiobox"
                type="radio"
              />
              Показать строки с ошибками
            </label>
            <label
              class="btn btn-default btn-sm"
              :class="{'active': filter.filter == 'success'}"
            >
              <input
                v-model="filter.filter"
                value="success"
                class="radiobox"
                type="radio"
              />
              Показать без ошибок
            </label>
            <label
              class="btn btn-default btn-sm"
              :class="{'active': filter.filter == 'all'}"
            >
              <input
                v-model="filter.filter"
                value="all"
                class="radiobox"
                type="radio"
              />
              Показать все
            </label>
          </div>
        </div>
        <datatable
          :filter="filter"
          :columns="columns"
          :options="{tableClass: 'table-bordered'}"
          :rows="{trClass}"
          src="content/import/list"
          ref="datatable"
        />
      </div>
    </div>
    <div v-else>
      {{ isUploading ? 'Загрузка файла...' : 'Подготовка...' }}
    </div>
  </div>
</template>

<script>
  import TdValue from './TdValue'
  import TdLine from './TdLine'
  import axios from 'axios'

  export default {
    name: 'ContentImportPreview',
    data () {
      return {
        status: null,
        statusIntervalId: null,
        processing: false,
        filter: {
          filter: 'all'
        },
      }
    },
    computed: {
      conf () {
        return this.status?.conf
      },
      isUploading () {
        return Boolean(this.status?.isUploading)
      },
      isImporting () {
        return Boolean(this.status?.isImporting)
      },
      noConfNoUploading () {
        return !this.conf && !this.isUploading
      },
      columns () {
        const columns = this.status.fileColumns.map(column => {
          return {
            title: column.label,
            field: column.field,
            component: TdValue,
            sort: false
          }
        })
        columns.unshift({
          title: '№',
          component: TdLine
        })
        return columns
      }
    },
    watch: {
      noConfNoUploading (flag) {
        if (flag) {
          this.close()
        }
      },
      isImporting (newValue, oldValue) {
        if (oldValue && !newValue) {
          this.processing = false
          this.$refs.datatable.update()
        }
      },
      'filter.filter' () {
        this.$refs.datatable.applyFilter('filter')
      }
    },
    methods: {
      reset () {
        axios.post('content/import/reset')
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
      },
      start () {
        this.processing = true
        axios.post('content/import/start')
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
      },
      watchStatus () {
        this.statusIntervalId = setInterval(async () => {
          const {data} = await axios.get('content/import/status')
          this.status = data
        }, 1000)
      },
      stopStatus () {
        clearInterval(this.statusIntervalId)
      },
      close () {
        this.stopStatus()
        this.$emit('close')
      },
      trClass (row) {
        return row['@line_errors'].length || Object.keys(row['@errors']).length
          ? 'bg-danger'
          : (row['@imported'] ? 'bg-success' : null)
      }
    },
    async mounted () {
      this.watchStatus()
    },
    beforeDestroy () {
      this.stopStatus()
    }
  }
</script>