<template>
  <div>
    <a @click.prevent="showAttributeModal = true" href="#" class="btn btn-link no-padding">{{value}}</a>

    <!-- Форма редактирования признака -->
    <attribute-edit-modal
      v-if="showAttributeModal"
      :value="row"
      :contentTypeId="contentTypeId"
      @edit="handleAttributeEdit"
      @remove="handleAttributeRemove"
      @close="showAttributeModal = false"
    />
  </div>
</template>

<script>
  import AttributeEditModal from '../AttributeModal'

  export default {
    components: {AttributeEditModal},
    props: ['row', 'value', 'td'],
    data () {
      return {
        showAttributeModal: false
      }
    },
    computed: {
      contentTypeId () {
        return this.td ? this.td.contentTypeId : null
      }
    },
    methods: {
      handleAttributeEdit (attribute) {
        this.showAttributeModal = this.$parent.$parent.handleAttributeEdit(attribute)
      },
      handleAttributeRemove (id) {
        this.$parent.$parent.handleAttributeRemove(id)
      }
    }
  }
</script>
