<template>
  <section class="col col-6">
    <section>
      <header>Страничное представление</header>
    </section>
    <section>
      <label class="checkbox">
        <input
          v-model="allowBlockParams"
          :true-value="1"
          :false-value="0"
          type="checkbox"
        />
        <i></i> Разрешить настройки блочного представления
      </label>
    </section>
    <section>
      <label class="checkbox">
        <input
          v-model="multiple"
          :true-value="1"
          :false-value="0"
          type="checkbox"
        />
        <i></i> Разрешить множественные значения
      </label>
      <div class="note">Для значений вида "1+2+3" будет применён оператор "И", а для значений вида "1,2,3" применяется оператор "ИЛИ".</div>
    </section>
    <section>
      <label class="label">Исключаемое значение</label>
      <label class="input">
        <label class="input">
          <input
            v-model="exParam"
            placeholder="Исключаемое значение"
          />
        </label>
      </label>
      <div class="note">Если это значение получено, фильтр будет проигнорирован.</div>
    </section>
    <section>
      <label class="label">Имя параметра в строке запроса</label>
      <label class="input">
        <label class="input">
          <input
            v-model="paramName"
            placeholder="Имя параметра в строке запроса"
          />
        </label>
      </label>
      <div class="note">
        <p>Имя параметра по умолчанию - <b>{{form.field.name}}</b>.</p>
        <p>Вы можете задать своё имя параметра для текущего фильтра.</p>
        <p>Имя параметра должно соответствовать маске <code>[a-z0-9_-]+</code></p>
      </div>
    </section>
    <section>
      <label class="checkbox">
        <input
          v-model="translit"
          :true-value="1"
          :false-value="0"
          type="checkbox"
        />
        <i></i> Применить транслитерацию значения
      </label>
      <div class="note">Если включить, то будет применена транслитерация значения этого поля.</div>
    </section>
  </section>
</template>

<script>
  export default {
    name: 'PageForm',
    computed: {
      display () {
        return this.$store.state.display
      },
      form () {
        return JSON.parse(JSON.stringify(this.display.filterContextForm))
      },
      allowBlockParams: {
        get () {
          return this.form.allow_block_params
        },
        set (value) {
          const {form} = this
          form.allow_block_params = value
          this.updateForm(form)
        }
      },
      multiple: {
        get () {
          return this.form.multiple
        },
        set (value) {
          const {form} = this
          form.multiple = value
          this.updateForm(form)
        }
      },
      exParam: {
        get () {
          return this.form.ex_param
        },
        set (value) {
          const {form} = this
          form.ex_param = value
          this.updateForm(form)
        }
      },
      paramName: {
        get () {
          return this.form.param_name
        },
        set (value) {
          const {form} = this
          form.param_name = value
          this.updateForm(form)
        }
      },
      translit: {
        get () {
          return this.form.translit
        },
        set (value) {
          const {form} = this
          form.translit = value
          this.updateForm(form)
        }
      }
    },
    methods: {
      updateForm (form) {
        this.$store.commit('display/setFilterContextForm', form)
      }
    }
  }
</script>