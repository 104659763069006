<template>
  <div :title="error">
    {{row['@line']}}
  </div>
</template>

<script>
  export default {
    name: 'Td',
    props: ['value', 'row'],
    computed: {
      error () {
        return this.row['@line_errors'].length
          ? this.row['@line_errors'].join('\n')
          : null
      }
    }
  }
</script>