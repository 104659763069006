<template>
  <div>
    <div>
      <button @click="$parent.$emit('setDisplayTab', row.id)" type="button" class="btn btn-link no-padding">
        {{value}}
      </button>
    </div>
    <div class="text-muted font-xs">{{row.name}}</div>
  </div>
</template>

<script>
  export default {
    name: 'Label',
    props: ['row', 'value']
  }
</script>