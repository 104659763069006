<template>
  <div>
    <div v-if="loading" class="well well-sm text-muted">Подготовка...</div>
    <div v-else class="smart-form well no-padding">
      <fieldset>
        <div class="alert alert-warning fade in">
          <i class="fa-fw fa fa-warning"></i>
          Это действие нельзя отменить!
        </div>
        <table class="table table-hover">
          <tbody>
            <tr>
              <td class="txt-color-blueDark">Имя поля:</td>
              <td>{{form.label}}</td>
            </tr>
            <tr>
              <td class="txt-color-blueDark">Машинное имя:</td>
              <td>{{form.name}}</td>
            </tr>
            <tr>
              <td class="txt-color-blueDark">Материалы:</td>
              <td>{{usedInfo.contents}}</td>
            </tr>
            <tr>
              <td class="txt-color-blueDark">Раскрытые фильтры:</td>
              <td>{{usedInfo.expandFilters}}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <footer>
        <submit @click="submit" :processing="processing" class="btn btn-danger" icon="fa fa-trash-o">
          Удалить
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'FieldDeleteForm',
    props: ['fieldId'],
    data () {
      return {
        form: {
          name: null,
          label: null
        },
        usedInfo: {
          contents: 0,
          expandFilters: 0
        },
        loading: true,
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post(`field/${this.fieldId}/delete`)
          .then(() => {
            this.$emit('deleted')
            smallBoxSuccess('Поле удалено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    async created () {
      const {data} = await axios.get(`field/${this.fieldId}/get`)
      this.form = data.field
      this.usedInfo = data.usedInfo
      this.loading = false
    }
  }
</script>

<style scoped>
  td:first-child {
    white-space: nowrap;
  }
  td:first-child {
    font-weight: bold;
    text-align: right;
    width: 10px;
  }
</style>