import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VModal from 'vue-js-modal'
import Submit from './components/Submit'
import Xselect2 from './components/Xselect2'
import Chips from './components/Chips'
import ButtonMenu from './components/ButtonMenu'
import BsSwitcher from './components/Bs-Switcher'
import Datatable from './components/datatable/Datatable'
import Popper from './components/Popper'
import Xtext from './components/Xtext'
import Xselect from './components/Xselect'
import VpEditor from './components/VpEditor'
import 'babel-polyfill'
import './backend.css'

// Разделы админки на Vue.js
import Gallery from '@/components/gallery/Gallery'
import ContentExport from './components/content/Export.vue'
import Guglex from '@/components/guglex/Guglex'
import ContentTypes from '@/components/content_types/ContentTypes'
import Fields from './components/fields/Fields.vue'
import Blocks from '@/components/blocks/Blocks'
import Dviews from './components/dviews/Dviews'
import Interrelations from './components/interrelations/Interrelations'
import Sharelink from './components/sharelink/Sharelink'
import SiteConf from './components/siteconf/SiteConf'
import ContentImport from './components/content/import/ContentImport'
import Subscribers from './components/subscribers/Subscribers'

// plugins
import './plugins/v-tooltip/v-tooltip'

// store
import storeCommon from './store/common'
import storeErrors from './store/errors'
import storeCookies from './store/cookies'
import storeDisplay from './store/display'

Vue.config.devtools = true

Vue.use(Vuex)
Vue.use(VModal, { dynamic: true, injectModalsContainer: true })
Vue.component('submit', Submit)
Vue.component('xselect2', Xselect2)
Vue.component('chips', Chips)
Vue.component('button-menu', ButtonMenu)
Vue.component('bs-switcher', BsSwitcher)
Vue.component('datatable', Datatable)
Vue.component('popper', Popper)
Vue.component('xtext', Xtext)
Vue.component('xselect', Xselect)
Vue.component('vp-editor', VpEditor)

const store = new Vuex.Store({
  modules: {
    common: storeCommon,
    errors: storeErrors,
    cookies: storeCookies,
    display: storeDisplay
  }
});

new Vue({
  store,
  watch: {
    '$store.state.errors.error': {
      deep: true,
      handler (error) {
        const status = error.status
        if (status !== null) {
          const errors = error.items
          let message = `Произошла ошибка ${status}`
          if (status == 422) {
            if (errors.length > 1) {
              message = `<ul style="margin: 0 -20px;">${errors.map(error => error.replace(/(.+)/, '<li style="margin: 5px auto">$1</li>')).join('')}</ul>`
            } else {
              message = errors[0]
            }
          }
          smallBoxErrors(message)
          this.$store.commit('errors/clear')
        }
      }
    }
  }
})

document.documentElement.addEventListener('click', function (e) {store.commit('common/domDocumentEventFired', e)}, false)
document.documentElement.addEventListener('keyup', function (e) {store.commit('common/domDocumentEventFired', e)}, false)

axios.defaults.baseURL = process.env.MIX_ADMIN_ROOT
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.withCredentials = true;
axios.interceptors.response.use((response) => response, (error) => {
  const response = error.response
  const status = response.status
  const errors = response.data
  store.commit('errors/add', {status, errors})
  throw error;
});

window.__admin = {}
window.__admin.galleryInit = function (elem, config) {
  window.__admin.pageContentVue = new Vue({
    data: {config},
    render: (h) => {
      return h(Gallery)
    }
  }).$mount(elem)
}

window.__admin.contentExportInit = function (elem, config) {
  window.__admin.pageContentVue = new Vue({
    store,
    data: {config},
    render: (h) => {
      return h(ContentExport)
    }
  }).$mount(elem)
}

window.__admin.guglexInit = function (elem, config) {
  window.__admin.pageContentVue = new Vue({
    store,
    data: {config},
    render: (h) => {
      return h(Guglex)
    }
  }).$mount(elem)
}

window.__admin.contentTypesInit = function (elem) {
  window.__admin.pageContentVue = new Vue({
    store,
    data: {},
    render: (h) => {
      return h(ContentTypes)
    }
  }).$mount(elem)
}

window.__admin.fieldsInit = function (elem) {
  window.__admin.pageContentVue = new Vue({
    data: {},
    render: (h) => {
      return h(Fields)
    }
  }).$mount(elem)
}

window.__admin.blocksInit = function (elem) {
  window.__admin.pageContentVue = new Vue({
    store,
    data: {},
    render: (h) => {
      return h(Blocks)
    }
  }).$mount(elem)
}

window.__admin.dviewsInit = function (elem) {
  window.__admin.pageContentVue = new Vue({
    store,
    data: {},
    render: (h) => {
      return h(Dviews)
    }
  }).$mount(elem)
}

window.__admin.interrelationsInit = function (elem, config) {
  window.__admin.pageContentVue = new Vue({
    store,
    data: {config},
    render: (h) => {
      return h(Interrelations)
    }
  }).$mount(elem)
}

window.__admin.sharelinkInit = function (elem, config) {
  window.__admin.pageContentVue = new Vue({
    store,
    data: {config},
    render: (h) => {
      return h(Sharelink)
    }
  }).$mount(elem)
}

window.__admin.siteConfInit = function (elem) {
  window.__admin.pageContentVue = new Vue({
    data: {},
    render: (h) => {
      return h(SiteConf)
    }
  }).$mount(elem)
}

window.__admin.contentImportInit = function (elem, config) {
  window.__admin.pageContentVue = new Vue({
    data: {config},
    render: (h) => {
      return h(ContentImport)
    }
  }).$mount(elem)
}

window.__admin.subscribersInit = function (elem, config) {
  window.__admin.pageContentVue = new Vue({
    data: {config},
    render: (h) => {
      return h(Subscribers)
    }
  }).$mount(elem)
}