<template>
  <div>
    <h5>
      <button @click="show = !show" class="btn btn-link no-padding">
        Подстановочные переменные <i class="fa" :class="show ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
      </button>
    </h5>
    <table v-if="show" class="table table-hover fields-wildcards">
      <tbody>
        <tr v-for="item in items">
          <td>{{item.label}}</td>
          <td v-html="item.name"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'Wildcards',
    props: ['items'],
    data () {
      return {
        show: false
      }
    }
  }
</script>

<style scoped>
  .fields-wildcards td:first-child {
    width: 20%
  }
  .fields-wildcards td:last-child {
    font-weight: bold
  }
  .fields-wildcards td {
    padding: 2px 10px;
    white-space: nowrap;
  }
  .fields-wildcards p {
    margin:0
  }
</style>