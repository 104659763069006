<template>
  <div class="tabs-body">
    <ul class="nav nav-tabs">
      <li
        v-for="tab in tabs"
        :key="tab.name"
        :class="{active: tab.name == activeTab}"
      >
        <a @click.prevent="setActiveTab(tab)" href="#">{{tab.title}}</a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active">
        <component :is="activeTab"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Order from './Order'
  import ContentTypes from './content_types/ContentTypes'
  import Attributes from './attributes/Attributes'
  import Synonyms from './synonyms/Synonyms'
  import Settings from './Settings'

  export default {
    components: {Order, ContentTypes, Attributes, Synonyms, Settings},
    data () {
      return {
        activeTab: _hash_struct.tab || 'Order',
        tabs: [
          {title: 'Рассчитать смету', name: 'Order'},
          {title: 'Типы материалов', name: 'ContentTypes'},
          {title: 'Признаки', name: 'Attributes'},
          {title: 'Синонимы', name: 'Synonyms'},
          {title: 'Настройки', name: 'Settings'}
        ]
      }
    },
    methods: {
      setActiveTab (tab) {
        this.activeTab = tab.name
        CHECK_URL_ON_HASH_CHANGE = false;
        window.location.hash = `guglex?tab=${tab.name}`
      }
    }
  }
</script>