<template>
  <div class="well well-sm">
    <fieldset>
      <submit
        @click="submit"
        :processing="processing"
        class="btn btn-primary"
        icon="fa fa-trash-o"
      >
        Очистить весь кеш сайта
      </submit>
    </fieldset>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Cache',
    data () {
      return {
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post('siteconf/cache/purge')
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    }
  }
</script>