<template>
  <modal name="form" @closed="$emit('close')" :scrollable="true" height="auto" width="700">
    <div class="well no-margin-bottom">
      <h4><strong>Загрузить файлы</strong></h4>
      <hr/>
      <table class="table">
        <tbody>
          <tr v-for="(item, i) in arrayFiles" :key="item.file.name">
            <td class="width-1">{{Number(i) + 1}}.</td>
            <td class="width-1 text-nowrap">
              <div style="padding: 5px 0;"><strong>{{item.file.name}}</strong></div>
              <div>
                <input v-model="item.alt" type="text" title="Альтернативный текст" placeholder="Альтернативный текст"/>
              </div>
              <div>
                <input v-model="item.videoUrl" type="text" title="ID YouTube видео" placeholder="ID YouTube видео"/>
              </div>
            </td>
            <td class="width-1 text-nowrap">
              <file-to-base64 :file="item.file">
                <template  slot-scope="{ext, src, isImage, base64String, height, width}">
                  <div v-if="isImage">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <span v-if="width && height" class="font-xs">{{width}}&times;{{height}}</span>
                            <br/>
                            <span class="font-xs">{{fileSizeText(item.file.size)}}</span>
                          </td>
                          <td style="width: 50px;">
                            <img :src="src" height="80"/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </file-to-base64>
            </td>
            <td>
              <div @click="arrayFiles.splice(i, 1)" class="delete-item" title="Исключить из загрузки"></div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" class="text-nowrap">
              Общий размер: <strong :class="{'text-danger': uploadMaxFilesizeExceeded}">{{fileSizeText(totalSize)}}</strong>
              <span v-if="uploadMaxFilesizeExceeded">(Макс.: {{fileSizeText(uploadMaxFilesize)}})</span>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-nowrap">
              Кол-во файлов: <strong :class="{'text-danger': maxFileUploadsExceeded}">{{arrayFiles.length}}</strong>
              <span v-if="maxFileUploadsExceeded">(Макс.: {{maxFileUploads}})</span>
            </td>
          </tr>
        </tfoot>
      </table>
      <hr/>
      <button @click="submit" type="button" class="btn btn-primary pull-right" :disabled="disabled">
        <i v-if="state" class="fa fa-circle-o-notch fa-spin"/> Загрузить файлы
      </button>
      <button @click="close" type="button" class="btn btn-link pull-right">Отмена</button>
      <div class="clearfix"></div>
    </div>
  </modal>
</template>

<script>
  import axios from 'axios'
  import FileToBase64 from '../FileToBase64'

  export default {
    components: {FileToBase64},
    props: ['files', 'path'],
    data () {
      return {
        arrayFiles: [],
        state: false
      }
    },
    computed: {
      totalSize () {
        return this.arrayFiles
          .reduce((a, b) => {
            return a + b.file.size
          }, 0)
      },
      maxFileUploads () {
        return this.$root.config.maxFileUploads
      },
      maxFileUploadsExceeded () {
        return this.arrayFiles.length > this.maxFileUploads
      },
      uploadMaxFilesize () {
        return this.$root.config.uploadMaxFilesize
      },
      uploadMaxFilesizeExceeded () {
        return this.totalSize > this.uploadMaxFilesize
      },
      disabled () {
        let {state, maxFileUploadsExceeded, uploadMaxFilesizeExceeded} = this
        return state || maxFileUploadsExceeded || uploadMaxFilesizeExceeded
      }
    },
    watch: {
      arrayFiles (value) {
        if (!value.length) {
          this.close()
        }
      }
    },
    methods: {
      submit () {
        let formData = new FormData()
        formData.append('path', this.path)
        this.arrayFiles.forEach((item, i) => {
          formData.append('files[]', item.file)
          formData.append(`files[${i}][alt]`, item.alt)
          formData.append(`files[${i}][video]`, item.videoUrl)
        })
        this.state = true
        axios.post('gallery/upload', formData)
          .then(() => {
            smallBoxSuccess('Файлы загружены.')
            this.$emit('success')
            this.close()
          })
      },
      close () {
        this.$modal.hide('form')
      },
      fileSizeText (bytes) {
        let arBytes = [
          {unit: 'ТБ', value: Math.pow(1024, 4)},
          {unit: 'ГБ', value: Math.pow(1024, 3)},
          {unit: 'МБ', value: Math.pow(1024, 2)},
          {unit: 'КБ', value: 1024},
          {unit: 'Б', value: 1}
        ]

        for (let arItem of arBytes) {
          if (bytes >= arItem.value) {
            let result = bytes / arItem.value
            result = String(Math.round(result * 100) / 100).replace('.', ',') + ` ${arItem.unit}`
            return result
          }
        }

        return bytes
      }
    },
    mounted () {
      this.arrayFiles = Array
        .from(this.files)
        .map(file => {
          return {
            file,
            alt: '',
            videoUrl: ''
          }
        })
      this.$modal.show('form')
    }
  }
</script>

<style scoped>
  table {
    margin-bottom: 0;
  }
  table td {
    padding: 3px 10px !important;
    vertical-align: top !important;
  }
  table tr:first-child td {
    border: none !important;
  }
  table tbody tr td:first-child {
    padding: 7px 0 !important;
  }
  table tfoot tr:first-child td {
    padding-top: 15px !important;
  }
  input[type="text"] {
    font-size: 80%;
    width: 100%;
    min-width: 200px;
    margin-top: 5px;
  }
  div.delete-item {
    display: inline-block;
    font-size: 20px;
    font-weight: 100;
    cursor: pointer;
    color: #aaa;
  }
  div.delete-item:after {
    content:"\00d7";
  }
</style>