<template>
  <div class="smart-form well no-padding">
    <fieldset>
      <h2>Импорт взаимосвязей</h2>
      <hr style="margin: 10px 0;"/>
      <section>
        <label class="label">Разделитель столбцов в csv</label>
        <label class="select">
          <select v-model="form.csv_delimiter">
            <option value=",">Запятая</option>
            <option value=";">Точка с запятой</option>
          </select>
          <i></i>
        </label>
      </section>
      <section>
        <submit
          :processing="processing"
          class="btn btn-link font-sm"
          @click="openFileDialog"
        >
          <i class="fa fa-upload"/> Загрузить файл импорта взаимосвязей
        </submit>
      </section>
    </fieldset>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'InterrelationImport',
    data () {
      return {
        form: {
          csv_delimiter: ';'
        },
        processing: false
      }
    },
    methods: {
      openFileDialog () {
        let input = document.createElement('input')
        input.type = 'file'
        input.accept = '.csv'
        input.click()
        input.onchange = (e) => {
          this.uploadFile(e.target.files[0])
        }
      },
      uploadFile (file) {
        let formData = new FormData()
        formData.append('csv_delimiter', this.form.csv_delimiter)
        formData.append('file', file)
        this.processing = true
        axios.post('interrelation/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    }
  }
</script>