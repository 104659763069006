<template>
  <div class="well well-sm">
    <fieldset>
      <div v-if="loading">Загрузка...</div>
      <div v-else>
        <div class="smart-form">
          <div class="row">
            <section class="col col-6">
              <section>
                <header>Настройки файла</header>
              </section>
              <section>
                <label class="label">Количество URL для каждого файла</label>
                <label class="input">
                  <input
                    v-model="form.sitemap_index_value"
                    type="text"
                    placeholder="Количество URL для каждого файла siemap{N}.xml"
                  />
                </label>
                <div class="note">
                  Если количество URL превысит заданное значение, будет создано несколько файлов.
                  При этом "sitemap.xml" будет являться файлом индексом, указывающий на файлы "sitemap{N}.xml"
                  Файлы именуются соответственно: "sitemap1.xml", "sitemap2.xml" и т.д.
                </div>
              </section>
            </section>
            <section class="col col-6">
              <section>
                <header>Настройки параметров</header>
              </section>
              <section>
                <label class="label">Формат даты для элемента &lt;lastmod&gt;</label>
                <label class="select">
                  <select v-model="form.sitemap_lastmod_format">
                    <option
                      v-for="(label, format) in lastmodFormats"
                      :value="format"
                      :key="format"
                    >
                      {{label}}
                    </option>
                  </select>
                  <i></i>
                </label>
              </section>
              <section>
                <label class="checkbox">
                  <input v-model="form.sitemap_include_lastmod" type="checkbox"/>
                  <i></i> Включить элемент &lt;lastmod&gt;
                </label>
              </section>
              <section>
                <label class="checkbox">
                  <input v-model="form.sitemap_include_changefreq" type="checkbox"/>
                  <i></i> Включить элемент &lt;changefreq&gt;
                </label>
              </section>
              <section>
                <label class="checkbox">
                  <input v-model="form.sitemap_include_priority" type="checkbox"/>
                  <i></i> Включить элемент &lt;priority&gt;
                </label>
              </section>
            </section>
          </div>
        </div>
        <hr/>
        <submit
          @click="submit"
          :processing="processing"
          class="btn btn-primary"
          icon="fa fa-save"
        >
          Сохранить
        </submit>
        <button
          type="button"
          class="btn btn-link"
          @click="showContentTypes = !showContentTypes"
        >
          Настроить "Changefreq" и "Priority" для типов материала
        </button>
      </div>
    </fieldset>
    <content-types
      v-if="showContentTypes"
      style="margin-top: 20px;"
    />
  </div>
</template>

<script>
  import ContentTypes from './content_types/ContentTypes'
  import axios from 'axios'

  export default {
    name: 'Sitemap',
    components: { ContentTypes },
    data () {
      return {
        form: {
          sitemap_index_value: null,
          sitemap_lastmod_format: null,
          sitemap_include_lastmod: null,
          sitemap_include_changefreq: null,
          sitemap_include_priority: null
        },
        lastmodFormats: [],
        showContentTypes: false,
        loading: true,
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post('siteconf/sitemap/save', this.form)
          .then(() => {
            smallBoxSuccess('Выполнено.')
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    mounted () {
      axios.get('siteconf/sitemap/get')
        .then(({data}) => {
          this.form = data.form
          this.lastmodFormats = data.lastmodFormats
          this.loading = false
        })
    }
  }
</script>