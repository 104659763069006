<template>
  <div class="well well-sm">
    <div class="smart-form">
      <section>
        <header>Текст заказа (каждая позиция с новой строки)</header>
      </section>
      <label class="textarea textarea-resizable">
        <textarea v-model="order" rows="10" class="custom-scroll" placeholder="Текст заказа (каждая позиция с новой строки)"/>
      </label>
    </div>
    <section>
      <h5 v-if="orders.length">Результат:</h5>
      <div
        v-for="order in orders"
        class="panel"
        :class="[order.contents.length ? 'panel-default' : 'panel-danger']"
        :key="order.id"
      >
        <div class="panel-heading">
          {{order.input}}
          <table>
            <tbody>
              <tr v-if="order.filters.type.id">
                <td class="text-muted text-nowrap">Тип материала:</td>
                <td class="text-primary">"{{order.filters.type.label}}"</td>
              </tr>
            </tbody>
            <tbody>
              v-for="attribute in order.filters.attributes"
              :key="attribute.id"
            >
              <tr v-if="attribute.values.length">
                <td class="text-muted text-nowrap">{{attribute.label}}:</td>
                <td class="text-primary">{{attribute.values.map(function(item){return '"'+item+'"'}).join(', ')}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="panel-body">
          <ol v-if="order.contents.length">
            <li
              v-for="content in order.contents"
              :key="content.id"
            >
              <a :href="`${siteRoot}${content.url}`" target="_blank">
                {{content.title}} - {{content.price}} ({{order.quantity}} шт)
              </a>
            </li>
          </ol>
          <div v-else class="text-danger">Ничего не нашлось.</div>
        </div>
      </div>
    </section>
    <div class="smart-form">
      <footer>
        <submit @click="submit" :processing="processing" class="btn btn-primary">
          Раcсчитать
        </submit>
      </footer>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data () {
      return {
        order: '',
        orders: [],
        processing: false
      }
    },
    computed: {
      siteRoot () {
        return this.$root.config.siteRoot
      }
    },
    methods: {
      submit () {
        this.orders = []
        this.processing = true
        const params = {text: this.order}
        axios.post('guglex/order/request', params)
          .then(({data}) => {
            this.orders = data
          })
          .finally(() => {
            this.processing = false
          })
      }
    }
  }
</script>