<template>
  <div>
    Критерии сортировки
    <button-menu
      title="Управление"
      btn-class="btn btn-link font-sm no-padding"
      style="margin-bottom: 5px;"
      :items="buttonMenu"
      @editOrder="showEditOrderForm = true"
      @reorderExpandOrder="showReorderExpandOrderForm = true"
    />
    <div class="order-box">
      <ol>
        <li
          v-for="item in displayOrders"
          :key="item.field.name"
        >
          <div :class="{'text-success': item.isExpand}">
            {{item.field.label}} ({{item.field.name}})
            <span class="text-muted"> : {{getDirectionLabel(item)}}</span>
            <div v-if="item.isExpand" class="font-xs">
              <span class="text-warning">: Фильтр раскрыт для пользователя</span> <span class="text-primary">: по {{item.label}}</span>
            </div>
          </div>
        </li>
      </ol>
    </div>
    <display-order-edit-form
      v-if="showEditOrderForm"
      @close="showEditOrderForm = false"
    />
    <expand-order-form
      v-if="showReorderExpandOrderForm"
      @close="showReorderExpandOrderForm = false"
    />
  </div>
</template>

<script>
  import DisplayOrderEditForm from './DisplayOrderEditForm'
  import ExpandOrderForm from './ExpandOrderForm'

  export default {
    name: 'DisplayOrder',
    components: {
      DisplayOrderEditForm,
      ExpandOrderForm
    },
    data () {
      return {
        buttonMenu: {
          editOrder: 'Настроить сортировку',
          reorderExpandOrder: 'Порядок раскрытой сортировки'
        },
        showEditOrderForm: false,
        showReorderExpandOrderForm: false
      }
    },
    computed: {
      display () {
        return this.$store.state.display
      },
      displayOrders () {
        const orders = this.display.form.filterCriteriaOrders
          .filter(item => !item.isExpand)
          .sort((a, b) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          })
        const expandOrders = this.display.form.filterCriteriaOrders
          .filter(item => item.isExpand)
          .sort((a, b) => {
            if (a.expand_order < b.expand_order) return -1
            if (a.expand_order > b.expand_order) return 1
            return 0
          })
        return orders.concat(expandOrders)
      }
    },
    methods: {
      getDirectionLabel (item) {
        return item.direction ? 'По убыванию' : 'По возрастанию'
      }
    }
  }
</script>

<style scoped>
  .order-box ol{
    padding-left: 25px
  }
  .order-box li {
    margin-bottom: 4px;
  }
</style>