<template>
  <div class="well well-sm">
    <div class="smart-form">
      <div class="row">
        <div class="col col-sm-5">
          <section>
            <header>Настройка заголовков сообщения</header>
          </section>
          <section>
            <label class="label">Тема письма</label>
            <label class="input">
              <input
                v-model="form.emailSubject"
                placeholder="Тема письма"
                type="text"
              >
            </label>
            <div class="note">Тема письма.</div>
          </section>
          <section>
            <label class="label">От кого (Email)</label>
            <label class="input">
              <input
                v-model="form.fromEmail"
                placeholder="От кого (Email)"
                type="text"
              >
            </label>
            <div class="note">Если оставить пустым, будет взято значение по умолчанию: <strong>{{ env.MAIL_FROM_ADDR }}</strong>.</div>
          </section>
          <section>
            <label class="label">От кого (Имя)</label>
            <label class="input">
              <input
                v-model="form.fromName"
                placeholder="От кого (Имя)"
                type="text"
              >
            </label>
            <div class="note">Если оставить пустым, будет взято значение по умолчанию: <strong>{{ env.MAIL_FROM_NAME }}</strong>.</div>
          </section>
        </div>
        <div class="col col-sm-7">
          <section>
            <header>Настройка шаблона сообщения</header>
          </section>
          <section>
            <label class="label">Шаблон тела сообщения</label>
            <vp-editor v-model="form.body"/>
            <div class="note">
              Используйте подстановочный шаблон
              <strong><span class="text-muted">[link]</span></strong>
              для подстановки ссылки.
            </div>
          </section>
        </div>
      </div>
    </div>
    <hr/>
    <submit
      :processing="processing"
      class="btn btn-primary"
      icon="fa fa-save"
      @click="submit"
    >
      Сохранить
    </submit>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Sharelink',
    data () {
      const {subject, from, name, body, env} = this.$root.config
      return {
        form: {
          emailSubject: subject,
          fromEmail: from,
          fromName: name,
          body
        },
        env,
        processing: false
      }
    },
    methods: {
      submit () {
        this.processing = true
        axios.post('shareLink/edit', this.form)
          .finally(() => {
            this.processing = false
          })
      }
    }
  }
</script>